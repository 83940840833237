import styled from '@emotion/styled'
import React from 'react'

import { theme } from 'app/theme/themeDefault'

const StyledLabel = styled.label<Pick<Props, 'disableBottomSpace'>>`
  display: block;
  font-size: ${theme.fontSize.small};
  font-weight: bold;
  padding-bottom: ${({ disableBottomSpace }) =>
    !disableBottomSpace ? theme.spacings.xs : '0'};
`

interface Props {
  htmlFor?: string
  disableBottomSpace?: boolean
}

export const Label: React.FunctionComponent<Props> = (props) => {
  const { children, htmlFor, disableBottomSpace } = props
  return (
    <StyledLabel htmlFor={htmlFor} disableBottomSpace={disableBottomSpace}>
      {children}
    </StyledLabel>
  )
}
