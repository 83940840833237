import styled from '@emotion/styled'
import React from 'react'

import { Spacer } from 'app/components/layout/Spacer'
import { theme } from 'app/theme/themeDefault'
import { generatePendoDataAttribute } from 'app/utils/pendo/generatePendoId'

const StyledSection = styled.section`
  color: ${theme.colors.white};

  a {
    color: ${theme.colors.white};
    text-decoration: none;
    &:hover {
      color: ${theme.colors.almostWhite};
      text-decoration: underline;
    }
  }

  small {
    font-size: 13px;
  }
`

const Links = styled.ul`
  display: block;
  li {
    display: inline-block;
  }
  li + li {
    position: relative;
    margin-left: 1.5rem;
    &:before {
      content: '·';
      position: absolute;
      left: calc((1.5rem / 2) * -1);
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

const linksArray: ReadonlyArray<{ label: string; url: string }> = [
  {
    label: 'Terms & Conditions',
    url: 'https://www.headbox.com/policies/terms',
  },
  {
    label: 'Privacy Policy',
    url: 'https://www.headbox.com/policies/privacy',
  },
  {
    label: 'Other Policies',
    url: 'https://www.headbox.com/policies/terms',
  },
]

export const SectionLinks: React.FunctionComponent = (_) => {
  return (
    <StyledSection>
      <Spacer size='sm'>
        <Links>
          <li>&copy; HeadBox</li>
          {linksArray.map((link, idx) => (
            <li key={`footer_link_${idx}`}>
              <a
                href={link.url}
                target='_blank'
                {...generatePendoDataAttribute('Footer Link', link.label)}
              >
                {link.label}
              </a>
            </li>
          ))}
        </Links>
      </Spacer>
      <small>
        Cookies are set by this site. To learn more or to decline them please
        see our{' '}
        <a href='https://www.headbox.com/en/policies/cookies' target='_blank'>
          Cookie Policy
        </a>
        .
      </small>
    </StyledSection>
  )
}
