import React from 'react'

import { Carousel } from 'app/components/ui/Carousel'
import { generatePendoDataAttribute } from 'app/utils/pendo/generatePendoId'

import { Container } from './styled'

export interface Props {
  id: string
  imageURLs: readonly string[]
  onProposalViewRequest: () => void
}

export const QuickGallery = ({
  id,
  imageURLs,
  onProposalViewRequest,
}: Props) => (
  <Container>
    <Carousel
      arrows
      hasLazyLoading
      roundedDots
      images={imageURLs}
      onImageClick={() => onProposalViewRequest()}
      dataAttributesForArrows={generatePendoDataAttribute(
        'Button',
        'Carousel arrow proposal preview'
      )}
      dataAttributesForImageSlide={generatePendoDataAttribute(
        'Image',
        `Open proposal ${id}`
      )}
    />
  </Container>
)
