// Keys required from backend setup
const COST_FOOD_AND_BEVERAGE = 'food-and-beverage'
const COST_AV = 'av'
const COST_ACCOMMODATION = 'accommodation'
const COST_SECURITY = 'security'
const COST_SERVICE_CHARGE = 'service-charge'
const COST_DEPOSIT = 'deposit'
const COST_ROOM_HIRE = 'room-hire'
const COST_MINIMUM_SPEND = 'minimum-spend'
const COST_EVENT_MANAGAMENT = 'event-managament'

// ENUM helper for building input labels
export const costsTypeHelper = {
  [COST_ROOM_HIRE]: 'Room Hire',
  [COST_MINIMUM_SPEND]: 'Minimum Spend',
  [COST_FOOD_AND_BEVERAGE]: 'Food and Beverages',
  [COST_AV]: 'AV Equipments',
  [COST_ACCOMMODATION]: 'Accommodation',
  [COST_SECURITY]: 'Security',
  [COST_SERVICE_CHARGE]: 'Service Charge ',
  [COST_DEPOSIT]: 'Deposit',
  [COST_EVENT_MANAGAMENT]: 'Event Management',
}

// Arrays of key to cycle in order to create form inputs
export const costsKeyDefinition = Object.keys(costsTypeHelper)
