import { css } from '@emotion/core'
import React from 'react'

import { FlexBox } from 'app/components/layout/FlexBox'
import { LoadingSpinner } from 'app/components/LoadingSpinner'

export const AsyncLoadingScreen: React.FC<{}> = () => (
  <FlexBox
    dial={5}
    css={css`
      height: 100vh;
    `}
  >
    <LoadingSpinner />
  </FlexBox>
)
