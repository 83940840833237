import React, { useEffect } from 'react'
import { Props } from './index'

export const LoginScreen: React.FC<Props> = () => {
  useEffect(() => {
    const { pathname, origin } = window.location

    const url =
      window.env.LOGIN_SERVICE_URL +
      '/users/sign_in?redirect_to=' +
      origin +
      pathname

    window.location.assign(url)
  }, [])

  return <div>Please wait for login page to load</div>
}
