import styled from '@emotion/styled'
import React from 'react'

import { ClampedLinesWithLabel } from 'app/components/ClampedLinesWithLabel'
import { Grid } from 'app/components/layout/Grid'
import { ButtonLink } from 'app/components/ui/ButtonLink'
import { costsTypeHelper } from 'app/constants/costingDefinitions'
import { OPTIONSHOLD } from 'app/constants/optionsHold'
import {
  ProposalAvailability,
  ProposalCostItem,
  ProposalLayoutInput,
  ProposalSpace,
} from 'app/containers/ApolloGeneratedComponents'
import { useResponsive } from 'app/hooks/useResponsive'
import { dateStringToNiceString } from 'app/utils/date'
import { generatePendoDataAttribute } from 'app/utils/pendo/generatePendoId'

const Wrapper = styled.div``

interface Props {
  availabilities: ReadonlyArray<
    Pick<ProposalAvailability, 'date' | 'optionHold' | 'timings'>
  >
  costItems: ReadonlyArray<Pick<ProposalCostItem, 'type' | 'description'>>
  layouts: readonly ProposalLayoutInput[]
  spaceLocation: ProposalSpace['location']
  spaceMarketplaceURL: ProposalSpace['marketplaceURL']
  spaceTour3dURL: ProposalSpace['tour3dURL']
}

export const Details: React.FC<Props> = ({
  availabilities,
  costItems,
  layouts,
  spaceLocation,
  spaceMarketplaceURL,
  spaceTour3dURL,
}) => {
  const gridgap = useResponsive('sm', 'sm', 'lg', 'lg', 'lg')
  const itemSpacing = 'lg'

  return (
    <Wrapper>
      <Grid
        gap={gridgap}
        breakAt='md'
        columnsTemplate='1fr 1fr'
        columnsTemplateBelowBreakpoint='1fr 1fr'
      >
        {/* LOCATION */}
        <ClampedLinesWithLabel
          spacing={itemSpacing}
          label='Location'
          lines={[spaceLocation]}
        />

        {/* LAYOUT CAPACITY */}
        <ClampedLinesWithLabel
          spacing={itemSpacing}
          label={
            layouts.length > 1 ? 'Layouts / capacities' : 'Layout / capacity'
          }
          lines={layouts.map(({ name, capacity }) => `${name} / ${capacity}`)}
        />
      </Grid>
      {/* AVAILABILITY */}
      {availabilities && availabilities.length ? (
        <ClampedLinesWithLabel
          spacing={itemSpacing}
          label='Availability / timings'
          lines={availabilities.map(
            ({ date, timings, optionHold }) =>
              `${dateStringToNiceString(date)} ${
                timings ? `, ${timings}` : ''
              } - ${OPTIONSHOLD.filter(({ key }) => key === optionHold)
                .map(({ label }) => label)
                .join()}`
          )}
        />
      ) : null}
      {/* COSTING */}
      {costItems.length ? (
        <Grid
          gap={gridgap}
          rowGap='none'
          breakAt='md'
          columnsTemplate='1fr 1fr'
          columnsTemplateBelowBreakpoint='1fr'
        >
          {costItems.map(({ description, type }, idx) =>
            description ? (
              <ClampedLinesWithLabel
                key={idx}
                spacing={itemSpacing}
                label={
                  costsTypeHelper[type as keyof typeof costsTypeHelper] || type
                }
                lines={description.split('\n').map((line) => line)}
              />
            ) : null
          )}
        </Grid>
      ) : null}
      {/* LINKS */}
      <Grid
        gap='lg'
        columnsTemplate='1fr 1fr'
        columnsTemplateBelowBreakpoint='1fr'
      >
        {spaceMarketplaceURL && (
          <ButtonLink
            variant='primary-ghost'
            to={spaceMarketplaceURL}
            target='_blank'
            isExternalUrl
            {...generatePendoDataAttribute('Anchor', 'View listing on HeadBox')}
          >
            View listing on HeadBox
          </ButtonLink>
        )}
        {spaceTour3dURL && (
          <ButtonLink
            variant='primary-ghost'
            to={spaceTour3dURL}
            target='_blank'
            isExternalUrl
            {...generatePendoDataAttribute(
              'Anchor',
              'Launch 3D tour in HeadBox'
            )}
          >
            Launch 3D tour in HeadBox
          </ButtonLink>
        )}
      </Grid>
    </Wrapper>
  )
}
