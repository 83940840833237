import { css } from '@emotion/core'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import Media from 'react-media'
import {
  Redirect,
  Route,
  RouteChildrenProps,
  RouteComponentProps,
} from 'react-router-dom'

import { Heading } from 'app/components/composite/Heading'
import { MainHeader } from 'app/components/composite/MainHeader'
import { ProposalPreview } from 'app/components/composite/ProposalPreview'
import { DisplayError } from 'app/components/DisplayError'
import { FlexBox } from 'app/components/layout/FlexBox'
import { Modal } from 'app/components/layout/Modal'
import { PageContainer } from 'app/components/layout/PageContainer'
import { Spacer } from 'app/components/layout/Spacer'
import { NoDataPlaceholder } from 'app/components/NoDataPlaceholder'
import { Alert } from 'app/components/ui/Alert'
import { Button } from 'app/components/ui/Button'
import { IconForButton } from 'app/components/ui/IconInButton'
import { Typography } from 'app/components/ui/Typography'
import { googleMapApiKey } from 'app/constants/googleMapApiKey'
import { useActor } from 'app/containers/ActorProvider/useActor'
import {
  ProposalState,
  ScreensDealSharedFetchDealComponent,
} from 'app/containers/ApolloGeneratedComponents'
import { useToggleSmart } from 'app/hooks/customHooks'
import { routes } from 'app/routes'
import { AsyncLoadingScreen } from 'app/screens/AsyncLoadingScreen'
import { CommentsBox } from 'app/screens/Deal/CommentsBox'
import { LoginScreen } from 'app/screens/Login/async'
import { PageNotFoundScreen } from 'app/screens/PageNotFound'
import { breakpoints } from 'app/theme/breakpoints'
import { dateStringToNiceString } from 'app/utils/date'
import { generatePendoDataAttribute } from 'app/utils/pendo/generatePendoId'
import { graphQLProposalToProposalEditableState } from 'app/utils/proposal'
import { OptionButton } from '../Deal/MapViewToggle'
import { ProposalCard } from '../Deal/ProposalCard'
import { ProposalCardList } from '../Deal/ProposalCardList'
import { ProposalDataForMap, ProposalsMap } from '../Deal/ProposalsMap'
import { ServiceFeeNotice } from '../Deal/ServiceFeeNotice'
import { Summary } from '../Deal/Summary'
import { SummaryBar } from '../Deal/SummaryBar'
import { UserCards } from '../Deal/UserCards'

interface RouteParams {
  token: string
  proposalIdForComments?: string
}

interface PersistentState {
  [token: string]: {
    mapView?: boolean
  }
}

export interface Props
  extends RouteChildrenProps<typeof routes.dealShared.exampleParams> {}

export const DealSharedScreen: React.FC<Props> = ({ match, history }) => {
  if (!match) {
    return null
  }

  const { token } = match.params
  const actorProvider = useActor()

  // RETRIVING SAVED STATE FROM LOCAL STORAGE
  const fromLocalStorage = localStorage.getItem('dealScreen')
  const stateFromLocalStorage: PersistentState = fromLocalStorage
    ? JSON.parse(fromLocalStorage)
    : {
        [token]: {
          mapView: false,
        },
      }
  const dealScreenState = stateFromLocalStorage[token]
  const mapViewInitialState =
    dealScreenState && dealScreenState.mapView ? dealScreenState.mapView : false

  // SCREEN STATE HERE
  const [showLoginScreen, setShowLoginScreen] = useState(false)
  const [
    showFullDetailsForProposalId,
    setShowFullDetailsForProposalId,
  ] = useState<string | null>(null)
  const mapView = useToggleSmart(mapViewInitialState)
  const summaryCardElement = useRef<HTMLDivElement | null>(null)

  // SIDE EFFECTS
  useEffect(() => {
    const stateToPersist: PersistentState = {
      ...stateFromLocalStorage,
      [token]: {
        mapView: mapView.isOn,
      },
    }
    localStorage.setItem('dealScreen', JSON.stringify(stateToPersist))
  }, [mapView.isOn])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [token])

  // REDIRECT LOGGED USER
  useEffect(() => {
    const stateToPersist: PersistentState = {
      ...stateFromLocalStorage,
      [token]: {
        mapView: mapView.isOn,
      },
    }
    localStorage.setItem('dealScreen', JSON.stringify(stateToPersist))
  }, [mapView.isOn])

  // HANDELING LOGIN SCREEN
  if (showLoginScreen && (!actorProvider.actor || !actorProvider.actor.user)) {
    return (
      <LoginScreen
        onAbortRequest={() => setShowLoginScreen(false)}
        onLoginSuccess={() => actorProvider.refetch()}
      />
    )
  }

  // SCREEN DEAL
  return (
    <ScreensDealSharedFetchDealComponent
      variables={{ dealPublicId: token, accessToken: token }}
      fetchPolicy='cache-and-network'
      children={({
        loading: loadingDeal,
        data: dealData,
        error: errorFetchingDeal,
      }) => {
        if (errorFetchingDeal) {
          return (
            <FlexBox dial={5}>
              <DisplayError message={errorFetchingDeal.message} />
            </FlexBox>
          )
        }

        if (!dealData || (loadingDeal && !dealData.deal)) {
          return <AsyncLoadingScreen />
        }

        if (!dealData.deal) {
          return <PageNotFoundScreen />
        }

        const { deal } = dealData

        if (!deal) {
          return <PageNotFoundScreen message='Deal not found' />
        }

        if (
          actorProvider &&
          actorProvider.actor &&
          actorProvider.actor.user &&
          actorProvider.actor.user.id
        ) {
          // Redirecting logged used proper deal page
          return <Redirect to={routes.deal.makeUrl(deal.id)} />
        }

        // Preparing proposals list for components
        const proposalsNotDeleted = deal.proposals.filter(
          (proposal) =>
            proposal.deletedAt === null || proposal.deletedAt === undefined
        )

        const acceptedProposal = proposalsNotDeleted.filter(
          (proposal) => proposal.state === ProposalState.ACCEPTED
        )

        const pendingProposals = proposalsNotDeleted.filter(
          (proposal) => proposal.state === ProposalState.PENDING
        )

        const proposalDataArrayForMap: readonly ProposalDataForMap[] = [
          ...acceptedProposal,
          ...pendingProposals,
        ]
          .filter(
            (proposal) =>
              proposal.space.coordinates !== null &&
              proposal.space.coordinates.latitude &&
              proposal.space.coordinates.longitude
          )
          .map((proposal) => ({
            id: proposal.id,
            location: proposal.space.location,
            spaceName: proposal.space.name,
            totalCost: proposal.totalCost,
            coordinates: proposal.space.coordinates!,
          }))

        // Preparing data for modal with full proposal details
        const singleProposalDataForModal = showFullDetailsForProposalId
          ? deal.proposals.find(({ id }) => id === showFullDetailsForProposalId)
          : null

        const summaryComponent = (
          <div ref={summaryCardElement}>
            <Spacer size='lg'>
              <Summary
                stage={deal.stage}
                title={deal.event.name}
                brief={deal.event.brief}
                eventBudget={deal.event.budget}
                dateOfEvent={
                  deal.event.date
                    ? dateStringToNiceString(deal.event.date)
                    : undefined
                }
                decisionDeadline={
                  deal.decisionDeadline
                    ? dateStringToNiceString(deal.decisionDeadline)
                    : undefined
                }
                displayDecisionDeadline={false}
                typeOfEvent={deal.event.productInterest || 'TBC'}
                companyName={
                  deal.headboxCompany ? deal.headboxCompany.name : undefined
                }
              />
            </Spacer>
          </div>
        )

        const summaryBarComponent = (
          <SummaryBar
            elementRefToObserve={summaryCardElement}
            stage={deal.stage}
            eventBudget={deal.event.budget}
            dateOfEvent={
              deal.event.date ? dateStringToNiceString(deal.event.date) : 'TBC'
            }
            typeOfEvent={deal.event.productInterest || 'TBC'}
          />
        )

        const usersComponent = (
          <UserCards
            company={{
              name: deal.headboxCompany ? deal.headboxCompany.name : '',
              logoURL: deal.headboxCompany
                ? deal.headboxCompany.logoURL || ''
                : '',
              primaryContactFullname: deal.primaryContact
                ? deal.primaryContact.fullName || ''
                : '',
              primaryContactEmail: deal.primaryContact.email || '',
              primaryContactPhone:
                deal.primaryContact.__typename === 'DealPrimaryContactProps'
                  ? deal.primaryContact.phone
                  : deal.primaryContact.__typename === 'HeadboxUser'
                  ? deal.primaryContact.phoneNumber || ''
                  : '',
              assignedTeamName: deal.headboxCompanyTeam
                ? deal.headboxCompanyTeam.name
                : undefined,
            }}
            owner={{
              fullName: deal.ownerAccountManager
                ? deal.ownerAccountManager.fullName
                : '',
              email: deal.ownerAccountManager
                ? deal.ownerAccountManager.email
                : '',
              avatarURL: deal.ownerAccountManager
                ? deal.ownerAccountManager.avatarURL
                : '',
            }}
            juniorAccountManager={{
              fullName: deal.juniorAccountManager
                ? deal.juniorAccountManager.fullName
                : '',
              email: deal.juniorAccountManager
                ? deal.juniorAccountManager.email
                : '',
              avatarURL: deal.juniorAccountManager
                ? deal.juniorAccountManager.avatarURL
                : '',
            }}
          />
        )

        const mainContent = (
          <Fragment>
            <Spacer size='sm'>
              <FlexBox dial={8} row spaceBetween>
                <div>
                  <h4>Your Quotes</h4>
                  {pendingProposals.length || acceptedProposal.length ? (
                    <Typography size='large' textColor='grayLight'>
                      Click a quote to view full details and comments
                    </Typography>
                  ) : null}
                </div>
                {/* TOGGLE BETWEEN CARD AND MAP VIEW */}
                {pendingProposals.length || acceptedProposal.length ? (
                  <FlexBox dial={5} row>
                    <OptionButton
                      isSelected={!mapView.isOn}
                      onClick={mapView.setOff}
                      {...generatePendoDataAttribute(
                        'Toggle',
                        'Set proposal card view in shared event'
                      )}
                    >
                      Card view
                    </OptionButton>
                    <OptionButton
                      isSelected={mapView.isOn}
                      onClick={mapView.setOn}
                      disabled={!proposalDataArrayForMap.length}
                      {...generatePendoDataAttribute(
                        'Toggle',
                        'Set proposal map view in shared event'
                      )}
                    >
                      Map view
                    </OptionButton>
                  </FlexBox>
                ) : null}
              </FlexBox>
            </Spacer>

            {!deal.proposals.length ? (
              <Fragment>
                <Spacer size='lg' />
                <NoDataPlaceholder message='Your quotes for this Event Proposal will appear here' />
              </Fragment>
            ) : mapView.isOn && proposalDataArrayForMap.length ? (
              <ProposalsMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${googleMapApiKey}`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `450px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                proposalArrayForMap={proposalDataArrayForMap}
              />
            ) : (
              <ProposalCardList
                // ACCEPTED PROPOSAL
                accepted={acceptedProposal.map((proposal) => (
                  <Spacer size='lg' key={proposal.id}>
                    <ProposalCard
                      id={proposal.id}
                      state='accepted'
                      title={proposal.space.name}
                      imageURLs={proposal.images.map((img) => img.URL)}
                      location={proposal.space.location}
                      layouts={proposal.layouts}
                      availabilityDates={proposal.availabilities.map(
                        ({ date }) => date
                      )}
                      costItems={[]}
                      totalCost={proposal.totalCost}
                      onProposalViewRequest={() =>
                        setShowFullDetailsForProposalId(proposal.id)
                      }
                      leftActionComponent={
                        <Button
                          variant='white'
                          onClick={(e) => {
                            e.stopPropagation()
                            // history.push(routes.proposal.makeUrl(proposal.id))
                            setShowFullDetailsForProposalId(proposal.id)
                          }}
                          {...generatePendoDataAttribute(
                            'Button',
                            'Open proposal in shared event'
                          )}
                        >
                          <IconForButton name='ellipsis-h' />
                          View more
                        </Button>
                      }
                      rightActionComponent={
                        <Button
                          variant='white'
                          onClick={(e) => {
                            e.stopPropagation()
                            history.push(
                              routes.proposalCommentsOnSharedDeal.makeUrl(
                                token,
                                proposal.id
                              )
                            )
                          }}
                          {...generatePendoDataAttribute(
                            'Button',
                            'Open Comments modal in shared event'
                          )}
                        >
                          <IconForButton name='comments' />
                          {proposal.comments.length > 0
                            ? `${proposal.comments.length} comment${
                                proposal.comments.length > 1 ? 's' : ''
                              }`
                            : 'Add comment'}
                        </Button>
                      }
                    />
                  </Spacer>
                ))}
                // SHORTLISTED PROPOSAL
                pending={pendingProposals.map((proposal) => (
                  <Spacer size='lg' key={proposal.id}>
                    <ProposalCard
                      id={proposal.id}
                      state='pending'
                      title={proposal.space.name}
                      imageURLs={proposal.images.map((img) => img.URL)}
                      location={proposal.space.location}
                      layouts={proposal.layouts}
                      availabilityDates={proposal.availabilities.map(
                        ({ date }) => date
                      )}
                      costItems={[]}
                      totalCost={proposal.totalCost}
                      onProposalViewRequest={() =>
                        setShowFullDetailsForProposalId(proposal.id)
                      }
                      leftActionComponent={
                        <Button
                          variant='white'
                          onClick={(e) => {
                            e.stopPropagation()
                            // history.push(routes.proposal.makeUrl(proposal.id))
                            setShowFullDetailsForProposalId(proposal.id)
                          }}
                          {...generatePendoDataAttribute(
                            'Button',
                            'Open proposal in shared event'
                          )}
                        >
                          <IconForButton name='ellipsis-h' />
                          View more
                        </Button>
                      }
                      rightActionComponent={
                        <Button
                          variant='white'
                          onClick={(e) => {
                            e.stopPropagation()
                            history.push(
                              routes.proposalCommentsOnSharedDeal.makeUrl(
                                token,
                                proposal.id
                              )
                            )
                          }}
                          {...generatePendoDataAttribute(
                            'Button',
                            'Open Comments modal in shared event'
                          )}
                        >
                          <IconForButton name='comments' />
                          {proposal.comments.length > 0
                            ? `${proposal.comments.length} Comment${
                                proposal.comments.length > 1 ? 's' : ''
                              }`
                            : 'Add Comment'}
                        </Button>
                      }
                    />
                  </Spacer>
                ))}
              />
            )}
          </Fragment>
        )

        return (
          <Fragment>
            {/* MAIN HEADER */}
            <MainHeader onLoginRequest={() => setShowLoginScreen(true)} />
            {/* HEAD */}
            <Heading pageTitle={deal.event.name} />

            <PageContainer>
              <Spacer size='lg'>
                <FlexBox dial={5} row>
                  <Alert fullWidth type='warning' padding='sm'>
                    Please sign in to accept, reject or comment on quotes
                  </Alert>
                </FlexBox>
              </Spacer>
              <Media query={`(min-width: ${breakpoints.md})`}>
                {(matches) =>
                  matches ? (
                    // DESKTOP VIEW
                    <FlexBox
                      row
                      stretch
                      spaceBetween
                      dial={1}
                      css={css`
                        > aside {
                          width: 33%;
                        }
                        > section {
                          width: calc(66% - 2rem);
                        }
                      `}
                    >
                      {summaryBarComponent}
                      <aside>
                        {summaryComponent}
                        {deal.headboxServiceFee ? (
                          <ServiceFeeNotice
                            amountPercent={deal.headboxServiceFee}
                          />
                        ) : null}
                        {usersComponent}
                      </aside>
                      <section>{mainContent}</section>
                    </FlexBox>
                  ) : (
                    // MOBILE VIEW
                    <FlexBox row={false} stretch dial={1}>
                      {summaryComponent}
                      {mainContent}
                      {usersComponent}
                    </FlexBox>
                  )
                }
              </Media>
            </PageContainer>

            {/* FULL PROPOSAL DETAIL MODAL */}
            {showFullDetailsForProposalId && singleProposalDataForModal ? (
              <Modal
                size='large'
                onCloseRequest={() => {
                  setShowFullDetailsForProposalId(null)
                }}
                verticalCentered
                modalPaddingInRem={0}
              >
                <ProposalPreview
                  {...graphQLProposalToProposalEditableState(
                    singleProposalDataForModal
                  )}
                  imageURLs={singleProposalDataForModal.images.map(
                    ({ URL }) => URL
                  )}
                />
              </Modal>
            ) : null}

            {/* COMMENT MODAL */}
            {/* "/deal-shared/:dealId/commentsToProposal/:proposalIdForComments" */}
            <Route
              exact
              path={routes.proposalCommentsOnSharedDeal.path}
              render={({
                match: {
                  params: { proposalIdForComments },
                },
              }: RouteComponentProps<RouteParams>) => {
                if (!proposalIdForComments) {
                  return null
                }
                return (
                  <Modal
                    size='large'
                    onCloseRequest={() => {
                      history.replace(routes.dealShared.makeUrl(token))
                    }}
                    verticalCentered
                  >
                    <CommentsBox
                      proposalId={proposalIdForComments}
                      comments={
                        deal.proposals.filter(
                          (proposal) => proposal.id === proposalIdForComments
                        )[0].comments
                      }
                      spaceName={
                        deal.proposals.filter(
                          (proposal) => proposal.id === proposalIdForComments
                        )[0].space.name
                      }
                    />
                  </Modal>
                )
              }}
            />
          </Fragment>
        )
      }}
    />
  )
}
