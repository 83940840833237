import React from 'react'

import { Spacer } from 'app/components/layout/Spacer'
import { CardUser } from './CardUser'

interface Props {
  company?: {
    name: string
    logoURL: string
    primaryContactFullname: string
    primaryContactEmail: string
    primaryContactPhone: string
    assignedTeamName?: string
  }

  owner?: {
    fullName: string
    avatarURL: string
    email: string
  }

  juniorAccountManager?: {
    fullName: string
    avatarURL: string
    email: string
  }
}

export const UserCards: React.FC<Props> = ({
  company,
  owner,
  juniorAccountManager,
}) => {
  return (
    <>
      {company && (company.name || company.primaryContactFullname) ? (
        <Spacer size='lg'>
          <CardUser title={company.name} media={company.logoURL}>
            {company.primaryContactFullname && (
              <div>{company.primaryContactFullname}</div>
            )}
            {company.assignedTeamName && <div>{company.assignedTeamName}</div>}
            {company.primaryContactEmail && (
              <div>{company.primaryContactEmail}</div>
            )}
            {company.primaryContactPhone && (
              <div>{company.primaryContactPhone}</div>
            )}
          </CardUser>
        </Spacer>
      ) : null}

      {owner && owner.fullName ? (
        <Spacer size='lg'>
          <CardUser
            title={owner.fullName}
            media={owner.avatarURL}
            showMediaAsAvatar
          >
            <div>
              Senior Account Manager <br />
              {owner.email}
            </div>
          </CardUser>
        </Spacer>
      ) : null}

      {juniorAccountManager && juniorAccountManager.fullName ? (
        <Spacer size='lg'>
          <CardUser
            title={juniorAccountManager.fullName}
            media={juniorAccountManager.avatarURL}
            showMediaAsAvatar
          >
            <div>
              Account Manager <br />
              {juniorAccountManager.email}
            </div>
          </CardUser>
        </Spacer>
      ) : null}
    </>
  )
}
