// APP ROUTES

export const routes = {
  root: {
    path: '/',
    makeUrl: () => '/',
    exampleParams: {},
  },
  login: {
    path: '/login',
    makeUrl: () => `/login`,
    exampleParams: {},
  },
  deal: {
    path: '/deal/:dealId',
    makeUrl: (dealId: string) => `/deal/${dealId}`,
    exampleParams: {
      dealId: 'xyz',
    },
  },
  proposalCommentsOnDeal: {
    path: '/deal/:dealId/commentsToProposal/:proposalIdForComments',
    makeUrl: (dealId: string, proposalId: string) =>
      `/deal/${dealId}/commentsToProposal/${proposalId}`,
    exampleParams: {
      dealId: 'xyz',
      proposalId: 'xyz',
    },
  },
  dealShared: {
    path: '/deal-shared/:token',
    makeUrl: (token: string) => `/deal-shared/${token}`,
    exampleParams: {
      token: 'xyz',
    },
  },
  proposalCommentsOnSharedDeal: {
    path: '/deal-shared/:dealId/commentsToProposal/:proposalIdForComments',
    makeUrl: (token: string, proposalId: string) =>
      `/deal-shared/${token}/commentsToProposal/${proposalId}`,
    exampleParams: {
      token: 'xyz',
      proposalId: 'xyz',
    },
  },
  proposal: {
    path: '/proposal/:proposalId',
    makeUrl: (proposalId: string) => `/proposal/${proposalId}`,
    exampleParams: {
      proposalId: 'xyz',
    },
  },
  proposalCreation: {
    path: '/deal/:dealId/newProposal',
    makeUrl: (dealId: string) => `/deal/${dealId}/newProposal`,
    exampleParams: {
      dealId: 'xyz',
    },
  },
  proposalModification: {
    path: '/proposal/:proposalId/edit',
    makeUrl: (proposalId: string) => `/proposal/${proposalId}/edit`,
    exampleParams: {
      proposalId: 'xyz',
    },
  },
  viewProposal: {
    path: '/view-proposal/:proposalId',
    makeUrl: (dealId: string) => `/deal/${dealId}`,
    exampleParams: {
      proposalId: 'xyz',
    },
  },
}
