import { css } from '@emotion/core'
import { breakpoints } from 'app/theme/breakpoints'
import { headings } from 'app/theme/tokens/headings'
import { headingsMobile } from 'app/theme/tokens/headingsMobile'

export const headingsCSS = css`
  h1 {
    font-size: ${headings.h1};
  }

  h2 {
    font-size: ${headings.h2};
  }

  h3 {
    font-size: ${headings.h3};
  }

  h4 {
    font-size: ${headings.h4};
  }

  h5 {
    font-size: ${headings.h5};
  }

  h6 {
    font-size: ${headings.h6};
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    h1 {
      font-size: ${headingsMobile.h1};
    }

    h2 {
      font-size: ${headingsMobile.h2};
    }

    h3 {
      font-size: ${headingsMobile.h3};
    }

    h4 {
      font-size: ${headingsMobile.h4};
    }

    h5 {
      font-size: ${headingsMobile.h5};
    }

    h6 {
      font-size: ${headingsMobile.h6};
    }
  }
`
