import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { theme } from 'app/theme/themeDefault'

interface OptionProp {
  isSelected: boolean
  disabled?: boolean
}

export const OptionButton = styled.button<OptionProp>`
  display: flex;
  align-items: center;
  box-shadow: none;
  outline: none;
  padding: ${theme.buttonPadding};
  justify-content: center;
  background-color: ${theme.colors.buttons.secondary.background};
  color: ${theme.colors.black};
  border: 0;
  text-align: center;
  font-size: ${theme.fontSize.small};
  font-weight: bold;
  &:first-of-type {
    border-top-left-radius: ${theme.borderRadius};
    border-bottom-left-radius: ${theme.borderRadius};
  }
  &:last-of-type {
    border-top-right-radius: ${theme.borderRadius};
    border-bottom-right-radius: ${theme.borderRadius};
  }

  ${({ isSelected }: OptionProp) =>
    isSelected
      ? css`
          background-color: ${theme.colors.buttons.secondary.background};
          box-shadow: inset 0 0 3px ${theme.colors.grayLight};
          pointer-events: none;
        `
      : null}

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
          cursor: initial;
        `
      : css`
          cursor: pointer;
        `}
`
