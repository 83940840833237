import styled from '@emotion/styled'
import React from 'react'

import { Grid } from 'app/components/layout/Grid'
import { PageContainer } from 'app/components/layout/PageContainer'
import { breakpoints } from 'app/theme/breakpoints'
import { theme } from 'app/theme/themeDefault'

import { SectionCertifications } from './SectionCertifications'
import { SectionLinks } from './SectionLinks'

const StyledFooter = styled.footer`
  background-color: ${theme.colors.components.footer};
  color: #fff;
  padding: 3rem 1rem;

  @media screen and (max-width: ${breakpoints.smMax}) {
    padding-bottom: 6rem;
  }
`

const FooterInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const Footer: React.FunctionComponent = (_) => {
  return (
    <StyledFooter>
      <PageContainer>
        <FooterInner>
          <Grid columnsTemplate='1fr 1fr' gap='xl'>
            <SectionLinks />
            <SectionCertifications />
          </Grid>
        </FooterInner>
      </PageContainer>
    </StyledFooter>
  )
}
