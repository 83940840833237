import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { theme } from 'app/theme/themeDefault'
import { darken } from 'polished'

import { Props as ButtonProps, Variant } from './index'

export const base = () => css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`

export const dimensions = ({ fullwidth, size }: Props) => css`
  padding: ${size === 'normal' ? theme.buttonPadding : '0.3rem 0.5rem'};
  min-height: ${size === 'normal' ? theme.inputs.height : 'auto'};
  width: ${fullwidth ? '100%' : 'auto'};
  /* IE 10 FIX */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: ${theme.inputs.height};
  }
`
export const borders = () => css`
  border-width: 1px;
  border-style: solid;
  border-radius: ${theme.borderRadius};
`
export const colors = ({ variant }: Props) => {
  switch (variant) {
    case 'primary':
      return css`
        color: #fff;
        background-color: ${theme.colors.primary};
        border-color: ${theme.colors.primary};

        &:hover,
        &:focus {
          background-color: ${darken(0.1, theme.colors.primary)};
        }
      `
    case 'primary-ghost':
      return css`
        color: ${theme.colors.primary};
        background-color: transparent;
        border-color: ${theme.colors.primary};

        &:hover,
        &:active,
        &:focus {
          color: #fff;
          background-color: ${theme.colors.primary};
        }
      `
    case 'secondary':
      return css`
        color: ${theme.colors.gray};
        background-color: ${theme.colors.buttons.secondary.background};
        border-color: ${theme.colors.buttons.secondary.border};

        &:hover,
        &:active,
        &:focus {
          background-color: ${darken(
            0.1,
            theme.colors.buttons.secondary.background
          )};
        }
      `
    case 'editor':
      return css`
        color: ${theme.colors.gray};
        background-color: ${theme.colors.buttons.secondary.background};
        border-color: ${theme.colors.buttons.secondary.border};

        &:hover,
        &:active,
        &:focus {
          background-color: ${darken(
            0.1,
            theme.colors.buttons.secondary.background
          )};
        }
      `
    case 'white':
      return css`
        color: ${theme.colors.gray};
        background-color: white;
        border-color: transparent;

        &:hover,
        &:active,
        &:focus {
          color: #fff;
          background-color: ${theme.colors.primary};
        }
      `
    case 'danger':
      return css`
        color: #fff;
        background-color: ${theme.colors.danger};
        border-color: ${theme.colors.danger};

        &:hover,
        &:focus {
          background-color: ${darken(0.1, theme.colors.danger)};
        }
      `
  }
}
export const fontProps = ({ size }: Props) => css`
  font-size: ${size === 'normal' ? theme.fontSize.small : '0.8rem'};
  font-weight: bold;
  text-transform: none;
  text-decoration: none;
`

export const StyledButton = styled.button<Props>`
  ${base}

  ${dimensions}
  ${borders}
  ${colors}
  ${fontProps}

  ${({ css: cssProps }) => cssProps};

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  user-select: none;
  box-shadow: none;

  &:focus {
    outline: none;
    box-shadow: ${theme.boxShadow};
  }
`

interface Props extends Omit<ButtonProps, 'children'> {
  variant: Variant
}
