import { IconLayoutName } from 'app/components/composite/ProposalEditor/LayoutWithCapacity/LayoutCapacitySelector/iconLayouts'

export const LAYOUTS_CAPACITY_DICTIONARY: LayoutsCapacityDictionary = {
  ['cabaret']: {
    id: 'cabaret',
    name: 'Cabaret',
    iconName: 'cabaret',
    selectable: true,
    capacity: 0,
  },
  ['boardroom']: {
    id: 'boardroom',
    name: 'Boardroom',
    iconName: 'boardroom',
    selectable: true,
    capacity: 0,
  },
  ['theatre']: {
    id: 'theatre',
    name: 'Theatre',
    iconName: 'theatre',
    selectable: true,
    capacity: 0,
  },
  ['standing']: {
    id: 'standing',
    name: 'Standing',
    iconName: 'standing',
    selectable: true,
    capacity: 0,
  },
  ['dining']: {
    id: 'dining',
    name: 'Dining/Rounds',
    iconName: 'dining',
    selectable: true,
    capacity: 0,
  },
  ['ushaped']: {
    id: 'ushaped',
    name: 'Ushaped',
    iconName: 'u-shaped',
    selectable: true,
    capacity: 0,
  },
  ['mixedSeating']: {
    id: 'mixedSeating',
    name: 'Mixed Seating',
    iconName: 'seating',
    selectable: true,
    capacity: 0,
  },
  ['classroom']: {
    id: 'classroom',
    name: 'Classroom',
    iconName: 'classroom',
    selectable: true,
    capacity: 0,
  },
  ['unknown']: {
    id: 'unknown',
    name: 'unknown',
    iconName: 'other',
    selectable: false,
    capacity: 0,
    hidden: true,
  },
}

const notHiddenAndSelectableLayouts = Object.keys(LAYOUTS_CAPACITY_DICTIONARY)
  .filter((key) => !LAYOUTS_CAPACITY_DICTIONARY[key as LayoutId].hidden)
  .filter((key) => LAYOUTS_CAPACITY_DICTIONARY[key as LayoutId].selectable)

export const acceptedLayoutValues = notHiddenAndSelectableLayouts.map(
  (key) => LAYOUTS_CAPACITY_DICTIONARY[key as LayoutId].name
)

export type LayoutsCapacityDictionary = Record<LayoutId, LayoutCapacityItem>

export type LayoutId =
  | 'cabaret'
  | 'boardroom'
  | 'theatre'
  | 'standing'
  | 'dining'
  | 'ushaped'
  | 'mixedSeating'
  | 'classroom'
  | 'unknown'

export interface LayoutCapacityItem {
  id: LayoutId
  name: string
  iconName: IconLayoutName
  capacity: number
  selectable: boolean
  hidden?: boolean
}
