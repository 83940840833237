import React from 'react'

import { Spacer } from 'app/components/layout/Spacer'
import { NoDataPlaceholder } from 'app/components/NoDataPlaceholder'

interface Props {
  accepted?: readonly JSX.Element[]
  pending?: readonly JSX.Element[]
  rejected?: readonly JSX.Element[]
}

export const ProposalCardList: React.FC<Props> = ({
  accepted,
  rejected,
  pending,
}) => {
  return (
    <>
      {/* ACCEPTED */}
      {accepted && accepted.length ? accepted : null}

      {/* SHORTLISTED */}
      {pending && pending.length ? pending : null}

      {/* REJECTED */}
      {rejected && rejected.length ? (
        <>
          <Spacer size='sm'>
            <h4>Rejected proposals</h4>
          </Spacer>
          {rejected}
        </>
      ) : null}

      {/* EMPTY STATE FOR NO ACCEPTED OR SHORTLISTED */}
      {(!accepted || !accepted.length) &&
      (!pending || !pending.length) &&
      (!rejected || !rejected.length) ? (
        <NoDataPlaceholder message='No shortlisted proposals found' />
      ) : null}
    </>
  )
}
