import styled from '@emotion/styled'
import React from 'react'

import { ActionNav } from 'app/components/layout/ActionNav'
import LogoGdpr from 'app/static/images/gdpr-logo.svg'
import LogoIso from 'app/static/images/iso27001.png'
import { breakpoints } from 'app/theme/breakpoints'
import { theme } from 'app/theme/themeDefault'

const StyledSection = styled.section`
  a {
    line-height: 0;
  }
  @media screen and (max-width: ${breakpoints.smMax}) {
    /* more room to handle the fixed bottom cta on mobile */
    padding-top: ${theme.spacings.lg};
  }
`

const ImageBadge = styled.img`
  width: 73px;
  height: 75px;
`

export const SectionCertifications: React.FunctionComponent = (_) => {
  return (
    <StyledSection>
      <ActionNav alignment='left'>
        <ImageBadge src={LogoIso} alt='ISO 27001 Certified' />
        <a
          href='https://www.headbox.com/en/policies/terms_links#data_protection'
          target='_blank'
        >
          <ImageBadge src={LogoGdpr} alt='Gdpr' />
        </a>
      </ActionNav>
    </StyledSection>
  )
}
