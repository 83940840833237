import styled from '@emotion/styled'

import { theme } from 'app/theme/themeDefault'

interface Dropdown {
  top: boolean
  left: boolean
  bottom: boolean
  right: boolean
  menuWidth?: string
  scroll: boolean
}

export const StyledDropdownWrapper = styled.div<{ inline: boolean }>`
  position: relative;
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  width: ${({ inline }) => (inline ? 'auto' : '100%')};
`

export const DropdownMenuWrapper = styled.div<Dropdown>`
  position: absolute;
  left: ${({ left }) => (left ? '0' : 'auto')};
  right: ${({ right }) => (right ? '0' : 'auto')};
  top: ${({ bottom }) => (bottom ? '100%' : 'auto')};
  bottom: ${({ top }) => (top ? '100%' : 'auto')};
  margin: 2px 0;
  width: ${({ menuWidth }) => menuWidth};

  z-index: ${theme.zIndex.dropdown};
  box-shadow: ${theme.boxShadow};
  border-radius: ${theme.borderRadius};
  overflow-x: hidden;
  overflow-y: ${({ scroll }) => (scroll ? 'scroll' : 'hidden')};
  max-height: ${({ scroll }) => (scroll ? '275px' : null)};
`

export const Backdrop = styled.div`
  position: fixed;
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: ${theme.zIndex.dropdown - 1};
`

export const DropdownToggle = styled.div`
  cursor: pointer;
`
