import styled from '@emotion/styled'
import React from 'react'

import { SpacingValue, theme } from 'app/theme/themeDefault'

const flexJustifyContent = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
}

const StyledNav = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: ${({ alignment }) => flexJustifyContent[alignment]};

  > * + * {
    margin-left: ${theme.spacingsLegacy.normal};
    margin-left: ${({ gap }) => theme.spacings[gap]};
  }
`

type Props = {
  alignment: 'center' | 'left' | 'right'
  gap: SpacingValue
  children: React.ReactNode
} & typeof defaultProps

const defaultProps = {
  /**
   * horizontal alignment
   * @default 'right'
   */
  alignment: 'right',
  /**
   * gap
   * @default 'normal'
   */
  gap: 'normal',
}

export const ActionNav = (props: Props) => {
  const { children, alignment, gap } = props

  return (
    <StyledNav gap={gap} alignment={alignment}>
      {children}
    </StyledNav>
  )
}

ActionNav.defaultProps = defaultProps
