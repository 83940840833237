import { css } from '@emotion/core'

import mreavesxlmodbWoff from 'app/static/fonts/mreavesxlmodb.woff'
import mreavesxlmodbWoff2 from 'app/static/fonts/mreavesxlmodb.woff2'
import mreavesxlmodrWoff from 'app/static/fonts/mreavesxlmodr.woff'
import mreavesxlmodrWoff2 from 'app/static/fonts/mreavesxlmodr.woff2'

export const fontFaceMrEavesXlModern = css`
  /* Mr EAVES XL Modern */
  @font-face {
    font-family: 'mreavesxlmod';
    src: url('${mreavesxlmodrWoff2}') format('woff2'),
      url('${mreavesxlmodrWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'mreavesxlmod';
    src: url('${mreavesxlmodbWoff2}') format('woff2'),
      url('${mreavesxlmodbWoff}') format('woff');
    font-weight: bold;
    font-style: normal;
  }
`
