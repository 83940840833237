import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'

export interface ArrowButtonProps {
  size: number
  direction: 'prev' | 'next'
  onClick?: () => void
}

export const ArrowButton: React.FC<ArrowButtonProps> = ({
  size,
  direction,
  ...rest
}) => {
  return (
    <ButtonArrowBase type='button' size={size} direction={direction} {...rest}>
      {direction === 'prev' ? (
        <i className='far fa-arrow-alt-circle-left' />
      ) : (
        <i className='far fa-arrow-alt-circle-right' />
      )}
    </ButtonArrowBase>
  )
}

const ButtonArrowBase = styled.button<ArrowButtonProps>`
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  outline: none;
  border: none;

  background-color: rgba(128, 128, 128, 0);
  color: #fff;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;

  ${({ size }) => css`
    font-size: ${size}px;
    height: ${size}px;
    width: ${size}px;
  `}

  ${({ direction }) =>
    direction === 'prev'
      ? css`
          left: 10px;
        `
      : css`
          right: 10px;
        `}
`
