import styled from '@emotion/styled'

import { breakpoints } from 'app/theme/breakpoints'
import { theme } from 'app/theme/themeDefault'

export const Container = styled.div`
  @media screen and (min-width: ${breakpoints.sm}) {
    position: sticky;
    top: 0;
    z-index: ${theme.zIndex.sticky};
  }
`

export const WhiteBar = styled.div`
  width: 100%;
  border-bottom: 1px solid #f1f0f0;
  padding: 0.5rem;
  min-height: 70px;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: ${theme.boxShadow};
`

export const PageTitle = styled.h4`
  margin: 0px;
`

export const BottomFixedCTA = styled.div`
  z-index: ${theme.zIndex.sticky};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);

  > button,
  a {
    width: 100%;
    min-height: 44px;
  }
`
