import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { FormikErrors } from 'formik'
import { merge, omit } from 'lodash'

import { ProposalEditableState } from 'app/components/composite/ProposalEditor'
import {
  COST_ITEMS_DICTIONARY,
  CostItemsTypes as InsertableCostItemsTypes,
  CostItemsTypesToBeUnique,
  CostItemType,
} from 'app/constants/proposalCostItems'
import { acceptedLayoutValues } from 'app/constants/proposalLayoutsWithCapacity'

import {
  Currency,
  Proposal,
  ProposalAvailabilityInput,
  ProposalCostItemInput,
  ProposalDataInput,
  ProposalLayoutInput,
  ProposalState,
} from 'app/containers/ApolloGeneratedComponents'

import {
  graphQLArrayFormToRawState,
  rawStateToGraphQLArrayFormInput,
} from './draftJS'
import { isValidEmailAddress } from './isValidEmailAddress'

export const proposalEditableStateValidator = async ({
  totalCost,
  layouts,
  availabilities,
  costItems,
  space,
}: ProposalEditableState): Promise<FormikErrors<ProposalDataInput>> => {
  // tslint:disable-next-line: no-let
  const errors: FormikErrors<ProposalDataInput> = {}

  if (!space.name.trim()) {
    errors.space = {
      ...errors.space,
      name: `Please enter a ${space.name ? 'valid ' : ''}space name`,
    }
  }

  if (!isValidEmailAddress(space.hostEmail)) {
    errors.space = {
      ...errors.space,
      hostEmail: `Please enter ${
        space.hostEmail ? 'a valid' : 'an'
      } email address for the host`,
    }
  }

  if (!space.fullAddress.trim()) {
    errors.space = {
      ...errors.space,
      fullAddress: 'Please enter a valid address',
    }
  }

  errors.layouts = layouts.map<FormikErrors<ProposalLayoutInput>>(
    ({ name, capacity }) => {
      const layoutsErrors: FormikErrors<ProposalLayoutInput> =
        capacity && name
          ? !acceptedLayoutValues.includes(name)
            ? {
                name: `"${name}" is not a valid layout value. Please select one among ${acceptedLayoutValues.join(
                  ', '
                )}`,
              }
            : capacity
            ? capacity > 0
              ? {}
              : {
                  capacity: 'Capcity must be a number',
                }
            : {}
          : {}

      if (Object.keys(layoutsErrors).length) {
        return layoutsErrors
      }
      return {}
    }
  )

  if (
    !errors.layouts.length ||
    !errors.layouts.filter((value) => value && Object.keys(value).length !== 0)
      .length
  ) {
    // tslint:disable-next-line: no-delete
    delete errors.layouts
  }

  if (layouts && layouts.length < 1) {
    errors.layouts = [
      {
        name: 'Specify a capacity for at least one layout.',
      },
    ]
  }

  errors.availabilities = availabilities.map<
    FormikErrors<ProposalAvailabilityInput>
  >(({ date, optionHold }) => {
    const availabilityError: FormikErrors<ProposalAvailabilityInput> = !date
      ? !optionHold
        ? {
            date: 'Date is required',
            optionHold: 'Please select an option',
          }
        : {
            date: 'Date is required',
          }
      : !optionHold
      ? {
          optionHold: 'Please select an option',
        }
      : {}

    if (Object.keys(availabilityError).length) {
      return availabilityError
    }
    return {}
  })

  if (
    !errors.availabilities.length ||
    !errors.availabilities.filter(
      (value) => value && Object.keys(value).length !== 0
    ).length
  ) {
    // tslint:disable-next-line: no-delete
    delete errors.availabilities
  }

  if (!totalCost.amount || totalCost.amount <= 0) {
    errors.totalCost = {
      amount: 'Please enter the total cost for this event',
    }
  }

  const costItemsDefinedTypes = costItems.map(({ type }) => type)
  errors.costItems = costItems.map<FormikErrors<ProposalCostItemInput>>(
    ({ id, type, description }, idx) => ({
      id:
        id === null || id === undefined
          ? undefined
          : id.trim()
          ? undefined
          : 'ID is not valid',
      type: InsertableCostItemsTypes.includes(type as any)
        ? CostItemsTypesToBeUnique.includes(type as any)
          ? costItemsDefinedTypes.slice(0, idx).includes(type)
            ? `A "${
                COST_ITEMS_DICTIONARY[type as CostItemType]
              }" cost item has already been added`
            : undefined
          : undefined
        : `"${type}" is not a valid Cost item type. Please select one among ${InsertableCostItemsTypes.toString()}`,
      description: description.trim()
        ? undefined
        : `Please enter a description`,
    })
  )
  if (
    !errors.costItems.length ||
    !errors.costItems.filter((item) => {
      const costItemWithoutUndefinedValues = JSON.parse(JSON.stringify(item))
      return value && Object.keys(costItemWithoutUndefinedValues).length !== 0
    }).length
  ) {
    // tslint:disable-next-line: no-delete
    delete errors.costItems
  }
  return errors
}

export const graphQLProposalToProposalEditableState = (
  proposal: Partial<Proposal>
): ProposalEditableState => ({
  additionalNotes: proposal.additionalNotes
    ? EditorState.createWithContent(
        convertFromRaw(graphQLArrayFormToRawState(proposal.additionalNotes))
      )
    : EditorState.createEmpty(),
  additionalNotesRenderKey: '',
  attachments: (proposal.attachments || []).map(withoutTypeName),
  availabilities: (proposal.availabilities || []).map(withoutTypeName),
  costItems: (proposal.costItems || []).map(withoutTypeName),
  name: proposal.name || '',
  images: (proposal.images || []).map(withoutTypeName),
  layouts: (proposal.layouts || []).map(withoutTypeName),
  space: merge<ProposalEditableState['space'], Partial<Proposal['space']>>(
    {
      city: '',
      description: proposal.space
        ? EditorState.createWithContent(
            convertFromRaw(
              graphQLArrayFormToRawState(proposal.space.description)
            )
          )
        : EditorState.createEmpty(),
      descriptionRenderKey: '',
      fullAddress: '',
      location: '',
      marketplaceId: null,
      name: '',
      hostEmail: '',
    },
    {
      ...(proposal.space && omit(proposal.space, 'description', 'maxCapacity')),
    }
  ),
  state: proposal.state || ProposalState.PENDING,
  totalCost: proposal.totalCost
    ? withoutTypeName(proposal.totalCost)
    : {
        amount: 0,
        currency: Currency.GBP,
        vatIncluded: false,
      },
})

export const proposalEditableStateToGraphQLProposalDataInput = (
  data: ProposalEditableState
): ProposalDataInput => {
  return {
    additionalNotes: rawStateToGraphQLArrayFormInput(
      convertToRaw(data.additionalNotes.getCurrentContent())
    ),
    attachments: data.attachments.map(({ id, name }) => ({
      id,
      name,
    })),
    availabilities: data.availabilities.map(
      ({ id, date, timings, optionHold }) => ({
        id: id || null,
        date,
        timings,
        optionHold,
      })
    ),
    costItems: data.costItems.map(({ id, type, description }) => ({
      id: id || null,
      type,
      description,
    })),
    images: data.images.map(({ id, name, caption }) => ({ id, name, caption })),
    layouts: data.layouts,
    name: data.space.name,
    space: {
      city: data.space.city,
      description: rawStateToGraphQLArrayFormInput(
        convertToRaw(data.space.description.getCurrentContent())
      ),
      fullAddress: data.space.fullAddress,
      location: data.space.location,
      marketplaceId: data.space.marketplaceId,
      name: data.space.name,
      hostEmail: data.space.hostEmail,
    },
    state: data.state,
    totalCost: {
      amount: data.totalCost.amount,
      currency: data.totalCost.currency,
      vatIncluded: data.totalCost.vatIncluded,
    },
  }
}

const withoutTypeName = <T extends object>(x: T) =>
  omit(x as any, '__typename') as Omit<T, '__typename'>
