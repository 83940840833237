import styled from '@emotion/styled'
import React from 'react'

import { Avatar } from 'app/components/ui/Avatar'
import { Icon } from 'app/components/ui/Icon'
import { breakpoints } from 'app/theme/breakpoints'
import { theme } from 'app/theme/themeDefault'
// import { PendoTrackComponent } from 'app/utils/pendo/PendoTrackComponent'

const StyledUserMenuToggler = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  i {
    margin-left: 5px;
    font-size: 1rem;
    color: inherit;
  }
`

const UserName = styled.div`
  font-size: ${theme.fontSize.normal};
  padding: var(--space-xxs) var(--space-unit);
  text-decoration: none;

  @media screen and (max-width: ${breakpoints.sm}) {
    display: none;
  }
`

interface Props {
  imageUrl: string
  altText?: string
  size?: string
  userName?: string
}

export const AvatarButtonToggler: React.FC<Props> = ({
  imageUrl,
  altText,
  size,
  userName,
  ...rest
}) => {
  return (
    <StyledUserMenuToggler {...rest}>
      {userName && <UserName>{userName}</UserName>}
      <Avatar
        noBorder
        imageUrl={imageUrl}
        altText={altText || ''}
        size={size || '45px'}
      />
      <Icon name='angle-down' />
    </StyledUserMenuToggler>
  )
}
