import styled from '@emotion/styled'
import { Formik } from 'formik'
import React from 'react'

import { Spacer } from 'app/components/layout/Spacer'
import { Button } from 'app/components/ui/Button'
import { ActorProvider } from 'app/containers/ActorProvider'
import { theme } from 'app/theme/themeDefault'
import { generatePendoDataAttribute } from 'app/utils/pendo/generatePendoId'

import { Input } from '../form/Input'
import { InputErrorMessage } from '../form/InputErrorMessage'
import { Label } from '../form/Label'
import { Textarea } from '../form/Textarea'
interface Props {
  enabled: boolean
  onSubmit: (data: CommentData) => Promise<any>
}

interface CommentData {
  authorName: string | null
  text: string
}

const StyledForm = styled.form``

export const NewCommentForm = ({ enabled, onSubmit }: Props) => {
  const initialValues = {
    authorName: '',
    text: '',
  }

  return (
    <ActorProvider
      fetchPolicy='cache-only'
      children={({ actor }) => {
        const isLoggedUser = !!(actor && actor.user)
        return (
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const errors: any = {}
              if (!isLoggedUser && !values.authorName.trim()) {
                errors.authorName = 'Your name is required'
              }
              if (!values.text.trim()) {
                errors.text = 'A message is required'
              }
              return errors
            }}
            onSubmit={(values, { resetForm }) => {
              if (!enabled) {
                return
              }
              onSubmit({
                authorName: values.authorName,
                text: values.text,
              }).then(() => resetForm())
            }}
          >
            {(formikBag) => {
              return (
                <StyledForm onSubmit={formikBag.handleSubmit}>
                  {isLoggedUser ? null : (
                    <Spacer size='lg'>
                      <Label htmlFor='authorName'>Name</Label>
                      <Input
                        value={formikBag.values.authorName}
                        onChange={formikBag.handleChange}
                        id='authorName'
                        name='authorName'
                        onBlur={() => undefined}
                        hasError={
                          !!(formikBag.errors && formikBag.errors.authorName)
                        }
                      />
                      {formikBag.errors && formikBag.errors.authorName ? (
                        <InputErrorMessage
                          message={formikBag.errors.authorName}
                        />
                      ) : null}
                    </Spacer>
                  )}
                  <Spacer size='lg'>
                    <Label htmlFor='text'>Your comment</Label>
                    <Textarea
                      rows={5}
                      maxRows={10}
                      value={formikBag.values.text}
                      onChange={formikBag.handleChange}
                      id='text'
                      name='text'
                    />
                    {formikBag.errors && formikBag.errors.text ? (
                      <InputErrorMessage message={formikBag.errors.text} />
                    ) : null}
                  </Spacer>
                  <Spacer size='sm'>
                    <Button
                      type='submit'
                      fullwidth
                      disabled={!enabled || !formikBag.values.text.trim()}
                      {...generatePendoDataAttribute(
                        'Button',
                        'Post New Comment'
                      )}
                    >
                      Post New Comment
                    </Button>
                  </Spacer>
                  <Emphasis>
                    Please note your comment can not be edited or deleted once
                    posted and it will be visible to anyone this quote is sent
                    to.
                  </Emphasis>
                </StyledForm>
              )
            }}
          </Formik>
        )
      }}
    />
  )
}

const Emphasis = styled.em`
  color: ${theme.colors.gray};
  font-style: italic;
`
