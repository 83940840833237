// TODO: need to be redefined and reworked
export const OPTIONSHOLD: ReadonlyArray<{ key: string; label: string }> = [
  {
    key: 'First',
    label: 'Held (1st)',
  },
  {
    key: 'Second',
    label: 'Held (2nd)',
  },
  {
    key: 'Forth',
    label: 'Not Held (1st)',
  },
  {
    key: 'Third',
    label: 'Not Held (2nd)',
  },
]
