import styled from '@emotion/styled'
import { theme } from 'app/theme/themeDefault'

export const LinkContainer = styled.a`
  position: relative;
  width: 100%;
  height: 70px;
  display: flex;
  text-decoration: none;
  color: ${theme.colors.black};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.colors.black};
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 0.1;
    }
  }
`

export const FileDetails = styled.div`
  flex-grow: 1;
  border: 1px solid ${theme.colors.buttons.secondary.background};
  border-left: none;
  display: flex;
  flex-direction: row;
  padding: 10px;
`

export const FileName = styled.div`
  position: relative;
  flex: 1;
  > span {
    font-size: ${theme.fontSize.small};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate(0, -50%);
  }
`

export const FileDownload = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1rem;
`

export const ButtonRemove = styled.div`
  font-size: ${theme.fontSize.small};
  margin-top: 0.5rem;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.danger};
  }
`

export const ButtonChangeName = styled.div`
  font-size: ${theme.fontSize.small};
  margin-top: 0.5rem;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
  }
`

export const FileIcon = styled.div`
  height: 100%;
  background-color: ${theme.colors.buttons.secondary.background};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  border: 1px solid ${theme.colors.buttons.secondary.background};
  border-right: 0;
`

export const NewNameInput = styled.input`
  flex: 1;
`
