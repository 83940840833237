import React, { useState } from 'react'

import { Carousel } from 'app/components/ui/Carousel'
import { useToggleSmart } from 'app/hooks/customHooks'
import { generatePendoDataAttribute } from 'app/utils/pendo/generatePendoId'

import { Container } from './styled'

interface Props {
  imagesURLs: readonly string[]
}

export const Gallery: React.FC<Props> = ({ imagesURLs }) => {
  const fullScreenVisibility = useToggleSmart(false)
  const [activeSlide, setActiveSlide] = useState<number>(0)

  return (
    <Container>
      <Carousel
        images={imagesURLs}
        arrows
        roundedDots
        autoFocus
        initialSlide={activeSlide}
        onImageClick={(current) => {
          setActiveSlide(current)
          fullScreenVisibility.setOn()
        }}
        dataAttributesForArrows={generatePendoDataAttribute(
          'Button',
          'Carousel arrow'
        )}
        dataAttributesForImageSlide={generatePendoDataAttribute(
          'Image',
          'Open full screen gallery'
        )}
      />

      {fullScreenVisibility.isOn && (
        <Carousel
          images={imagesURLs}
          initialSlide={activeSlide}
          arrows
          roundedDots
          autoFocus
          isFullScreen
          onImageClick={(_) => {
            fullScreenVisibility.setOff()
          }}
          onFullSreenClose={() => {
            fullScreenVisibility.setOff()
          }}
          afterChange={(current) => {
            setActiveSlide(current)
          }}
          dataAttributesForImageSlide={generatePendoDataAttribute(
            'Image',
            'Close full screen gallery'
          )}
          dataAttributesForArrows={generatePendoDataAttribute(
            'Button',
            'Carousel arrow Fullscreen Gallery'
          )}
        />
      )}
    </Container>
  )
}
