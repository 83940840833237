import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'

import { theme } from 'app/theme/themeDefault'

const StyledMessage = styled.div<{ floating?: boolean }>`
  display: block;
  font-size: ${theme.fontSize.small};
  color: ${theme.colors.danger};

  ${({ floating }) =>
    floating
      ? css`
          position: absolute;
        `
      : null}
`

interface Props {
  floating?: boolean
  message: string
}

export const InputErrorMessage = (props: Props) => {
  const { message, floating } = props
  return <StyledMessage floating={floating}>{message}</StyledMessage>
}
