import styled from '@emotion/styled'

import { breakpoints } from 'app/theme/breakpoints'
import { theme } from 'app/theme/themeDefault'

export const Container = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
`

export const Title = styled.h1<{ mobile: boolean }>`
  flex: 1;
  flex-shrink: 1;
  font-size: ${({ mobile }) =>
    mobile ? theme.headings.h4 : theme.headings.h1};
  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 1rem;
  }
`
export const PriceZone = styled.div`
  flex: 1;
  flex-shrink: 0;
  font-weight: 600;
  padding-left: 1rem;
  @media (max-width: ${breakpoints.md}) {
    padding-left: 0;
  }
`
export const PriceAmount = styled.span`
  font-size: 2.5rem;
  display: block;
`
export const Vat = styled.span`
  font-size: 1.25rem;
  display: block;
  text-align: right;
`
