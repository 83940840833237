import React from 'react'

import { ListMenu } from './ListMenu'
import {
  Backdrop,
  DropdownMenuWrapper,
  DropdownToggle,
  StyledDropdownWrapper,
} from './styled'

interface DropdownProps {
  children: React.ReactNode
  inline?: boolean
  isOpen: boolean
  menuWidth?: string
  onClick: (e: React.MouseEvent) => void
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  scroll?: boolean
  toggle: React.ReactChild
}

export const Dropdown = ({
  children,
  inline,
  isOpen,
  menuWidth,
  onClick,
  position,
  scroll,
  toggle,
}: DropdownProps) => {
  const top = position.includes('top')
  const bottom = position.includes('bottom')
  const left = position.includes('left')
  const right = position.includes('right')

  const onClickAndStopBubbling = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClick(e)
  }

  return (
    <StyledDropdownWrapper inline={inline || false}>
      <DropdownToggle onClick={onClickAndStopBubbling}>{toggle}</DropdownToggle>
      {isOpen && (
        <>
          <Backdrop onClick={onClickAndStopBubbling} />
          <DropdownMenuWrapper
            top={top}
            left={left}
            bottom={bottom}
            right={right}
            menuWidth={menuWidth}
            scroll={scroll || false}
          >
            <ListMenu menuWidth={menuWidth || '180px'}>{children}</ListMenu>
          </DropdownMenuWrapper>
        </>
      )}
    </StyledDropdownWrapper>
  )
}
