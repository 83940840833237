export const COST_ITEMS_DICTIONARY: CostItemsDictionary = {
  ['food-and-beverage']: {
    label: 'Food and Beverages',
    insertable: true,
    uniqueOfType: true,
  },
  ['av']: {
    label: 'AV Equipments',
    insertable: true,
    uniqueOfType: true,
  },
  ['accommodation']: {
    label: 'Accommodation',
    insertable: true,
    uniqueOfType: true,
  },
  ['security']: {
    label: 'Security',
    insertable: true,
    uniqueOfType: true,
  },
  ['service-charge']: {
    label: 'Service Charge',
    insertable: true,
    uniqueOfType: true,
  },
  ['deposit']: {
    label: 'Deposit',
    insertable: true,
    uniqueOfType: true,
  },
  ['room-hire']: {
    label: 'Room Hire',
    insertable: true,
    uniqueOfType: true,
  },
  ['minimum-spend']: {
    label: 'Minimum Spend',
    insertable: true,
    uniqueOfType: true,
  },
  ['event-managament']: {
    label: 'Event Management',
    insertable: true,
    uniqueOfType: true,
  },
}
export type CostItemsDictionary = Record<CostItemType, CostItemParams>
export type CostItemType =
  | 'food-and-beverage'
  | 'av'
  | 'accommodation'
  | 'security'
  | 'service-charge'
  | 'deposit'
  | 'room-hire'
  | 'minimum-spend'
  | 'event-managament'

export interface CostItemParams {
  label: string
  insertable: boolean
  uniqueOfType: boolean
}

export const CostItemsTypes = Object.keys(
  COST_ITEMS_DICTIONARY
) as readonly CostItemType[]

export const CostItemsTypesToBeUnique = Object.keys(
  COST_ITEMS_DICTIONARY
).filter(
  (costItemType) =>
    COST_ITEMS_DICTIONARY[costItemType as CostItemType].uniqueOfType
)

export const DEFAULT_OTHER_COST_TYPE = 'NEW-ITEM'
