import React from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import { Container } from './styled'

interface Props {
  disabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  value: any
  id?: string
  autoFocus?: boolean
  rows?: number
  maxRows?: number
  name?: string
  placeholder?: string
  hasError?: boolean
}

export const Textarea: React.FC<Props> = (props) => {
  return (
    <Container hasError={props.hasError}>
      <TextareaAutosize
        autoCorrect='false'
        autoCapitalize='false'
        spellCheck={false}
        autoComplete='off'
        {...props}
      />
    </Container>
  )
}
