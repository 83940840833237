import styled from '@emotion/styled'
import { rgba } from 'polished'

import { theme } from 'app/theme/themeDefault'

export const StyledMenu = styled.ul<{ menuWidth: string }>`
  display: block;
  min-width: 60px;
  width: ${({ menuWidth }) => menuWidth};
  max-height: 60vh;
  overflow-y: scroll;

  background-color: #fff;
  padding: 0.5rem 0;
`

export const StyledItem = styled.li<{ hasHoverState: boolean }>`
  display: block;
  color: ${theme.colors.gray};
  &:hover {
    background-color: ${({ hasHoverState }) =>
      hasHoverState ? `${rgba(theme.colors.primary, 0.1)}` : null};
  }
  > a,
  > div,
  > span {
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 0.65rem 1rem;
    font-size: ${theme.fontSize.normal};
    cursor: pointer;
    &:hover {
      color: inherit;
    }
  }
`
