import styled from '@emotion/styled'

import { breakpoints } from 'app/theme/breakpoints'
import { theme } from 'app/theme/themeDefault'

export const HeaderMenu = styled.section`
  display: flex;
  align-content: center;
  align-items: center;
  position: relative;
  z-index: ${theme.zIndex.header};
  color: ${theme.colors.white};

  > a,
  > span {
    display: inline-block;
    color: inherit;
    font-size: ${theme.fontSize.normal};
    padding: ${theme.spacingsLegacy.xxs} ${theme.spacingsLegacy.normal};
    padding: ${theme.spacings.xxs} ${theme.spacings.normal};
    text-decoration: none;
  }

  button {
    color: inherit;
  }

  @media screen and (max-width: ${breakpoints.smMax}) {
    > a,
    > span {
      display: none;
    }
  }
`

export const MainLogo = styled.img`
  width: 150px;
  max-height: 43px;
  display: inline-block;
`

export const StyledHeader = styled.header`
  width: 100%;
  padding: 0 0.5rem;
  height: 70px;
  background-color: ${theme.colors.brand.primary};
  color: #fff;
  display: flex;
  align-items: center;
`

export const FeedbackLinkContent = styled.span`
  position: relative;
  > span {
    display: block;
    font-size: 13px;
    position: absolute;
    top: 100%;
    opacity: 0.5;
  }
`
