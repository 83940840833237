import React from 'react'

import { ActionNav } from 'app/components/layout/ActionNav'
import { PageContainer } from 'app/components/layout/PageContainer'
import { Spacer } from 'app/components/layout/Spacer'

import { FlexBox } from 'app/components/layout/FlexBox'
import { breakpoints } from 'app/theme/breakpoints'
import Media from 'react-media'
import { BottomFixedCTA, Container, PageTitle, WhiteBar } from './styled'
interface Props {
  pageTitle: string
  primaryActionComponent?: React.ReactChild
  secondaryActionComponents?: React.ReactChild | React.ReactChildren
  fullWidth?: boolean
  extraContent?: React.ReactChild
}

export const Heading: React.FC<Props> = ({
  pageTitle,
  primaryActionComponent,
  secondaryActionComponents,
  fullWidth,
  extraContent,
}) => {
  return (
    <Container>
      <Spacer size='lg'>
        <WhiteBar>
          <PageContainer fluid={fullWidth}>
            <Media query={`(min-width: ${breakpoints.smMax})`}>
              {(matches) =>
                matches ? (
                  <FlexBox row dial={5} spaceBetween>
                    <PageTitle>{pageTitle}</PageTitle>
                    <ActionNav alignment='right'>
                      {secondaryActionComponents}
                      {primaryActionComponent}
                    </ActionNav>
                  </FlexBox>
                ) : (
                  <FlexBox dial={1} spaceBetween>
                    <Spacer size='md'>
                      <PageTitle>{pageTitle}</PageTitle>
                    </Spacer>
                    <ActionNav alignment='right'>
                      {secondaryActionComponents}
                    </ActionNav>
                    {primaryActionComponent && (
                      <BottomFixedCTA>{primaryActionComponent}</BottomFixedCTA>
                    )}
                  </FlexBox>
                )
              }
            </Media>
          </PageContainer>
        </WhiteBar>
        {extraContent}
      </Spacer>
    </Container>
  )
}
