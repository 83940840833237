// tslint:disable: readonly-array
import React, { useEffect, useState } from 'react'

import { Actor } from 'app/containers/ApolloGeneratedComponents'

interface PendoUser {
  id: number
  email: string
  firstname?: string
  lastname?: string
  avatar?: string
  company_logo?: string
  company_name?: string
  mobile_number?: string
  phone_number?: string
  job_title?: string
  roles: string[]
}

interface Props {
  actor: Actor
}

export const PendoActorTracker: React.FC<Props> = ({ actor }) => {
  const pendoUser = convertActorToPendoUser(actor)
  const userId = pendoUser && pendoUser.id
  const pendoSDK = (window as any).__pendoSDK
  const [pendoIsReady, setPendoIsReady] = useState(false)

  useEffect(() => {
    setPendoIsReady(Boolean(pendoSDK && pendoSDK.trackUserSession))
  }, [])

  useEffect(() => {
    if (pendoIsReady) {
      if (userId) {
        pendoSDK.trackUserSession(userId, () => pendoUser)
      } else {
        // pendo.trackAnonymousSession()
      }
    }
  }, [userId, pendoIsReady])

  return null
}

const convertActorToPendoUser = (actor: Actor): PendoUser | null => {
  if (!actor.user) {
    return null
  }
  const roles: string[] = []
  if (actor.isAccountManager) {
    roles.push('account_manager')
  }
  if (actor.isBusinessAdministrator) {
    roles.push('administrator')
  }
  if (actor.isBusinessManager) {
    roles.push('manager')
  }
  if (actor.isBusinessMember) {
    roles.push('member')
  }

  return {
    id: actor.user.id,
    email: actor.user.email,
    firstname: actor.user.firstName,
    lastname: actor.user.lastName,
    mobile_number: actor.user.mobileNumber || '',
    phone_number: actor.user.phoneNumber || '',
    company_name: actor.user.company ? actor.user.company.name : '',
    avatar: actor.user.avatarURL,
    job_title: '',
    roles,
  }
}
