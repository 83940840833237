import { Carousel as AntCarousel } from 'antd'
import React, { useEffect, useRef } from 'react'

import { useFocus } from 'app/hooks/useFocus'
import Placeholder from 'app/static/images/no-image-placeholder.png'
import { ArrowButton, ArrowButtonProps } from './ArrowButton'
import {
  ButtonClose,
  CarouselProps,
  Container,
  ContainerProps,
  ImageSlide,
  StyledAntCarousel,
} from './styled'

export interface Props extends CarouselProps, ContainerProps {
  autoFocus?: boolean
  hasLazyLoading?: boolean
  images?: readonly string[]
  onFullSreenClose?: () => void
  onImageClick?: (currentIndex: number) => void

  dataAttributesForArrows?: {
    [key: string]: string
  }
  dataAttributesForImageSlide?: {
    [key: string]: string
  }
}

export const Carousel: React.FC<Props> = ({
  autoFocus,
  children,
  images,
  isFullScreen,
  onFullSreenClose,
  onImageClick,
  initialSlide,
  dataAttributesForArrows,
  dataAttributesForImageSlide,
  hasLazyLoading,
  ...antCarouselProps
}) => {
  const slider = useRef<AntCarousel>(null)
  const elementToFocus = useFocus<HTMLDivElement>()

  useEffect(() => {
    if (slider.current && initialSlide !== undefined) {
      slider.current.goTo(initialSlide, true)
    }
  }, [initialSlide])

  return (
    <Container
      ref={autoFocus ? elementToFocus : undefined}
      tabIndex={0}
      onClick={(e) => {
        e.stopPropagation()
      }}
      isFullScreen={isFullScreen}
      onKeyDown={({ keyCode }) => {
        if (keyCode === 39 && slider.current) {
          slider.current.next()
        }
        if (keyCode === 37 && slider.current) {
          slider.current.prev()
        }
        if (keyCode === 27 && slider.current && onFullSreenClose) {
          onFullSreenClose()
        }
      }}
    >
      {onFullSreenClose && isFullScreen && (
        <ButtonClose
          title='close'
          buttonSize='50px'
          onClick={onFullSreenClose}
        />
      )}
      <StyledAntCarousel
        ref={slider}
        initialSlide={initialSlide || 0}
        lazyLoad={hasLazyLoading ? 'ondemand' : undefined}
        prevArrow={
          antCarouselProps.arrows ? (
            <ArrowButtonComponent
              direction='prev'
              size={40}
              dataAttributesForArrows={dataAttributesForArrows}
            />
          ) : undefined
        }
        nextArrow={
          antCarouselProps.arrows ? (
            <ArrowButtonComponent
              direction='next'
              size={40}
              dataAttributesForArrows={dataAttributesForArrows}
            />
          ) : undefined
        }
        {...antCarouselProps}
        children={
          images ? (
            images.length ? (
              images.map((image, idx) => (
                <ImageSlide
                  key={idx}
                  image={image}
                  isFullScreen={isFullScreen}
                  isAnimated={isFullScreen}
                  cursor={onImageClick ? 'pointer' : 'default'}
                  onClick={onImageClick ? () => onImageClick(idx) : undefined}
                  {...dataAttributesForImageSlide}
                />
              ))
            ) : (
              <ImageSlide
                image={Placeholder}
                isFullScreen={isFullScreen}
                cursor={onImageClick ? 'pointer' : 'default'}
                onClick={onImageClick ? () => onImageClick(0) : undefined}
                {...dataAttributesForImageSlide}
              />
            )
          ) : (
            children
          )
        }
      />
    </Container>
  )
}

// fix for this -> https://github.com/akiran/react-slick/issues/1195
interface ArrowButtonComponentProps
  extends ArrowButtonProps,
    Pick<Props, 'dataAttributesForArrows'> {
  //
}
const ArrowButtonComponent: React.FC<ArrowButtonComponentProps> = ({
  dataAttributesForArrows,
  direction,
  size,
  onClick,
}) => {
  return (
    <ArrowButton
      onClick={onClick}
      direction={direction}
      size={size}
      {...dataAttributesForArrows}
    />
  )
}
