import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { theme } from 'app/theme/themeDefault'

export interface SpinnerProps {
  fullscreen?: boolean
  small?: boolean
}

const Container = styled.div<SpinnerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${({ small }) => (small ? '2rem 0' : '0')};
  text-align: unset;
  &,
  & > * {
    user-select: none;
  }

  ${({ fullscreen }) =>
    fullscreen
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.55);
          z-index: ${theme.zIndex.overall + 10};
          padding: 0 0 3rem !important;
          margin: 0 !important;
        `
      : null}
`

const LogoImage = styled.img`
  width: 50px;
  filter: brightness(64%) saturate(0%);
  margin-bottom: -18px;
`

const SpinnerElement = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
`

const AnimatedDot = styled.div`
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: ${theme.colors.brand.primary};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-of-type(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
    background: ${theme.colors.brand.primary};
  }

  &:nth-of-type(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
    background: ${theme.colors.brand.dark};
  }

  &:nth-of-type(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
    background: ${theme.colors.primary};
  }

  &:nth-of-type(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
    background: ${theme.colors.primary};
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
`

export const StyledSpinner = {
  Container,
  LogoImage,
  SpinnerElement,
  AnimatedDot,
}
