import { css as emotionCss, SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'
import { darken } from 'polished'

import { theme } from 'app/theme/themeDefault'
export interface Props {
  type: 'success' | 'plain' | 'warning' | 'error' | 'rejected'
  css?: SerializedStyles
}
export const getStyleType = ({ type }: Props) => {
  switch (type) {
    case 'success':
      return emotionCss`
        background-color: ${theme.colors.success};
        color: #fff;
      `
    case 'rejected':
      return emotionCss`
        background-color: ${theme.colors.primary};
        color: #fff;
      `

    case 'warning':
      return emotionCss`
        background-color: ${theme.colors.warning};
        color: ${darken(0.3, theme.colors.warning)};
      `
    case 'error':
      return emotionCss`
        background-color: ${theme.colors.danger};
        color: #fff;
      `
    default:
      return emotionCss`
        background-color: ${theme.colors.components.inputBackground};
        color: #000;
      `
  }
}

export const StyledTag = styled.div<Props>`
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;

  ${({ css }) => css}
  ${getStyleType}
`
