import styled from '@emotion/styled'
import { theme } from 'app/theme/themeDefault'
import React from 'react'

export interface ModalHeaderProps {
  title?: string
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ title }) =>
  title ? (
    <Header>
      <Title>{title}</Title>
    </Header>
  ) : null

const Header = styled.header`
  flex: 0 0 auto;
  padding-bottom: ${theme.spacings.sm};
`

const Title = styled.h4``
