import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'

export interface DropdownMenuItemProps {
  disabled?: boolean
  onItemClick?: () => void
  alignText?: 'left' | 'right' | 'center'
}
export const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  disabled,
  children,
  onItemClick,
  alignText,
}) => {
  return (
    <Item
      onClick={(e) => {
        e.stopPropagation()
        if (!disabled && onItemClick) {
          onItemClick()
        }
      }}
      disabled={disabled}
      alignText={alignText}
    >
      {children}
    </Item>
  )
}

const Item = styled.div<DropdownMenuItemProps>`
  white-space: nowrap;
  text-align: ${({ alignText }) => alignText || 'left'};

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.4;
          pointer-events: none;
          touch-action: none;
        `
      : null}
`
