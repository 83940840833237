import { SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'

import { alignItems } from './alignItems'
import { flexDirection } from './flexDirection'
import { justifyContent } from './justifyContent'
import { shorthandStyles } from './shorthandStyles'

const Div = styled.div<Props>`
  display: flex;
  ${alignItems};
  ${flexDirection};
  ${justifyContent};
  ${shorthandStyles};
  ${({ css }) => css}
`

const Section = styled.section<Props>`
  display: flex;
  ${alignItems};
  ${flexDirection};
  ${justifyContent};
  ${shorthandStyles};
  ${({ css }) => css}
`

export const FlexBox: React.FC<Props> = (props) => {
  const Component = props.type === 'section' ? Section : Div

  return <Component {...props} css={props.css} />
}

export interface Props {
  /**
   * The type of node to use
   */
  type?: 'div' | 'section'

  /**
   * Positions children according to the position of the `dial` number on a phone dial pad:
   *
   * ||
   * |---|---|---|
   * | 1 | 2 | 3 |
   * | 4 | 5 | 6 |
   * | 7 | 8 | 9 |
   */
  dial: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

  /**
   * Uses flexbox row disposition instead of column
   */
  row?: boolean

  /**
   * Reverses the column or row direction
   */
  reverse?: boolean

  spaceBetween?: boolean

  spaceAround?: boolean

  spaceEvenly?: boolean

  stretch?: boolean

  /**
   * You can pass a value (number or string) or an array of values to mimic css behaviour.
   *
   * Examples:
   *
   * - 20 *(becomes 20px)*
   * - 2em
   * - ['2em'] *(equivalent to the above value)*
   * - [20, '1em']
   * - [10, 30, 20]
   * - [10, 30, 20, 0]
   */
  margin?: ShorthandCSSDimensions

  /**
   * Like `margin` prop
   */
  padding?: ShorthandCSSDimensions

  onClick?: React.MouseEventHandler

  css?: SerializedStyles
}

export type CSSDimension = number | string

// tslint:disable-next-line: readonly-array
export type ShorthandCSSDimensions =
  | CSSDimension
  | readonly [CSSDimension]
  | readonly [CSSDimension, CSSDimension]
  | readonly [CSSDimension, CSSDimension, CSSDimension]
  | readonly [CSSDimension, CSSDimension, CSSDimension, CSSDimension]
