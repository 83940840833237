import React from 'react'

import { Price } from 'app/containers/ApolloGeneratedComponents'
import { formatPrice } from 'app/utils/currency'

import { Price as PriceItems } from './styled'

export const PriceTag = ({ amount, currency, vatIncluded }: Price) => (
  <PriceItems.Container>
    <PriceItems.AmountLine>
      {formatPrice(amount, currency)}
    </PriceItems.AmountLine>
    <PriceItems.VatLine>{` ${vatIncluded ? 'inc.' : 'exc.'} ${
      currency === 'AUD' ? 'GST' : 'VAT'
    }`}</PriceItems.VatLine>
  </PriceItems.Container>
)
