/**
 * Returns a nice string from and ISO date
 */
const monthNames: ReadonlyArray<string> = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const isoDateToNiceString = (isoDate: Date | null): string => {
  if (!isoDate) {
    return ''
  }
  const d = new Date(isoDate)
  const day = d.getDate()
  const month = d.getMonth()
  const year = d.getFullYear()

  return `${day} ${monthNames[month]} ${year}`
}

export const dateStringToNiceString = (dateString: string | null): string => {
  if (!dateString) {
    return ''
  }
  const [year, month, day] = dateString.split('-')
  return `${day} ${monthNames[parseInt(month, 10) - 1]} ${year}`
}

export const isoDateToDateWithTimeString = (isoDate: Date | null): string => {
  if (!isoDate) {
    return ''
  }
  const d = new Date(isoDate)
  const day = d.getDate()
  const month = d.getMonth()
  const year = d.getFullYear()
  const hours = d.getHours().toString().padStart(2, '0')
  const minutes = d.getMinutes().toString().padStart(2, '0')

  return `${day}/${month + 1}/${year} at ${hours}:${minutes}`
}
