// tslint:disable: no-submodule-imports
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Carousel } from 'antd'
import { CarouselProps as AntCarouselProps } from 'antd/lib/carousel'

import 'antd/es/carousel/style/index.css'

export interface CarouselProps extends AntCarouselProps {
  roundedDots?: boolean
}

export interface ContainerProps {
  isFullScreen?: boolean
}

interface ImageSlideProp {
  image: string
  isFullScreen?: boolean
  isAnimated?: boolean
  cursor: 'default' | 'pointer'
}

const getContainerSize = ({ isFullScreen }: ContainerProps) =>
  isFullScreen
    ? css`
        position: fixed;
        z-index: 9999;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 1rem;
        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          position: -ms-page;
        }
      `
    : css`
        height: 100%;
      `

export const Container = styled.div<ContainerProps>`
  ${getContainerSize}
  .ant-carousel {
    height: 100%;
  }
`

const dotsCss = ({ roundedDots }: CarouselProps) =>
  roundedDots
    ? css`
        height: 15px;
        li > button {
          width: 15px;
          height: 15px;
          border-radius: 50%;
        }
        li.slick-active {
          button {
            width: 15px;
          }
        }
      `
    : null

export const StyledAntCarousel = styled(Carousel)<CarouselProps>`
  &.slick-slider {
    height: 100%;

    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100%;
    }

    .slick-slide > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .slick-dots {
      ${dotsCss}
    }
  }
`

const imageSlideAnimation = ({ isFullScreen, isAnimated }: ImageSlideProp) =>
  isFullScreen && isAnimated
    ? css`
        animation: zoomIn 0.2s ease-in 0s 1 forwards;
        @keyframes zoomIn {
          from {
            transform: scale(0.3);
          }
          to {
            transform: scale(1);
          }
        }
      `
    : null

const forceBackgroundPrinting = css`
  @media print {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
`

export const ImageSlide = styled.div<ImageSlideProp>`
  height: 100%;
  width: 100%;

  background-size: ${({ isFullScreen }) =>
    isFullScreen ? 'contain' : 'cover'};
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ image }) => `url("${image}")`};
  cursor: ${({ cursor }) => cursor};

  ${imageSlideAnimation};
  ${forceBackgroundPrinting};
`

export const ButtonClose = styled.button<{ buttonSize: string }>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ buttonSize }) => buttonSize};
  height: ${({ buttonSize }) => buttonSize};
  line-height: ${({ buttonSize }) => buttonSize};
  font-size: ${({ buttonSize }) => buttonSize};
  cursor: pointer;
  border: 0;
  background-color: transparent;
  color: #fff;
  z-index: 10;

  &:focus {
    outline: none;
  }

  &:before {
    content: '×';
    font-family: Arial, sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    line-height: inherit;
    font-size: inherit;
  }
`
