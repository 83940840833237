import styled from '@emotion/styled'

import { breakpoints } from 'app/theme/breakpoints'

export const Container = styled.div`
  height: 250px;
  @media (min-width: ${breakpoints.md}) {
    height: 350px;
  }
`
