import styled from '@emotion/styled'
import { Footer } from 'app/components/composite/Footer'
import { NotificationArea } from 'app/components/composite/NotificationArea'
import { FlexBox } from 'app/components/layout/FlexBox'
import { routes } from 'app/routes'
import React from 'react'
import { useLocation } from 'react-router-dom'

const displayFooter = () => {
  const path = useLocation().pathname
  return !path.startsWith('/' + routes.viewProposal.path.split('/')[1])
}

export const RoutingMainRenderZone: React.FC<{}> = ({ children }) => (
  <FlexContainer dial={2} stretch spaceBetween>
    <RenderZone>{children}</RenderZone>
    {displayFooter() && <Footer />}

    <NotificationArea />
  </FlexContainer>
)

const FlexContainer = styled(FlexBox)`
  min-height: 100vh;

  /* this layout doesn't work in IE10 and IE11, we fallback to a simple display block */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
  }
`

const RenderZone = styled.div`
  position: relative;
  flex: 1;
`
