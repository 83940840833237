import styled from '@emotion/styled'
import React from 'react'

import { theme } from 'app/theme/themeDefault'
import { isoDateToDateWithTimeString } from 'app/utils/date'

export interface CommentData {
  id: string
  author: {
    fullName: string
    companyName: string | null
  }
  date: Date
  text: string
}

const Wrapper = styled.div`
  padding: 1rem;
  background-color: #fff;
  margin-bottom: 0.5rem;
  border-radius: ${theme.borderRadius};
`

const Name = styled.div`
  font-weight: bold;
  color: ${theme.colors.primary};
`

const DateTime = styled.div`
  font-size: 0.8rem;
  color: ${theme.colors.grayLight};
  margin-bottom: 0.5rem;
`

const MessageContent = styled.div`
  display: block;
`

export const Comment: React.FC<CommentData> = ({ author, date, text }) => (
  <Wrapper>
    <Name>{author.fullName}</Name>
    <DateTime>
      {author.companyName ? `${author.companyName} - ` : ''}
      {isoDateToDateWithTimeString(date)}
    </DateTime>
    <MessageContent>
      {text.split('\n').map((row, idx) => (
        <p key={idx}>{row}</p>
      ))}
    </MessageContent>
  </Wrapper>
)
