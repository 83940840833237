import { FetchPolicy } from 'apollo-client'

import {
  Actor,
  ContainersActorProviderDocument,
  ContainersActorProviderQuery,
} from 'app/containers/ApolloGeneratedComponents'

import { apolloClient } from 'app/ApolloClient'
import { useEffect, useState } from 'react'

type UseActor = (
  fetchPolicy?: FetchPolicy
) => {
  actor: Actor | null
  error: boolean
  loading: boolean
  refetch: () => void
}

export const useActor: UseActor = (fetchPolicyProp = 'cache-first') => {
  const [fetchPolicy, setFetchPolicy] = useState<FetchPolicy>(fetchPolicyProp)
  const [actor, setActor] = useState<Actor | null>(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [refetchRequest, requestRefetch] = useState({})

  useEffect(() => {
    apolloClient
      .query<ContainersActorProviderQuery>({
        query: ContainersActorProviderDocument,
        fetchPolicy,
      })
      .then((result) => {
        setActor(result.data.actor)
        setError(Boolean(result.errors && result.errors.length))
        setLoading(result.loading)
      })
      .catch(() => {
        setError(true)
      })
  }, [fetchPolicy, refetchRequest])

  return {
    actor,
    error,
    loading,
    refetch: () => {
      setFetchPolicy('network-only')
      requestRefetch({})
    },
  }
}
