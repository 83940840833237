import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { theme } from 'app/theme/themeDefault'

export const RTEEditorCss = css`
  .RTEEditor,
  .DraftEditor-editorContainer {
    font-family: ${theme.fonts.sansSerif};
    font-size: ${theme.fontSize.normal};
    line-height: ${theme.lineHeight.normal};

    &.readOnly {
      border: none;
      .public-DraftEditor-content {
        padding: 0 !important;
      }
    }
  }
`
export const Container = styled.div`
  ${RTEEditorCss}
`

export const SectionTitle = styled.h4``
