import React from 'react'
import Loadable from 'react-loadable'

import { AsyncLoadingScreen } from '../AsyncLoadingScreen'
import { Props } from './index'

export const ProposalModificationScreen = Loadable<Props, React.FC<Props>>({
  loader: () =>
    import('./index').then(
      ({ ProposalModificationScreen: Component }) => Component
    ),
  loading: AsyncLoadingScreen,
  render: (Component, props) => {
    return <Component {...props} />
  },
})
