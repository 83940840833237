import { EditorState } from 'draft-js'
import React from 'react'

import { Card } from 'app/components/layout/Card'
import {
  Price,
  ProposalAvailabilityInput,
  ProposalCostItemInput,
  ProposalLayoutInput,
  ProposalSpace,
  ProposalState,
} from 'app/containers/ApolloGeneratedComponents'
import { useResponsive } from 'app/hooks/useResponsive'

import { Spacer } from 'app/components/layout/Spacer'
import { checkIfEditorStateContainsText } from 'app/utils/draftJS'
import { AdditionalNotesEditor } from './AdditionalNotesEditor'
import { AttachmentProps, Attachments } from './Attachments'
import { DescriptionEditor } from './DescriptionEditor'
import { Details } from './Details'
import { Gallery } from './Gallery'
import { Map } from './Map'
import { NameAndPrice } from './NameAndPrice'
import { Container, SectionTitle } from './styled'

export interface Props {
  id?: string
  name: string
  state: ProposalState
  totalCost: Price
  space: Omit<ProposalSpace, 'description'> & {
    description: EditorState
  }
  layouts: readonly ProposalLayoutInput[]
  imageURLs: readonly string[]
  attachments: ReadonlyArray<AttachmentProps>
  availabilities: readonly ProposalAvailabilityInput[]
  costItems: readonly ProposalCostItemInput[]
  additionalNotes: EditorState
}

export const ProposalPreview: React.FC<Props> = ({
  id,
  additionalNotes,
  attachments,
  availabilities,
  costItems,
  imageURLs,
  layouts,
  space,
  totalCost,
}) => {
  const responsivePaddingV = useResponsive('xl', 'xl', 'xl', 'xl', 'xxl')
  const responsivePaddingH = useResponsive('lg', 'lg', 'xl', 'xl', 'xxxl')

  return (
    <Container>
      <Card verticalPadding='none' horizontalPadding='none'>
        <Gallery imagesURLs={imageURLs} />
      </Card>
      <Card
        stacked
        verticalPadding={responsivePaddingV}
        horizontalPadding={responsivePaddingH}
      >
        <Spacer size='lg'>
          <NameAndPrice name={space.name} price={totalCost} />
        </Spacer>
        <Details
          availabilities={availabilities}
          costItems={costItems}
          layouts={layouts}
          spaceLocation={space.location}
          spaceMarketplaceURL={space.marketplaceURL}
          spaceTour3dURL={space.tour3dURL}
        />
      </Card>
      {checkIfEditorStateContainsText(space.description) ? (
        <Card
          stacked
          verticalPadding={responsivePaddingV}
          horizontalPadding={responsivePaddingH}
        >
          <Spacer size='lg'>
            <SectionTitle>Package Description</SectionTitle>
          </Spacer>
          <DescriptionEditor
            key={id}
            editable={false}
            state={space.description}
          />
        </Card>
      ) : null}
      {checkIfEditorStateContainsText(additionalNotes) ? (
        <Card
          stacked
          verticalPadding={responsivePaddingV}
          horizontalPadding={responsivePaddingH}
        >
          <Spacer size='lg'>
            <SectionTitle>Additional Notes</SectionTitle>
          </Spacer>
          <AdditionalNotesEditor
            key={id}
            editable={false}
            state={additionalNotes}
          />
        </Card>
      ) : null}
      {attachments.length ? (
        <Card
          stacked
          verticalPadding={responsivePaddingV}
          horizontalPadding={responsivePaddingH}
        >
          <Attachments attachments={attachments} />
        </Card>
      ) : null}
      <Card
        stacked
        verticalPadding={responsivePaddingV}
        horizontalPadding={responsivePaddingH}
      >
        <Map address={space.fullAddress} />
      </Card>
    </Container>
  )
}
