import React from 'react'

import { MainHeader } from 'app/components/composite/MainHeader'
import { FlexBox } from 'app/components/layout/FlexBox'
import { Spacer } from 'app/components/layout/Spacer'
import { NoDataPlaceholder } from 'app/components/NoDataPlaceholder'
import { ButtonLink } from 'app/components/ui/ButtonLink'
import { routes } from 'app/routes'

export interface Props {
  message?: string
  hideMainheader?: boolean
  hideGoToRootButton?: boolean
}

export const PageNotFoundScreen: React.FC<Props> = ({
  message,
  hideGoToRootButton,
  hideMainheader,
}) => (
  <>
    {!hideMainheader && <MainHeader />}
    <Spacer size='xxl' />
    <Spacer size='lg'>
      <NoDataPlaceholder message={message ? message : 'Page not found'} />
    </Spacer>
    {!hideGoToRootButton && (
      <FlexBox dial={5}>
        <ButtonLink to={routes.root.makeUrl()}>Visit Home Page</ButtonLink>
      </FlexBox>
    )}
    <Spacer size='xxl' />
  </>
)
