import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'

import { Spacer } from 'app/components/layout/Spacer'
import { Typography } from 'app/components/ui/Typography'
import { SpacingValue, theme } from 'app/theme/themeDefault'

interface Props {
  label: string
  lines: readonly string[]
  fontSize?: string
  lineHeight?: string
  clampToMax?: number
  spacing?: SpacingValue
}

export const ClampedLinesWithLabel = ({
  label,
  lines,
  fontSize,
  lineHeight,
  clampToMax,
  spacing,
}: Props) => (
  <Spacer size={spacing || 'md'}>
    <Spacer size='xxs'>
      <Typography weight='bold'>{label}</Typography>
    </Spacer>
    <Typography wordBreak='break-word'>
      {getTextContainer(getTextFragments(lines), {
        clampLimit: clampToMax,
        fontSize,
        lineHeight,
      })}
    </Typography>
  </Spacer>
)

const getTextFragments = (lines: readonly string[]) =>
  lines.map((line, idx, list) => (
    <React.Fragment key={idx}>
      {line}
      {idx < list.length - 1 ? <br /> : null}
    </React.Fragment>
  ))

const getTextContainer = (
  textFragments: string | readonly JSX.Element[],
  containerProps: TextContainerProps
) =>
  containerProps.clampLimit !== undefined && containerProps.clampLimit > 0 ? (
    <ClampContainer {...containerProps}>{textFragments}</ClampContainer>
  ) : (
    textFragments
  )

const getMaxHeight = ({ clampLimit, lineHeight }: TextContainerProps) => {
  const lh = lineHeight || theme.lineHeight.normal
  return css`
    max-height: calc(${lh} * ${clampLimit!});
  `
}

const ClampContainer = styled.div<TextContainerProps>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ clampLimit }) => clampLimit!};
  -webkit-box-orient: vertical;
  font-size: ${({ fontSize }) => fontSize || theme.fontSize.normal};
  line-height: ${({ lineHeight }) => lineHeight || theme.lineHeight.normal};
  ${getMaxHeight}
`

interface TextContainerProps {
  fontSize?: string
  lineHeight?: string
  clampLimit?: number
}
