import { spacingObject, spacingObjectLegacy } from './spacingsCSS'
import { colors } from './tokens/colors'
import { containerSize } from './tokens/containerSize'
import { headings } from './tokens/headings'

export type SpacingValue = Partial<keyof typeof theme.spacings>
export type Colors = keyof typeof theme.colors

export const theme = {
  colors,
  headings,
  containerSize,
  spacings: spacingObject,
  spacingsLegacy: spacingObjectLegacy,
  fonts: {
    sansSerif: 'mreavesxlmod, sans-serif',
  },
  baseFontSize: '16px',
  borderRadius: '4px',
  boxShadow:
    '0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 1px -3px rgba(0, 0, 0, 0.1)',
  buttonPadding: '0.5rem 1.25rem',
  boxShadowInputFocus: `0 0 2px ${colors.primary}`,
  zIndex: {
    dropdown: 200,
    modal: 300,
    sticky: 400,
    header: 500,
    overall: 1000,
    notifications: 1100,
  },
  fontSize: {
    small: '1rem',
    large: '1.25rem',
    normal: '1.125rem',
  },
  lineHeight: {
    normal: '1.4',
  },
  inputs: {
    padding: '0.5rem 1rem',
    height: '45px',
  },
}
