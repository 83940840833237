import { css } from '@emotion/core'
import React from 'react'

import { Card } from 'app/components/layout/Card'
import { Tag } from 'app/components/ui/Tag'

import { Actions, Props as ActionsProps } from './Actions'
import { Details, Props as DetailsProps } from './Details'

import { Props as QuickGalleryProps, QuickGallery } from './QuickGallery'
import {
  acceptedCardCss,
  acceptedTagCss,
  FlexContainer,
  rejectedCardCss,
  rejectedTagCss,
} from './styled'

interface Props extends DetailsProps, QuickGalleryProps, ActionsProps {
  state: 'accepted' | 'pending' | 'rejected'
  spaceId?: string
  stateReason?: string
}

const ACCEPTED_STATUS = 'accepted'
const REJECTED_STATUS = 'rejected'

export const ProposalCard: React.FC<Props> = ({
  state,
  stateReason,
  spaceId,
  ...rest
}) => {
  const assignTagCSS = (stateValueForStyle: Props['state']) => {
    switch (stateValueForStyle) {
      case ACCEPTED_STATUS:
        return acceptedCardCss

      case REJECTED_STATUS:
        return rejectedCardCss

      default:
        return undefined
    }
  }

  return (
    <Card
      horizontalPadding='none'
      verticalPadding='none'
      css={assignTagCSS(state)}
    >
      <FlexContainer dial={5} disabled={state === REJECTED_STATUS}>
        <QuickGallery {...rest} />
        <Details {...rest} />
      </FlexContainer>
      <Actions {...rest} />
      {state === ACCEPTED_STATUS && (
        <Tag type='success' text='ACCEPTED' css={acceptedTagCss} />
      )}
      {state === REJECTED_STATUS && (
        <Tag
          type='rejected'
          text={`REJECTED : ${stateReason}`}
          css={rejectedTagCss}
        />
      )}
      {spaceId ? (
        <Tag
          type='plain'
          text={spaceId}
          css={css`
            position: absolute;
            top: 0;
            right: 0;
          `}
        />
      ) : null}
    </Card>
  )
}
