import styled from '@emotion/styled'
import { theme } from 'app/theme/themeDefault'

export const Wrapper = styled.div`
  font-family: ${theme.fonts.sansSerif};
  width: 100%;
  height: 100%;
  font-size: ${theme.fontSize.small};
  a {
    &:hover {
      font-weight: bold;
    }
  }
`

export const Title = styled.div`
  font-weight: bold;
  padding-bottom: ${theme.spacings.xs};
  max-width: 100px;
  min-width: 50px;
`

export const Item = styled.div`
  padding-bottom: ${theme.spacings.xs};
  max-width: 100px;
`

export const Link = styled.div`
  color: ${theme.colors.primary};
  min-width: 70px;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.brand.dark};
    font-weight: bold;
  }
`

// tslint:disable-next-line: readonly-array
export const MapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },

  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },

  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },

  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },

  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },

  {
    featureType: 'transit.line',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },

  {
    featureType: 'transit.station.airport',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },

  {
    featureType: 'transit.station.bus',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'transit.station.rail',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'on',
      },

      {
        saturation: -6,
      },
      {
        weight: 10,
      },
    ],
  },
  {
    featureType: 'transit.station.rail',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'transit.station.rail',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
]
