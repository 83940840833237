import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import React, { useState } from 'react'
import RichTextEditor from 'react-rte'

type Props = { state: EditorState } & (
  | {
      editable: false
      onStateChange?: (newState: EditorState) => void
    }
  | {
      editable: true
      onStateChange: (newState: EditorState) => void
    }
)

export const AdditionalNotesEditor: React.FC<Props> = ({
  editable,
  onStateChange,
  state,
}) => {
  const [value, setValue] = useState(
    RichTextEditor.createValueFromString(
      stateToHTML(state.getCurrentContent()),
      'html'
    )
  )

  return (
    <RichTextEditor
      className={editable ? 'RTEEditor' : 'RTEEditor readOnly'}
      value={value}
      readOnly={!editable}
      placeholder={editable ? 'Type an note...' : undefined}
      onChange={(newValue) => {
        if (editable) {
          setValue(newValue)
          if (onStateChange) {
            onStateChange(
              EditorState.createWithContent(
                stateFromHTML(newValue.toString('html'))
              )
            )
          }
        }
      }}
      toolbarConfig={toolbarConfig as any}
    />
  )
}

const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
}
