import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { theme } from 'app/theme/themeDefault'

export const InputWrapper = styled.div`
  width: 100%;
`

export interface Props {
  variant: 'normal' | 'minimal'
  hasError: boolean
}

const getErrorStyle = ({ hasError = false }: Props) =>
  hasError
    ? css`
        border: 1px solid ${theme.colors.danger};
      `
    : css`
        border: 1px solid ${theme.colors.components.inputBorders};
      `

const variantCSS = ({ variant }: Props) => {
  switch (variant) {
    case 'normal':
      return css`
        border-radius: ${theme.borderRadius};
        background-color: ${theme.colors.components.inputBackground};
        font-size: ${theme.fontSize.normal};
        font-weight: normal;
        height: ${theme.inputs.height};
        padding: ${theme.inputs.padding};
      `
    case 'minimal':
      return css`
        border-radius: 0;
        background-color: #fff;
        font-size: ${theme.fontSize.small};
        height: 1.5rem;
        font-weight: bold;
        padding: 0;
      `
  }
}

export const StyledInput = styled.input<Props>`
  display: block;
  width: 100%;
  color: ${theme.colors.black};
  ${getErrorStyle};
  ${variantCSS}

  &:focus {
    outline: none;
    box-shadow: inset ${theme.boxShadowInputFocus};
  }
`
