import styled from '@emotion/styled'

export const StyledCardUser = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardBody = styled.div``

export const TitleWrapper = styled.div`
  overflow: hidden;
  flex-grow: 1;
`

export const AvatarWrapper = styled.div`
  margin-left: 8px;
`

export const Image = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100px;
`
