import React from 'react'

import { Grid } from 'app/components/layout/Grid'
import { Spacer } from 'app/components/layout/Spacer'

import { Attachment, Props as AttachmentProps } from './Attachment'
export { Props as AttachmentProps } from './Attachment'

interface Props {
  attachments: ReadonlyArray<AttachmentProps>
}

export const Attachments: React.FC<Props> = ({ attachments }) => {
  return (
    <>
      <Spacer size='lg'>
        <h4>Attachments</h4>
      </Spacer>
      <Grid
        columnsTemplate='1fr 1fr'
        columnsTemplateBelowBreakpoint='1fr'
        gap='lg'
      >
        {attachments.map((attachmentProps) => (
          <Attachment key={attachmentProps.id} {...attachmentProps} />
        ))}
      </Grid>
    </>
  )
}
