import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { FlexBox } from 'app/components/layout/FlexBox'
import { theme } from 'app/theme/themeDefault'

interface ContainerProps {
  disabled: boolean
}

const getDisabledStyle = ({ disabled }: ContainerProps) =>
  disabled
    ? css`
        position: relative;
        user-select: none;
        cursor: pointer;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          opacity: 0.7;
          background-color: white;
          pointer-events: none;
        }
      `
    : css`
        cursor: pointer;
      `

export const FlexContainer = styled(FlexBox)<ContainerProps>`
  ${getDisabledStyle};
`

export const acceptedCardCss = css`
  border: 2px solid ${theme.colors.success};
`
export const rejectedCardCss = css`
  border: 2px solid ${theme.colors.primary};
`
export const acceptedTagCss = css`
  position: absolute;
  top: 0;
  left: 0;
`

export const rejectedTagCss = css`
  position: absolute;
  top: 0;
  left: 0;
`
