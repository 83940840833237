export const colors = {
  primary: '#1f2a44',
  gray: '#4a4c49',
  grayLighter: '#a5a6a4',
  grayLight: '#808280',
  secondary: '#bcbfc7',
  success: '#28a745',
  warning: '#ffc107',
  danger: '#dc3545',
  error: '#D0021B',
  body: '#e9eaec',
  black: '#1e1e1d',
  white: '#fff',
  almostWhite: '#f5f5f5',
  brand: {
    primary: '#1f2a44',
    dark: '#131929',
  },
  components: {
    inputBorders: '#EBEBEF',
    inputBackground: '#F9F9F9',
    stageLabel: '#1f2a44',
    footer: '#4a4c49',
  },
  buttons: {
    secondary: {
      background: '#dbdbdb',
      border: '#e5ebee',
    },
  },
}
