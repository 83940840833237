import React from 'react'

import Logo from 'app/static/images/logoHB.png'

import { SpinnerProps, StyledSpinner } from './styled'

export const LoadingSpinner: React.FC<SpinnerProps> = ({
  fullscreen,
  small,
}) => {
  return (
    <StyledSpinner.Container
      fullscreen={fullscreen}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {small ? null : <StyledSpinner.LogoImage src={Logo} alt='Loading...' />}
      <StyledSpinner.SpinnerElement>
        <StyledSpinner.AnimatedDot />
        <StyledSpinner.AnimatedDot />
        <StyledSpinner.AnimatedDot />
        <StyledSpinner.AnimatedDot />
      </StyledSpinner.SpinnerElement>
    </StyledSpinner.Container>
  )
}
