import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { theme } from 'app/theme/themeDefault'

import { Props as ButtonProps, Variant } from '../Button'
import {
  base,
  borders,
  colors,
  dimensions,
  fontProps,
} from '../Button/StyledButton'

export const StyledLink = styled(Link)<Props>`
  ${base}
  ${dimensions}
  ${borders}
  ${colors}
  ${fontProps}

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
  user-select: none;
  box-shadow: none;

  &:focus {
    outline: none;
    box-shadow: ${theme.boxShadow};
  }
`

export const StyledAnchor = styled.a<Props>`
  ${base}
  ${dimensions}
  ${borders}
  ${colors}
  ${fontProps}

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
  user-select: none;
  box-shadow: none;

  &:focus {
    outline: none;
    box-shadow: ${theme.boxShadow};
  }
`

interface Props extends Omit<ButtonProps, 'children'> {
  variant: Variant
}
