import { RefObject, useEffect, useRef } from 'react'

// usage:
// const elementToGiveFocus = useFocus<HTMLDivElement>()
// <div ref={elementToGiveFocus} tabIndex={0} />

export function useFocus<T extends HTMLElement>(): RefObject<T> {
  const element = useRef<T>(null)
  useEffect(() => {
    if (element && element.current) {
      element.current.focus()
    }
  }, [])

  return element
}
