import { css } from '@emotion/core'
import React from 'react'

import { FlexBox } from 'app/components/layout/FlexBox'
import { Icon } from 'app/components/ui/Icon'

import { Button } from 'app/components/ui/Button'
import { useToggleSmart } from 'app/hooks/customHooks'
import { generatePendoDataAttribute } from 'app/utils/pendo/generatePendoId'

import {
  ButtonChangeName,
  ButtonRemove,
  FileDetails,
  FileDownload,
  FileIcon,
  FileName,
  LinkContainer,
  NewNameInput,
} from './styled'

interface File {
  name: string
  size?: number
  mimeType?: string
  URL: string
  onNameChange?: (newName: string) => void
  onRemoveRequest?: () => void
}

export const FileItem: React.FunctionComponent<File> = ({
  name,
  mimeType,
  URL,
  onNameChange,
  onRemoveRequest,
}) => {
  const editableNameInputVisibility = useToggleSmart(false)
  const [fileName, setfileName] = React.useState(name)
  return (
    <FlexBox dial={7}>
      {!editableNameInputVisibility.isOn ? (
        <LinkContainer
          href={URL}
          target='_blank'
          rel='noopener'
          {...generatePendoDataAttribute('Anchor', 'Download attachment')}
        >
          <FileIcon>
            {mimeType && mimeType.includes('pdf') ? (
              <Icon name='file-pdf' />
            ) : (
              <Icon name='file' />
            )}
          </FileIcon>
          <FileDetails>
            <FileName>
              <span>{name}</span>
            </FileName>
            <FileDownload>
              <Icon name='cloud-download-alt' />
            </FileDownload>
          </FileDetails>
        </LinkContainer>
      ) : null}

      {editableNameInputVisibility.isOn && onNameChange ? (
        <>
          <strong>Type new file name</strong>
          <FlexBox
            row
            dial={1}
            stretch
            css={css`
              width: 100%;
            `}
          >
            <NewNameInput
              value={fileName}
              onChange={(e) => setfileName(e.currentTarget.value)}
              autoFocus
            />
            <Button
              type='button'
              size='small'
              disabled={fileName.trim().length <= 0}
              onClick={() => {
                editableNameInputVisibility.setOff()
                onNameChange(fileName)
              }}
            >
              Save
            </Button>
          </FlexBox>
          <FlexBox
            row
            dial={3}
            css={css`
              width: 100%;
            `}
          >
            <ButtonChangeName
              onClick={() => {
                editableNameInputVisibility.setOff()
                setfileName(name)
              }}
            >
              Cancel
            </ButtonChangeName>
          </FlexBox>
        </>
      ) : onNameChange ? (
        <ButtonChangeName
          onClick={() => editableNameInputVisibility.setOn()}
          {...generatePendoDataAttribute('Button', 'Edit uploaded file name')}
        >
          Change name
        </ButtonChangeName>
      ) : null}
      {onRemoveRequest ? (
        <ButtonRemove
          onClick={onRemoveRequest}
          {...generatePendoDataAttribute('Button', 'Delete uploaded file')}
        >
          Delete file
        </ButtonRemove>
      ) : null}
    </FlexBox>
  )
}
