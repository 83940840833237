// tslint:disable:no-submodule-imports
import throttle from 'lodash/throttle'
import { useEffect, useState } from 'react'

import { breakpoints } from 'app/theme/breakpoints'

const getWindowWidth = () => window.innerWidth

/** Takes 5 generic params and returns the corrisponding one based on viewport width. */
export const useResponsive = <T extends {}>(
  valXs: T,
  valSm: T,
  valMd: T,
  valLg: T,
  valXl: T
): T => {
  const [windowSize, setWindowSize] = useState(getWindowWidth())

  const handleResize = () => {
    const width = getWindowWidth()
    setWindowSize(width)
  }

  // avoid continous re-rendering by throttling the handler
  const throttledHandleRezise = throttle(handleResize, 1000)

  useEffect(() => {
    // window.addEventListener('resize', handleResize)
    window.addEventListener('resize', throttledHandleRezise)
    return () => {
      // window.removeEventListener('resize', handleResize)
      window.addEventListener('resize', throttledHandleRezise.cancel)
    }
  }, [])

  if (windowSize < parseInt(breakpoints.sm, 10)) {
    return valXs
  }
  if (windowSize < parseInt(breakpoints.md, 10)) {
    return valSm
  }
  if (windowSize < parseInt(breakpoints.lg, 10)) {
    return valMd
  }
  if (windowSize < parseInt(breakpoints.xl, 10)) {
    return valLg
  }

  return valXl
}
