import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { DisplayError } from 'app/components/DisplayError'
import { FlexBox } from 'app/components/layout/FlexBox'
import { LoadingSpinner } from 'app/components/LoadingSpinner'
import { ActorProvider } from 'app/containers/ActorProvider'
import { routes } from 'app/routes'

import { DealScreen } from 'app/screens/Deal/async'
import { LoginScreen } from 'app/screens/Login/async'
import { PageNotFoundScreen } from 'app/screens/PageNotFound/async'
import { PipelineScreen } from 'app/screens/Pipeline/async'
import { ProposalScreen } from 'app/screens/Proposal/async'
import { ProposalCreationScreen } from 'app/screens/ProposalCreation/async'
import { ProposalModificationScreen } from 'app/screens/ProposalModification/async'
import { DealSharedScreen } from './screens/DealShared'
import { ViewProposalScreen } from './screens/ViewProposal/async'

export const Router = () => {
  return (
    <ActorProvider fetchPolicy='cache-first'>
      {({ loading, error, actor }) => {
        if (loading || actor === undefined) {
          return <LoadingSpinner fullscreen />
        }

        if (error) {
          return (
            <FlexBox dial={5}>
              <DisplayError message='Cannot establish API connection. Please refresh the page' />
            </FlexBox>
          )
        }

        return (
          <Switch>
            {/* "/" */}
            <Route
              exact
              path={routes.root.path}
              render={(renderProps) =>
                actor.user ? (
                  actor.isAccountManager ||
                  actor.isBusinessAdministrator ||
                  actor.isBusinessManager ||
                  actor.isBusinessMember ||
                  actor.isHost ? (
                    <PipelineScreen
                      {...renderProps}
                      viewMode={
                        actor.isAccountManager
                          ? 'AM'
                          : actor.isHost
                          ? 'Host'
                          : 'BusinessUser'
                      }
                      userIdentity={actor.user.id}
                    />
                  ) : (
                    <PageNotFoundScreen />
                  )
                ) : (
                  <LoginScreen />
                )
              }
            />

            {/* "/deal/:dealId/newProposal" */}
            <Route
              exact
              path={routes.proposalCreation.path}
              render={(renderProps) =>
                actor.user ? (
                  actor.isAccountManager || actor.isHost ? (
                    <ProposalCreationScreen
                      {...renderProps}
                      spaceImporterModalIsOpenAsDefault={actor.isHost}
                    />
                  ) : (
                    <Redirect
                      to={routes.deal.makeUrl(renderProps.match.params.dealId)}
                    />
                  )
                ) : (
                  <LoginScreen />
                )
              }
            />

            {/* "/deal/:dealId" */}
            <Route
              path={routes.deal.path}
              render={(renderProps) =>
                actor.user ? (
                  actor.isAccountManager ||
                  actor.isBusinessAdministrator ||
                  actor.isBusinessManager ||
                  actor.isBusinessMember ||
                  actor.isHost ? (
                    <DealScreen {...renderProps} />
                  ) : (
                    <PageNotFoundScreen />
                  )
                ) : (
                  <LoginScreen />
                )
              }
            />

            {/* "/deal-shared/:token" */}
            <Route path={routes.dealShared.path} component={DealSharedScreen} />

            {/* "/proposal/:proposalId/edit" */}
            <Route
              exact
              path={routes.proposalModification.path}
              render={(renderProps) =>
                actor.user ? (
                  actor.isAccountManager || actor.isHost ? (
                    <ProposalModificationScreen {...renderProps} />
                  ) : (
                    <Redirect
                      to={routes.proposal.makeUrl(
                        renderProps.match.params.proposalId
                      )}
                    />
                  )
                ) : (
                  <LoginScreen />
                )
              }
            />

            {/* "/proposal/:proposalId" */}
            <Route
              path={routes.proposal.path}
              render={(renderProps) =>
                actor.user ? (
                  actor.isAccountManager ||
                  actor.isBusinessAdministrator ||
                  actor.isBusinessManager ||
                  actor.isBusinessMember ||
                  actor.isHost ? (
                    <ProposalScreen {...renderProps} />
                  ) : (
                    <PageNotFoundScreen />
                  )
                ) : (
                  <LoginScreen />
                )
              }
            />

            {/* /view-proposal/:proposalId */}
            <Route
              path={routes.viewProposal.path}
              render={(renderProps) =>
                actor.user ? (
                  actor.isAccountManager ||
                  actor.isBusinessAdministrator ||
                  actor.isBusinessManager ||
                  actor.isBusinessMember ||
                  actor.isHost ? (
                    <ViewProposalScreen {...renderProps} />
                  ) : (
                    <PageNotFoundScreen />
                  )
                ) : (
                  <LoginScreen />
                )
              }
            />
          </Switch>
        )
      }}
    </ActorProvider>
  )
}
