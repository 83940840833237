import React from 'react'
import { LinkProps } from 'react-router-dom'

import { Props as ButtonProps } from '../Button'
import { StyledAnchor, StyledLink } from './styled'

export interface Props extends ButtonProps, Pick<LinkProps, 'to' | 'target'> {
  isExternalUrl?: boolean
}

export const ButtonLink = ({
  children,
  to,
  target,
  size,
  variant,
  isExternalUrl,
  ...buttonProps
}: Props) => {
  return isExternalUrl ? (
    <StyledAnchor
      href={`${to}`}
      target={target}
      size={size || 'normal'}
      variant={variant || 'primary'}
      {...buttonProps}
    >
      {children}
    </StyledAnchor>
  ) : (
    <StyledLink
      to={to}
      target={target}
      size={size || 'normal'}
      variant={variant || 'primary'}
      {...buttonProps}
    >
      {children}
    </StyledLink>
  )
}
