import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { darken, lighten } from 'polished'
import React from 'react'

import { breakpoints } from 'app/theme/breakpoints'
import { SpacingValue, theme } from 'app/theme/themeDefault'

const getStyleType = ({ type }: MessageTypes) => {
  switch (type) {
    case 'success':
      return css`
        background-color: ${lighten(0.5, theme.colors.success)};
        color: ${darken(0.2, theme.colors.success)};
      `

    case 'warning':
      return css`
        background-color: ${lighten(0.3, theme.colors.warning)};
        color: ${darken(0.3, theme.colors.warning)};
      `
    case 'error':
      return css`
        background-color: ${lighten(0.4, theme.colors.danger)};
        color: ${darken(0.2, theme.colors.danger)};
      `
    default:
      return css`
        background-color: ${theme.colors.components.inputBackground};
        color: #000;
      `
  }
}

const StyledAlert = styled.div<AlertProps>`
  display: inline-block;
  padding: ${({ padding }) =>
    padding ? theme.spacingsLegacy[padding] : theme.spacingsLegacy.component};
  padding: ${({ padding }) =>
    padding ? theme.spacings[padding] : theme.spacings.component};

  min-width: 200px;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '500px')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  border-radius: ${theme.borderRadius};
  box-shadow: ${theme.boxShadow};
  text-align: center;

  @media screen and (max-width: ${breakpoints.md}) {
    padding: ${theme.spacingsLegacy.md};
    padding: ${theme.spacings.md};
    min-width: 100px;
    max-width: 100%;
  }

  ${getStyleType}
`

export interface MessageTypes {
  type?: 'success' | 'error' | 'warning' | 'plain'
}

interface AlertProps extends MessageTypes {
  fullWidth?: boolean
  padding?: SpacingValue
}

export const Alert: React.FC<AlertProps> = ({
  children,
  type,
  fullWidth,
  padding,
}) => {
  return (
    <StyledAlert
      type={type ? type : 'plain'}
      fullWidth={fullWidth}
      padding={padding}
    >
      {children}
    </StyledAlert>
  )
}
