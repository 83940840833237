import { SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'

import AvatarDefault from 'app/static/images/default-avatar.png'
import { theme } from 'app/theme/themeDefault'

const StyledAvatar = styled.img<StyledAvatarProps>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  object-fit: cover;
  border-radius: 50%;
  display: inline-block;
  box-shadow: ${({ noBorder }) => (noBorder ? 'none' : '0 0 0 1px #f1f1f1')};
  ${({ css }) => css}
`

const AvatarWrapper = styled.div`
  display: block;
  position: relative;

  .tooltip {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    transform: translate(0, -50%);
    background-color: ${theme.colors.components.stageLabel};
    padding: 2px 5px;
    font-size: 0.7rem;
    color: #fff;
    white-space: nowrap;
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
`

interface Props {
  imageUrl: string
  altText: string
  size?: string
  noBorder?: boolean
  css?: SerializedStyles
}
type StyledAvatarProps = Partial<Props>

export const Avatar = (props: Props) => {
  const { imageUrl, altText, size, noBorder, css } = props
  return (
    <AvatarWrapper>
      <StyledAvatar
        noBorder={!!noBorder}
        size={size || '60px'}
        src={imageUrl || AvatarDefault}
        alt={altText}
        css={css}
      />
      {altText && <span className='tooltip'>{altText}</span>}
    </AvatarWrapper>
  )
}
