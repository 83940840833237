import styled from '@emotion/styled'
import React from 'react'

import { theme } from 'app/theme/themeDefault'
import { Comment, CommentData } from './Comment'

interface Props {
  comments: ReadonlyArray<CommentData>
}

const Wrapper = styled.div`
  border: 1px solid ${theme.colors.components.inputBorders};
  background-color: ${theme.colors.components.inputBackground};
  border-radius: ${theme.borderRadius};
  > div {
    margin: 1rem;
  }
`

export const CommentList: React.FC<Props> = ({ comments }) => (
  <Wrapper>
    {comments.map((comment) => (
      <Comment key={comment.id} {...comment} />
    ))}
  </Wrapper>
)
