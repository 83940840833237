import { useEffect, useState } from 'react'

export const useModalState = (
  transitionDurationMs: number,
  onCancelCallBack?: () => void
) => {
  const [isTransitioning, toggleIsTransitioning] = useState<boolean>(false)

  useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0]
    toggleIsTransitioning((prevState) => !prevState)
    bodyElement.style.overflow = 'hidden'
    return () => {
      toggleIsTransitioning((prevState) => !prevState)
      bodyElement.style.overflow = ''
    }
  }, [])

  const handleClose = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault()
    e.stopPropagation()
    toggleIsTransitioning((prevState) => !prevState)

    window.setTimeout(() => {
      if (onCancelCallBack) {
        onCancelCallBack()
      }
    }, transitionDurationMs)
  }

  return {
    isTransitioning,
    handleClose,
    transitionDurationMs,
  }
}
