import React from 'react'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import {
  DealSummaryBar,
  Props as DealSummaryBarProps,
} from 'app/components/DealSummaryBar'
import { useElementVisibility } from 'app/hooks/useElementVisibility'
import { theme } from 'app/theme/themeDefault'

interface Props extends DealSummaryBarProps {
  elementRefToObserve: React.MutableRefObject<HTMLDivElement | null>
}

export const SummaryBar: React.FC<Props> = ({
  elementRefToObserve,
  ...dealSummaryBarProps
}) => {
  const vibility = useElementVisibility(elementRefToObserve, 70)

  return (
    <WrapperContainer isVisible={vibility.isPartiallyAboveViewport}>
      <DealSummaryBar {...dealSummaryBarProps} />
    </WrapperContainer>
  )
}

const WrapperContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  z-index: ${theme.zIndex.sticky - 1};
  transform: translate(0, -300px);

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    top: 0px;
  }

  ${({ isVisible }) =>
    isVisible
      ? css`
          animation: slideDown 0.5s linear 1 forwards;
          @keyframes slideDown {
            from {
              transform: translate(0, -300px);
            }
            to {
              transform: translate(0, 0);
            }
          }
        `
      : null}
`
