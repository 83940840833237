import styled from '@emotion/styled'
import React from 'react'

import { Icon } from 'app/components/ui/Icon'

interface Props {
  /**
   * fontawesome icon name
   */
  name: string
  position?: 'left' | 'right'
  size?: number | string
}

export const IconForButton: React.FC<Props> = ({ name, position, size }) => (
  <Container position={position || 'left'} size={size || 14}>
    <Icon name={name} />
  </Container>
)

const Container = styled.span<Partial<Props>>`
  display: inline-flex;
  align-items: center;

  font-size: ${({ size }) => (typeof size === 'number' ? `${size}px` : size)};
  margin-right: ${({ position }) => (position === 'left' ? '5px' : '0')};
  margin-left: ${({ position }) => (position === 'right' ? '5px' : '0')};

  i {
    margin: 0;
  }
`
