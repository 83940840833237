import React from 'react'

import { Card } from 'app/components/layout/Card'
import { Typography } from 'app/components/ui/Typography'

import { CardHeading, CardHeadingProps } from './CardHeading'
import { CardBody, StyledCardUser } from './styled'

interface Props
  extends Pick<CardHeadingProps, 'title' | 'media' | 'showMediaAsAvatar'> {
  //
}

export const CardUser: React.FC<Props> = ({ children, ...headingProps }) => {
  return (
    <Card>
      <StyledCardUser>
        <CardHeading {...headingProps} />
        <CardBody>
          <Typography>{children}</Typography>
        </CardBody>
      </StyledCardUser>
    </Card>
  )
}
