import React from 'react'

import { FlexBox } from 'app/components/layout/FlexBox'
import { Price } from 'app/containers/ApolloGeneratedComponents'
import { useResponsive } from 'app/hooks/useResponsive'
import { formatPrice } from 'app/utils/currency'

import { PriceAmount, PriceZone, Title, Vat } from './styled'

interface Props {
  name: string
  price: Price
}

export const NameAndPrice: React.FC<Props> = ({ name, price }) => {
  const isMobile = useResponsive(true, false, false, false, false)
  const getString = (currency: string, vatIncluded: boolean): string =>
    `${vatIncluded ? 'inc. ' : 'exc. '} ${currency === 'AUD' ? 'GST' : 'VAT'}`

  return (
    <FlexBox dial={1} row spaceBetween>
      <Title mobile={isMobile}>{name}</Title>
      <PriceZone>
        <FlexBox dial={9}>
          <PriceAmount>{formatPrice(price.amount, price.currency)}</PriceAmount>
          <Vat>{getString(price.currency, price.vatIncluded)}</Vat>
        </FlexBox>
      </PriceZone>
    </FlexBox>
  )
}
