import React from 'react'
import ReactDOM from 'react-dom'

import { useFocus } from 'app/hooks/useFocus'
import { ModalBody } from './ModalBody'
import { ModalFooter, ModalFooterProps } from './ModalFooter'
import { ModalHeader, ModalHeaderProps } from './ModalHeader'
import {
  Backdrop,
  ButtonClose,
  ModalContainer,
  ModalContainerInner,
  ModalContainerProps,
  StyledModal,
} from './styled'
import { useModalState } from './useModalState'

interface Props
  extends Partial<ModalContainerProps>,
    ModalHeaderProps,
    ModalFooterProps {
  hideCloseButton?: boolean
  preventCloseOnEscKey?: boolean
  verticalCentered?: boolean

  disableFocusOnModalComponent?: boolean
}

export const Modal: React.FC<Props> = ({
  allowOverflow,
  children,
  hideCloseButton,
  title,
  size = 'normal',
  verticalCentered = false,
  onCloseRequest,
  preventCloseOnEscKey,
  disableFocusOnModalComponent,
  modalPaddingInRem,
  fullwidthButtons,
  ...footerProps
}) => {
  const { handleClose, isTransitioning, transitionDurationMs } = useModalState(
    300,
    onCloseRequest
  )
  const elementToFocus = useFocus<HTMLDivElement>()

  return ReactDOM.createPortal(
    <StyledModal
      isTransitioning={isTransitioning}
      transitionDurationMs={transitionDurationMs}
      ref={!disableFocusOnModalComponent ? elementToFocus : undefined}
      role='dialog'
      tabIndex={0}
      onKeyDown={(e) => {
        if (!preventCloseOnEscKey && e.keyCode === 27) {
          handleClose(e)
        }
      }}
    >
      <Backdrop onClick={handleClose} />
      <ModalContainer
        size={size}
        verticalCentered={verticalCentered}
        allowOverflow={!!allowOverflow}
        modalPaddingInRem={
          modalPaddingInRem !== undefined ? modalPaddingInRem : 2
        }
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {!hideCloseButton && (
          <ButtonClose buttonSize='2rem' onClick={handleClose} />
        )}
        <ModalContainerInner allowOverflow={!!allowOverflow}>
          <ModalHeader title={title} />
          <ModalBody allowOverflow={!!allowOverflow} children={children} />
          <ModalFooter
            onCloseRequest={onCloseRequest}
            fullwidthButtons={
              // by default modals with size normal or small have full width buttons
              fullwidthButtons === undefined
                ? size !== 'large'
                : fullwidthButtons
            }
            {...footerProps}
          />
        </ModalContainerInner>
      </ModalContainer>
    </StyledModal>,
    document.body
  )
}
