// tslint:disable: readonly-array
import { useCallback, useState } from 'react'

/** Reusable togglable hook with useCallback */
export const useToggle = (initial: boolean): [boolean, () => void] => {
  const [isOpen, setIsOpen] = useState(initial)
  const cb = useCallback(() => setIsOpen((status) => !status), [status])
  return [isOpen, cb]
}

/** Smart toggable with setter (setOn e setOff) and toggle */
export interface SmartToggleObject {
  isOn: boolean
  toggle: () => void
  setOff: () => void
  setOn: () => void
}
export const useToggleSmart = (initialState: boolean): SmartToggleObject => {
  const [isOn, setState] = useState(initialState)
  return {
    isOn,
    toggle: () => setState(!isOn),
    setOff: () => setState(false),
    setOn: () => setState(true),
  }
}
