import { css } from '@emotion/core'
import { Props } from './index'

export const flexDirection = ({
  row,
  reverse,
}: Pick<Props, 'row' | 'reverse'>) => {
  return row
    ? css`
        flex-direction: ${reverse ? 'row-reverse' : 'row'};
      `
    : css`
        flex-direction: ${reverse ? 'column-reverse' : 'column'};
      `
}
