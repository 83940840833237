import styled from '@emotion/styled'
import React from 'react'

import { theme } from 'app/theme/themeDefault'

const ErrorMessage = styled.div`
  padding: 0.5em 1em;
  color: ${theme.colors.danger};
  font-size: 1.5rem;
`

interface Props {
  message: string
}

export const DisplayError: React.FC<Props> = ({ message }) => {
  return <ErrorMessage>{message}</ErrorMessage>
}
