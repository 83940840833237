import styled from '@emotion/styled'
import React, { useContext } from 'react'

import { Alert } from 'app/components/ui/Alert'
import { NotificationsContext } from 'app/containers/NotificationsProvider'
import { theme } from 'app/theme/themeDefault'

const StyledNotificationArea = styled.div`
  position: fixed;
  bottom: 0;
  left: 5%;
  z-index: ${theme.zIndex.notifications};
  display: flex;
  flex-direction: column;

  > div {
    animation: slidein 0.5s ease-in forwards;
  }
  > div + div {
    margin-top: 1rem;
  }

  @keyframes slidein {
    0% {
      opacity: 0;
      transform: translate(0, 50%);
    }
    40% {
      transform: translate(0, 0);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
`

export const NotificationArea: React.FC = () => {
  const context = useContext(NotificationsContext)

  return (
    <StyledNotificationArea>
      {context && context.notifications ? (
        <>
          {context.notifications.map((item, idx) => (
            <Alert key={`notification_${idx}`} type={item.type}>
              {item.message}
            </Alert>
          ))}
        </>
      ) : null}
    </StyledNotificationArea>
  )
}
