import React from 'react'

import { Dropdown } from 'app/components/ui/Dropdown'
import { DropdownMenuItem } from 'app/components/ui/DropdownMenuItem'
import { Icon } from 'app/components/ui/Icon'
import { ScreensDealCloneProposalsComponent } from 'app/containers/ApolloGeneratedComponents'
import { useToggleSmart } from 'app/hooks/customHooks'
import { routes } from 'app/routes'
import { generatePendoDataAttribute } from 'app/utils/pendo/generatePendoId'
import { Redirect } from 'react-router-dom'

export interface Props {
  onProposalDeleteRequest?: Callback
  onProposalRejectRequest?: Callback
  onProposalAcceptRequest?: Callback
  onProposalShortlistRequest?: Callback
  onProposalCloneRequest?: Callback
  dataForSingleDirectCloning?: {
    dealId: string
    proposalsId: string
  }
}

type Callback = null | (() => void)

export const MoreActionsDropdown: React.FC<Props> = ({
  onProposalDeleteRequest,
  onProposalRejectRequest,
  onProposalAcceptRequest,
  onProposalShortlistRequest,
  dataForSingleDirectCloning,
}) => {
  const dropdownState = useToggleSmart(false)

  const handleClickBase = (e: React.MouseEvent<Element, MouseEvent>) => {
    // there's an onlick event bound on the entire card, we need this here
    e.stopPropagation()
    e.preventDefault()
    dropdownState.toggle()
  }

  const renderOptionItem = (callback: Callback, label: string) =>
    callback ? (
      <div
        onClick={(e) => {
          handleClickBase(e)
          callback()
        }}
        {...generatePendoDataAttribute('Card Proposal Menu', label)}
      >
        {label}
      </div>
    ) : null

  if (
    !onProposalDeleteRequest &&
    !onProposalRejectRequest &&
    !onProposalShortlistRequest
  ) {
    return null
  }

  return (
    <Dropdown
      position='top-right'
      onClick={handleClickBase}
      isOpen={dropdownState.isOn}
      toggle={<Icon name='ellipsis-v' />}
      menuWidth='110px'
      {...generatePendoDataAttribute('Button', 'Proposal preview more option')}
    >
      {/* DELETE */}
      {onProposalDeleteRequest &&
        renderOptionItem(onProposalDeleteRequest, 'Delete')}

      {/* REJECT  */}
      {onProposalRejectRequest &&
        renderOptionItem(onProposalRejectRequest, 'Reject')}

      {/* ACCEPT */}
      {onProposalAcceptRequest &&
        renderOptionItem(onProposalAcceptRequest, 'Accept')}

      {/* RESET TO PENDING */}
      {onProposalShortlistRequest &&
        renderOptionItem(onProposalShortlistRequest, 'Shortlist')}

      {/* CLONE PROPOSAL */}
      {dataForSingleDirectCloning ? (
        <ScreensDealCloneProposalsComponent
          children={(cloneProposals, { data, loading: isCloning }) => {
            if (data && data.clonedProposals) {
              return (
                <Redirect
                  to={routes.proposalModification.makeUrl(
                    data.clonedProposals[0].id
                  )}
                />
              )
            }
            return (
              <DropdownMenuItem
                disabled={isCloning}
                onItemClick={() => {
                  cloneProposals({
                    variables: {
                      targetDealId: dataForSingleDirectCloning.dealId,
                      proposalsIds: [dataForSingleDirectCloning.proposalsId],
                    },
                  })
                }}
                {...generatePendoDataAttribute(
                  'Card Proposal Menu',
                  'Clone proposal'
                )}
              >
                Clone
              </DropdownMenuItem>
            )
          }}
        />
      ) : null}
    </Dropdown>
  )
}
