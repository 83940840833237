import { css, SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'
import { rgba } from 'polished'

import { SpacingValue, theme } from 'app/theme/themeDefault'

export interface StyledCardProps {
  stacked?: boolean
  verticalPadding?: SpacingValue
  horizontalPadding?: SpacingValue
  showOverflow?: boolean
  css?: SerializedStyles
  hasHoverState?: boolean
}

const getPadding = ({
  verticalPadding,
  horizontalPadding,
}: StyledCardProps) => {
  const vp = verticalPadding || 'component'
  const hp = horizontalPadding || 'component'

  return css`
    /* legacy fallback for old IE */
    padding-top: ${theme.spacingsLegacy[vp]};
    padding-bottom: ${theme.spacingsLegacy[vp]};
    padding-left: ${theme.spacingsLegacy[hp]};
    padding-right: ${theme.spacingsLegacy[hp]};

    /* using default css custom properties */
    padding-top: ${theme.spacings[vp]};
    padding-bottom: ${theme.spacings[vp]};
    padding-left: ${theme.spacings[hp]};
    padding-right: ${theme.spacings[hp]};
  `
}

const getBorders = ({ stacked }: StyledCardProps) =>
  stacked
    ? css`
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: ${theme.borderRadius};
        border-bottom-right-radius: ${theme.borderRadius};
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      `
    : css`
        border-radius: ${theme.borderRadius};
      `

const getHoverState = ({ hasHoverState }: StyledCardProps) =>
  hasHoverState
    ? css`
        cursor: pointer;
        transition: box-shadow 0.2s ease-in-out;
        &:hover {
          box-shadow: 0 0 10px 0 ${rgba(theme.colors.grayLighter, 0.9)};
          z-index: 1;
        }
      `
    : null

const getStackedPosition = ({ stacked }: StyledCardProps) =>
  stacked
    ? css`
        margin-top: -3px;
      `
    : null

export const StyledCard = styled.div<StyledCardProps>`
  position: relative;
  width: 100%;
  background-color: #fff;
  box-shadow: ${theme.boxShadow};

  ${getPadding}
  ${getBorders}
  ${getHoverState}
  ${getStackedPosition}

  overflow: ${({ showOverflow }) => (showOverflow ? 'visible' : 'hidden')};
  ${({ css: cssProp }) => cssProp}
`
