import { Global } from '@emotion/core'
import React from 'react'
import { ApolloProvider } from 'react-apollo'
import Helmet from 'react-helmet'
import { hot } from 'react-hot-loader/root'
import { BrowserRouter } from 'react-router-dom'

import { RoutingMainRenderZone } from 'app/components/layout/RoutingMainRenderZone'
import { ActorProvider } from 'app/containers/ActorProvider'
import { NotificationsProvider } from 'app/containers/NotificationsProvider'
import favicon from 'app/static/images/favicon.ico'
import { fontFaceMrEavesXlModern } from 'app/theme/fontFacesCSS'
import { globalCSS } from 'app/theme/globalCSS'
import { headingsCSS } from 'app/theme/headingsCSS'
import { spacingsCSS } from 'app/theme/spacingsCSS'
import { PendoActorTracker } from 'app/utils/pendo/ActorTracker'
import { apolloClient } from './ApolloClient'
import { Router } from './Router'

const GlobalInlcudes = () => (
  <>
    <Global styles={spacingsCSS} />
    <Global styles={fontFaceMrEavesXlModern} />
    <Global styles={globalCSS} />
    <Global styles={headingsCSS} />
    <Helmet>
      <link rel='shortcut icon' type='image/x-icon' href={favicon} />
    </Helmet>
  </>
)

const App = () => (
  <ApolloProvider client={apolloClient}>
    <NotificationsProvider waitToKill={4000}>
      <GlobalInlcudes />
      <BrowserRouter>
        <RoutingMainRenderZone>
          <ActorProvider fetchPolicy='cache-first'>
            {({ actor }) =>
              actor ? <PendoActorTracker actor={actor} /> : null
            }
          </ActorProvider>
          <Router />
        </RoutingMainRenderZone>
      </BrowserRouter>
    </NotificationsProvider>
  </ApolloProvider>
)

export default hot(App)
