import axios, { AxiosRequestConfig } from 'axios'
import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

import { FlexBox } from 'app/components/layout/FlexBox'
import { PageContainer } from 'app/components/layout/PageContainer'
import { Button } from 'app/components/ui/Button'
import { Dropdown } from 'app/components/ui/Dropdown'
import { ActorProvider } from 'app/containers/ActorProvider'
import { useToggle } from 'app/hooks/customHooks'
import AvatarPlaceholder from 'app/static/images/default-avatar.png'
import Logo from 'app/static/images/HeadBox-Logo-web.png'

import { routes } from 'app/routes'
import { generatePendoDataAttribute } from 'app/utils/pendo/generatePendoId'
import { AvatarButtonToggler } from './AvatarButtonToggler'
import {
  FeedbackLinkContent,
  HeaderMenu,
  MainLogo,
  StyledHeader,
} from './styled'

interface Props {
  onLoginRequest?: () => void
}
export const MainHeader: React.FC<Props> = ({ onLoginRequest }) => {
  const [isOpen, toggleOpen] = useToggle(false)

  return (
    <StyledHeader>
      <PageContainer>
        <FlexBox dial={5} row spaceBetween>
          <ActorProvider
            fetchPolicy='cache-only'
            children={({ actor, client }) => {
              return (
                <Fragment>
                  <a
                    href='https://www.headbox.com/'
                    target='_blank'
                    {...generatePendoDataAttribute('Link', 'Masthead Logo')}
                  >
                    <MainLogo src={Logo} />
                  </a>

                  <HeaderMenu>
                    {actor && actor.user ? (
                      <Fragment>
                        {/* NEW DEAL LINK */}
                        {actor.user && actor.user.company ? (
                          <a
                            href='https://app.headbox.com/plan-my-event/business'
                            target='_blank'
                            rel='noopener'
                            {...generatePendoDataAttribute(
                              'Link',
                              'Masthead Create New Brief'
                            )}
                          >
                            Plan your event
                          </a>
                        ) : null}

                        {/* BACK TO PIPELINE */}
                        <NavLink
                          exact
                          to={routes.root.makeUrl()}
                          activeStyle={{ opacity: 0.6 }}
                        >
                          Event Hub
                        </NavLink>

                        {/* SEND FEEDBACK ONLY FOR HOST */}
                        {actor.user &&
                        actor.isHost &&
                        !actor.isAccountManager ? (
                          <a
                            href={'mailto:product@headbox.com'}
                            target='_blank'
                            rel='noopener'
                            title='product@headbox.com'
                          >
                            <FeedbackLinkContent>
                              Send Us Feedback
                              <span>product@headbox.com</span>
                            </FeedbackLinkContent>
                          </a>
                        ) : null}

                        {/* USER DROPDOWN */}
                        <Dropdown
                          position='bottom-right'
                          onClick={toggleOpen}
                          isOpen={isOpen}
                          inline
                          toggle={
                            <AvatarButtonToggler
                              userName={actor.user.fullName}
                              imageUrl={
                                actor.user.avatarURL || AvatarPlaceholder
                              }
                              {...generatePendoDataAttribute('asd', 'bbbb')}
                            />
                          }
                        >
                          <a
                            href='#'
                            onClick={(e) => {
                              e.preventDefault()
                              const axiosOptions: AxiosRequestConfig = {
                                method: 'post',
                                url:
                                  window.env.APP_BACKEND_URL +
                                  '/authentication/logout',
                                withCredentials: true,
                              }
                              axios(axiosOptions).then((response) => {
                                if (!response.data) {
                                  throw new Error()
                                }
                                client!.cache.reset()
                                client!.resetStore()
                              })
                            }}
                          >
                            Sign out
                          </a>
                        </Dropdown>
                      </Fragment>
                    ) : (
                      <>
                        {onLoginRequest && (
                          <Button
                            variant='primary-ghost'
                            onClick={onLoginRequest}
                          >
                            Sign in
                          </Button>
                        )}
                      </>
                    )}
                  </HeaderMenu>
                </Fragment>
              )
            }}
          />
        </FlexBox>
      </PageContainer>
    </StyledHeader>
  )
}
