// https://www.notion.so/headbox/Pendo-Product-Analytics-87399ea2249a436aba9daa93b5397b16
const formatPidSection = (str: string, char: string): string =>
  str.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, char)

export const generatePendoId = (elementType: string, id: string): string =>
  `${formatPidSection(elementType, '-')}--${formatPidSection(
    id,
    '_'
  )}`.toLowerCase()

export const generatePendoDataAttribute = (
  elementType: string,
  id: string
) => ({
  ['data-pid']: generatePendoId(elementType, id),
})
