import styled from '@emotion/styled'
import React from 'react'

import { SpacingValue, theme } from 'app/theme/themeDefault'

interface Props {
  size?: SpacingValue
}

interface StyledSpacerProps {
  gap: SpacingValue
}

const StyledSpacer = styled.section<StyledSpacerProps>`
  margin-bottom: ${({ gap }) => theme.spacingsLegacy[gap]};
  margin-bottom: ${({ gap }) => theme.spacings[gap]};
`

export const Spacer: React.FC<Props> = ({ children, size }) => (
  <StyledSpacer gap={size || 'normal'}>{children}</StyledSpacer>
)
