import React, { useState } from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import { Link } from 'react-router-dom'

import { Price } from 'app/containers/ApolloGeneratedComponents'
import { routes } from 'app/routes'
import { formatPrice } from 'app/utils/currency'
import { generatePendoDataAttribute } from 'app/utils/pendo/generatePendoId'

import { getBoundsZoomLevel } from './getBoundsZoomLevel'
import { Item, MapStyles, Title, Wrapper } from './styled'

export interface ProposalDataForMap {
  id: string
  location: string
  spaceName: string
  totalCost: Price
  coordinates: {
    latitude: number
    longitude: number
  }
}

interface Props {
  proposalArrayForMap: readonly ProposalDataForMap[]
}

const Map: React.FunctionComponent<Props> = ({ proposalArrayForMap }) => {
  if (typeof google === 'undefined' || !google.maps) {
    return null
  }

  const compatibleLocations = proposalArrayForMap
    .filter((proposal) => proposal.coordinates && proposal.coordinates.latitude)
    .map(
      (proposal) =>
        new google.maps.LatLng(
          proposal.coordinates.latitude,
          proposal.coordinates.longitude
        )
    )

  // calculate correct zoom level based on coordinates
  const latlngbounds: google.maps.LatLngBounds = new google.maps.LatLngBounds()

  compatibleLocations.forEach((proposal) => {
    latlngbounds.extend(proposal)
  })

  const mapLocation = ({
    coordinates: { latitude, longitude },
    id,
    location,
    spaceName,
    totalCost,
  }: ProposalDataForMap) => {
    const [expandWindow, setWindow] = useState(true)
    if (!latitude || !longitude) {
      return null
    }
    return (
      <Marker
        key={id}
        position={{
          lat: latitude,
          lng: longitude,
        }}
        onClick={() => setWindow(true)}
      >
        {expandWindow ? (
          <InfoWindow onCloseClick={() => setWindow(false)}>
            <Wrapper>
              <Title>{spaceName}</Title>
              <Item>{location}</Item>
              <Item> {formatPrice(totalCost.amount, totalCost.currency)}</Item>
              <Link
                to={routes.proposal.makeUrl(id)}
                {...generatePendoDataAttribute(
                  'Anchor',
                  'Open proposal from map'
                )}
              >
                More info
              </Link>
            </Wrapper>
          </InfoWindow>
        ) : null}
      </Marker>
    )
  }

  return (
    <GoogleMap
      defaultZoom={
        getBoundsZoomLevel(latlngbounds, {
          height: 700,
          width: 624,
        }) - 1
      }
      defaultCenter={{
        lat: latlngbounds.getCenter().lat(),
        lng: latlngbounds.getCenter().lng(),
      }}
      options={{
        styles: MapStyles,
      }}
    >
      {proposalArrayForMap.map((proposal) => (
        <div key={proposal.id}>{mapLocation(proposal)}</div>
      ))}
    </GoogleMap>
  )
}

export const ProposalsMap = withScriptjs(withGoogleMap(Map))
