import { css, SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'

import { Breakpoint, breakpoints } from 'app/theme/breakpoints'
import { SpacingValue, theme } from 'app/theme/themeDefault'
export interface GridProp {
  gap: SpacingValue
  rowGap: SpacingValue
  breakAt: Breakpoint
  columnsTemplateBelowBreakpoint: string
  columnsTemplate: string
  rowsTemplate: string
  alignItems:
    | 'initial'
    | 'auto'
    | 'normal'
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'baseline'
  legacyCss?: SerializedStyles
}

const defaultCssForIE = ({ columnsTemplate, gap }: GridProp) => {
  const widthForIE = columnsTemplate === '1fr 1fr' ? '50%' : '100%'
  const gapForIE = theme.spacingsLegacy[gap]

  return css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
      width: calc(${widthForIE} - ${gapForIE});
    }
  `
}

export const StyledGrid = styled.div<GridProp>`
  display: grid;
  grid-template-columns: ${({ columnsTemplate }) => columnsTemplate};
  grid-template-rows: ${({ rowsTemplate }) => rowsTemplate};
  grid-gap: ${({ gap }) => theme.spacings[gap]};
  row-gap: ${({ rowGap }) => theme.spacings[rowGap]};
  align-items: ${({ alignItems }) => alignItems};

  @media screen and (max-width: ${({ breakAt }) => breakpoints[breakAt]}) {
    grid-template-columns: ${({ columnsTemplateBelowBreakpoint }) =>
      columnsTemplateBelowBreakpoint};
  }

  /* IE 10 FIX */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    ${({ legacyCss }) => (legacyCss ? legacyCss : defaultCssForIE)}
  }
`
