import styled from '@emotion/styled'
import React from 'react'

import { Spacer } from 'app/components/layout/Spacer'
import { googleMapApiKey } from 'app/constants/googleMapApiKey'

const StyledEmbed = styled.iframe`
  height: 350px;
  width: 100%;
  border: none;
`

interface Props {
  address: string
}

export const Map: React.FC<Props> = ({ address }) => (
  <>
    <Spacer size='lg'>
      <h4>Location</h4>
    </Spacer>
    <StyledEmbed src={prepareAddressForGmaps(address)} />
  </>
)

const prepareAddressForGmaps = (address: string): string => {
  const baseUrl = `https://www.google.com/maps/embed/v1/place?key=${googleMapApiKey}&zoom=14&q=`
  // ampersands in address name will break the query string
  const encodedUrl = encodeURI(address.replace(/&/g, ''))
  return baseUrl + encodedUrl
}
