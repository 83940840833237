import styled from '@emotion/styled'
import React from 'react'

import { theme } from 'app/theme/themeDefault'

interface Props {
  stage: string
}

const StyledLabel = styled.div`
  background-color: ${theme.colors.components.stageLabel};
  color: #fff;
  display: inline-flex;
  position: relative;
  text-align: left;
  height: 3rem;
  padding: 0 1rem;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  letter-spacing: 0.04em;
  width: 100%;
  /* IE 10 FIX */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    > span {
      display: block;
      max-width: 100%;
    }
  }
`
// keep the inner span for IE 10/11, in order to wrap text when too long
// IE doesn't wrap text for flex container, but only for children
export const Stage: React.FunctionComponent<Props> = ({ stage }: Props) => {
  return (
    <StyledLabel>
      <span>{stage === 'Qualified Brief' ? 'Qualified Enquiry' : stage}</span>
    </StyledLabel>
  )
}
