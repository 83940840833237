import { SerializedStyles } from '@emotion/core'
import React from 'react'

import { StyledButton } from './StyledButton'

export const Button = ({
  children,
  disabled,
  fullwidth,
  size,
  variant,
  onClick,
  css,
  ...htmlButtonProp
}: Props) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled || false}
    fullwidth={fullwidth || false}
    size={size || 'normal'}
    variant={variant || 'primary'}
    css={css}
    {...htmlButtonProp}
  >
    {children}
  </StyledButton>
)

export interface Props extends React.ButtonHTMLAttributes<any> {
  children: React.ReactNodeArray | React.ReactNode
  fullwidth?: boolean
  size?: Size
  variant?: Variant
  css?: SerializedStyles
}

export type Variant =
  | 'primary'
  | 'primary-ghost'
  | 'secondary'
  | 'white'
  | 'danger'
  | 'editor'

type Size = 'small' | 'normal'
