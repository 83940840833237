// generated with:` npm run codegen:client`
// tslint:disable

import gql from 'graphql-tag'
import * as React from 'react'
import * as ReactApollo from 'react-apollo'
export type Maybe<T> = T | null
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  UUID: string
  DateTime: Date
  Date: string
}

export type AccountManager = {
  __typename?: 'AccountManager'
  id?: Maybe<Scalars['Int']>
  fullName: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  avatarURL: Scalars['String']
  phoneNumber?: Maybe<Scalars['String']>
}

export type Actor = {
  __typename?: 'Actor'
  user?: Maybe<HeadboxUser>
  isAccountManager: Scalars['Boolean']
  isBusinessAdministrator: Scalars['Boolean']
  isBusinessManager: Scalars['Boolean']
  isBusinessMember: Scalars['Boolean']
  isHost: Scalars['Boolean']
}

export type Coordinates = {
  __typename?: 'Coordinates'
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type CoordinatesForFileUpload = {
  __typename?: 'CoordinatesForFileUpload'
  signedURL: Scalars['String']
}

export enum Currency {
  EUR = 'EUR',
  GBP = 'GBP',
  AUD = 'AUD',
}

export type Deal = {
  __typename?: 'Deal'
  id: Scalars['UUID']
  publicAccessToken: Scalars['String']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  stage: Scalars['String']
  decisionDeadline?: Maybe<Scalars['Date']>
  event: DealEvent
  amount?: Maybe<Price>
  primaryContact: DealPrimaryContact
  bookedSpace?: Maybe<Scalars['String']>
  hubspotDealURL?: Maybe<Scalars['String']>
  headboxCompany?: Maybe<HeadboxCompany>
  headboxCompanyTeam?: Maybe<HeadboxCompanyTeam>
  headboxServiceFee?: Maybe<Scalars['Float']>
  proposals: Array<Proposal>
  ownerAccountManager?: Maybe<AccountManager>
  juniorAccountManager?: Maybe<AccountManager>
  actorCanAssignToACompamyTeam: Scalars['Boolean']
  actorCanShareByEmail: Scalars['Boolean']
  actorCanCreateProposal: Scalars['Boolean']
  actorCanDesignateAPrimaryContact: Scalars['Boolean']
  hostInvitations: Array<HostInvitationToDeal>
  numberOfAttendees?: Maybe<Scalars['Int']>
}

export type DealProposalsArgs = {
  accessToken?: Maybe<Scalars['String']>
}

export type DealEvent = {
  __typename?: 'DealEvent'
  name: Scalars['String']
  brief: Scalars['String']
  type: Scalars['String']
  date?: Maybe<Scalars['Date']>
  budget?: Maybe<Price>
  location?: Maybe<Scalars['String']>
  seatingStyle?: Maybe<Scalars['String']>
  length?: Maybe<Scalars['String']>
  time?: Maybe<Scalars['String']>
  flexibility?: Maybe<Scalars['String']>
  facilities: Array<Scalars['String']>
  catering?: Maybe<Scalars['String']>
  additionalRequirements?: Maybe<Scalars['String']>
  productInterest?: Maybe<Scalars['String']>
}

export type DealPrimaryContact = HeadboxUser | DealPrimaryContactProps

export type DealPrimaryContactProps = {
  __typename?: 'DealPrimaryContactProps'
  fullName: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  phone: Scalars['String']
}

export type DealsFilteringInput = {
  archived: Scalars['Boolean']
  beforeDate?: Maybe<Scalars['Date']>
}

export type DealsList = {
  __typename?: 'DealsList'
  deals: Array<Deal>
  page: Page
  totalDeals: Scalars['Int']
}

export enum DealsListFilterAxisSplitDirection {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
}

export type DealsListFilterDateAxisHalf = {
  direction: DealsListFilterAxisSplitDirection
  splitPoint: Scalars['Date']
}

export type DealsListFilteringInput = {
  company: Array<Scalars['Int']>
  companyTeam: Array<Scalars['Int']>
  dealStage: Array<Scalars['String']>
  dealAmountIn?: Maybe<DealsListFilterNumberAxisHalf>
  dealDecisionDeadlineIn?: Maybe<DealsListFilterDateAxisHalf>
  dealPrimaryContact: Array<Scalars['Int']>
  eventDateIn?: Maybe<DealsListFilterDateAxisHalf>
  eventName?: Maybe<DealsListFilterStringFilter>
  eventType: Array<Scalars['String']>
  juniorAccountManager: Array<Scalars['Int']>
  ownerAccountManager: Array<Scalars['Int']>
  proposalsNumber?: Maybe<DealsListFilterNumberAxisHalf>
}

export type DealsListFilterNumberAxisHalf = {
  direction: DealsListFilterAxisSplitDirection
  splitPoint: Scalars['Float']
}

export type DealsListFilterStringFilter = {
  operator: DealsListFilterStringFilterOperator
  term: Scalars['String']
}

export enum DealsListFilterStringFilterOperator {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
}

export enum DealsListSortableValues {
  NAME = 'NAME',
  STAGE = 'STAGE',
  DECISION_DEADLINE = 'DECISION_DEADLINE',
  EVENT_DATE = 'EVENT_DATE',
  EVENT_BUDGET = 'EVENT_BUDGET',
  EVENT_NAME = 'EVENT_NAME',
  AMOUNT = 'AMOUNT',
}

export type DealsListSortingInput = {
  byFieldValues: Array<DealsListSortableValues>
  sortDirection: SortDirection
}

export type DraftJsBlock = {
  __typename?: 'DraftJsBlock'
  key: Scalars['String']
  text: Scalars['String']
  type: DraftJsBlockType
  depth: Scalars['Int']
  inlineStyleRanges: Array<DraftJsInlineStyleRange>
  entityRanges: Array<DraftJsEntityRange>
  data: DraftJsBlockData
}

export type DraftJsBlockData = {
  __typename?: 'DraftJsBlockData'
  id?: Maybe<Scalars['String']>
}

export type DraftJsBlockDataInput = {
  id?: Maybe<Scalars['String']>
}

export type DraftJsBlockInput = {
  key: Scalars['String']
  text: Scalars['String']
  type: DraftJsBlockType
  depth: Scalars['Int']
  inlineStyleRanges: Array<DraftJsInlineStyleRangeInput>
  entityRanges: Array<DraftJsEntityRangeInput>
  data: DraftJsBlockDataInput
}

export enum DraftJsBlockType {
  UNSTYLED = 'unstyled',
  BLOCKQUOTE = 'blockquote',
  CODE_BLOCK = 'code_block',
  UNORDERED_LIST_ITEM = 'unordered_list_item',
  ORDERED_LIST_ITEM = 'ordered_list_item',
  HEADER_ONE = 'header_one',
  HEADER_TWO = 'header_two',
  HEADER_THREE = 'header_three',
  HEADER_FOUR = 'header_four',
  HEADER_FIVE = 'header_five',
  HEADER_SIX = 'header_six',
}

export type DraftJsEntity = {
  __typename?: 'DraftJsEntity'
  type: DraftJsEntityType
  mutability: DraftJsEntityMutability
  data: DraftJsEntityData
}

export type DraftJsEntityData = DraftJsEntityLinkData

export type DraftJsEntityDataInput = {
  type: DraftJsEntityType
  /** DraftJsEntityLinkData */
  url?: Maybe<Scalars['String']>
}

export type DraftJsEntityInput = {
  type: DraftJsEntityType
  mutability: DraftJsEntityMutability
  data: DraftJsEntityDataInput
}

export type DraftJsEntityLinkData = {
  __typename?: 'DraftJsEntityLinkData'
  type: DraftJsEntityType
  url: Scalars['String']
}

export enum DraftJsEntityMutability {
  MUTABLE = 'MUTABLE',
  IMMUTABLE = 'IMMUTABLE',
  SEGMENTED = 'SEGMENTED',
}

export type DraftJsEntityRange = {
  __typename?: 'DraftJsEntityRange'
  offset: Scalars['Int']
  length: Scalars['Int']
  key: Scalars['Int']
}

export type DraftJsEntityRangeInput = {
  offset: Scalars['Int']
  length: Scalars['Int']
  key: Scalars['Int']
}

export enum DraftJsEntityType {
  LINK = 'LINK',
}

export enum DraftJsInlineStyle {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
  STRIKETHROUGH = 'STRIKETHROUGH',
  CODE = 'CODE',
}

export type DraftJsInlineStyleRange = {
  __typename?: 'DraftJsInlineStyleRange'
  offset: Scalars['Int']
  length: Scalars['Int']
  style: DraftJsInlineStyle
}

export type DraftJsInlineStyleRangeInput = {
  offset: Scalars['Int']
  length: Scalars['Int']
  style: DraftJsInlineStyle
}

export type DraftJsRawContentArrayForm = {
  __typename?: 'DraftJsRawContentArrayForm'
  blocks: Array<DraftJsBlock>
  entityArray: Array<DraftJsEntity>
}

export type DraftJsRawContentArrayFormInput = {
  blocks: Array<DraftJsBlockInput>
  entityArray: Array<DraftJsEntityInput>
}

export type File = {
  __typename?: 'File'
  id: Scalars['UUID']
  name: Scalars['String']
  mimeType: Scalars['String']
  size: Scalars['Int']
  URL: Scalars['String']
  createdAt: Scalars['DateTime']
}

export type HeadboxCompany = {
  __typename?: 'HeadboxCompany'
  id: Scalars['Int']
  name: Scalars['String']
  logoURL?: Maybe<Scalars['String']>
  hubspotNewDealFormURL?: Maybe<Scalars['String']>
  administrators: Array<HeadboxUser>
  members: Array<HeadboxUser>
  teams: Array<HeadboxCompanyTeam>
}

export type HeadboxCompanyTeam = {
  __typename?: 'HeadboxCompanyTeam'
  id: Scalars['Int']
  name: Scalars['String']
  managers: Array<HeadboxUser>
  members: Array<HeadboxUser>
}

export type HeadboxLayout = {
  __typename?: 'HeadboxLayout'
  layout: Scalars['String']
  capacity: Scalars['Int']
}

export type HeadboxSpace = {
  __typename?: 'HeadboxSpace'
  id: Scalars['Int']
  name: Scalars['String']
  venueName: Scalars['String']
  description: Scalars['String']
  host: HeadboxUser
  maxCapacity: Scalars['Int']
  marketplaceURL?: Maybe<Scalars['String']>
  tour3dURL?: Maybe<Scalars['String']>
  location: Scalars['String']
  fullAddress: Scalars['String']
  city: Scalars['String']
  photos: Array<HeadboxSpacePhoto>
  attachments: Array<HeadboxSpaceAttachment>
  coordinates?: Maybe<Coordinates>
  layouts: Array<HeadboxLayout>
}

export type HeadboxSpaceAttachment = {
  __typename?: 'HeadboxSpaceAttachment'
  name: Scalars['String']
  URL: Scalars['String']
}

export type HeadboxSpacePhoto = {
  __typename?: 'HeadboxSpacePhoto'
  name: Scalars['String']
  URL: Scalars['String']
}

export type HeadboxUser = {
  __typename?: 'HeadboxUser'
  id: Scalars['Int']
  firstName: Scalars['String']
  lastName: Scalars['String']
  fullName: Scalars['String']
  email: Scalars['String']
  avatarURL: Scalars['String']
  mobileNumber?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  company?: Maybe<HeadboxCompany>
  spaces: Array<HeadboxSpace>
}

export type HostInvitationToDeal = {
  __typename?: 'HostInvitationToDeal'
  id: Scalars['UUID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  invitee: HeadboxUser
  deal: Deal
  createdBy: HeadboxUser
  lastUpdatedBy: HeadboxUser
  autoSuggested: Scalars['Boolean']
}

export type Mutation = {
  __typename?: 'Mutation'
  assignDealToHeadboxCompanyTeam?: Maybe<Deal>
  designateDealPrimaryContact?: Maybe<Deal>
  synchronizeDealWithHubspot?: Maybe<Deal>
  reorderProposals?: Maybe<Deal>
  shareDealURLByEmail: Scalars['Boolean']
  createHostInvitationToDeal: Deal
  deleteHostInvitationToDeal?: Maybe<Deal>
  createFileFromURL: File
  getCoordinatesForFileUpload: CoordinatesForFileUpload
  addProposalToDeal: Proposal
  removeProposal: Scalars['String']
  updateProposal: Proposal
  updateProposalState: Proposal
  cloneProposals: Array<Proposal>
  addCommentToProposal: ProposalComment
}

export type MutationAssignDealToHeadboxCompanyTeamArgs = {
  dealId: Scalars['UUID']
  headboxCompanyTeamId?: Maybe<Scalars['Int']>
}

export type MutationDesignateDealPrimaryContactArgs = {
  dealId: Scalars['UUID']
  headboxUserIdentity?: Maybe<Scalars['Int']>
}

export type MutationSynchronizeDealWithHubspotArgs = {
  id: Scalars['ID']
}

export type MutationReorderProposalsArgs = {
  dealId: Scalars['UUID']
  proposalIds: Array<Scalars['UUID']>
}

export type MutationShareDealUrlByEmailArgs = {
  dealId: Scalars['UUID']
  recipients: Array<Scalars['String']>
  customMessage?: Maybe<Scalars['String']>
}

export type MutationCreateHostInvitationToDealArgs = {
  dealId: Scalars['UUID']
  headboxHostIdentity: Scalars['Int']
  customMessage: Scalars['String']
}

export type MutationDeleteHostInvitationToDealArgs = {
  invitationIdentity: Scalars['UUID']
}

export type MutationCreateFileFromUrlArgs = {
  name?: Maybe<Scalars['String']>
  url: Scalars['String']
}

export type MutationAddProposalToDealArgs = {
  dealId: Scalars['UUID']
  proposalData: ProposalDataInput
}

export type MutationRemoveProposalArgs = {
  proposalId: Scalars['UUID']
}

export type MutationUpdateProposalArgs = {
  proposalId: Scalars['UUID']
  proposalData: ProposalDataInput
}

export type MutationUpdateProposalStateArgs = {
  proposalId: Scalars['UUID']
  proposalState: ProposalState
  reason?: Maybe<Scalars['String']>
}

export type MutationCloneProposalsArgs = {
  targetDealId: Scalars['UUID']
  proposalsIds: Array<Scalars['UUID']>
}

export type MutationAddCommentToProposalArgs = {
  proposalId: Scalars['UUID']
  commentData: ProposalCommentInput
}

export type Page = {
  __typename?: 'Page'
  number: Scalars['Int']
  size: Scalars['Int']
  totalPages: Scalars['Int']
}

export type PaginationInput = {
  pageNumber: Scalars['Int']
  pageSize: Scalars['Int']
}

export type Price = {
  __typename?: 'Price'
  amount: Scalars['Float']
  vatIncluded: Scalars['Boolean']
  currency: Currency
}

export type PriceInput = {
  amount: Scalars['Float']
  vatIncluded: Scalars['Boolean']
  currency: Currency
}

export type Proposal = {
  __typename?: 'Proposal'
  id: Scalars['UUID']
  name: Scalars['String']
  author?: Maybe<HeadboxUser>
  state: ProposalState
  stateReason: Scalars['String']
  stateSetter?: Maybe<HeadboxUser>
  totalCost: Price
  space: ProposalSpace
  additionalNotes: DraftJsRawContentArrayForm
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  attachments: Array<File>
  availabilities: Array<ProposalAvailability>
  comments: Array<ProposalComment>
  costItems: Array<ProposalCostItem>
  deal: Deal
  images: Array<ProposalImage>
  layouts: Array<ProposalLayout>
  actorCanAccept: Scalars['Boolean']
  actorCanReject: Scalars['Boolean']
  actorCanSetAsPending: Scalars['Boolean']
}

export type ProposalAttachmentInput = {
  id: Scalars['UUID']
  name: Scalars['String']
}

export type ProposalAvailability = {
  __typename?: 'ProposalAvailability'
  id: Scalars['UUID']
  date: Scalars['Date']
  timings: Scalars['String']
  optionHold: Scalars['String']
}

export type ProposalAvailabilityInput = {
  id?: Maybe<Scalars['UUID']>
  date: Scalars['Date']
  timings: Scalars['String']
  optionHold: Scalars['String']
}

export type ProposalComment = {
  __typename?: 'ProposalComment'
  id: Scalars['UUID']
  text: Scalars['String']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  author: ProposalCommentAuthor
  attachments: Array<File>
  proposal: Proposal
}

export type ProposalCommentAttachmentInput = {
  id: Scalars['UUID']
  name: Scalars['String']
}

export type ProposalCommentAuthor = {
  __typename?: 'ProposalCommentAuthor'
  fullName: Scalars['String']
  avatarURL?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  mobileNumber?: Maybe<Scalars['String']>
  user?: Maybe<HeadboxUser>
}

export type ProposalCommentInput = {
  text: Scalars['String']
  authorName?: Maybe<Scalars['String']>
  authorEmail?: Maybe<Scalars['String']>
  attachments: Array<ProposalCommentAttachmentInput>
}

export type ProposalCostItem = {
  __typename?: 'ProposalCostItem'
  id: Scalars['UUID']
  type: Scalars['String']
  description: Scalars['String']
}

export type ProposalCostItemInput = {
  id?: Maybe<Scalars['UUID']>
  type: Scalars['String']
  description: Scalars['String']
}

export type ProposalDataInput = {
  name: Scalars['String']
  state: ProposalState
  totalCost: PriceInput
  space: ProposalSpaceInput
  additionalNotes: DraftJsRawContentArrayFormInput
  attachments: Array<ProposalAttachmentInput>
  availabilities: Array<ProposalAvailabilityInput>
  costItems: Array<ProposalCostItemInput>
  images: Array<ProposalImageInput>
  layouts: Array<ProposalLayoutInput>
}

export type ProposalImage = {
  __typename?: 'ProposalImage'
  id: Scalars['UUID']
  name: Scalars['String']
  caption: Scalars['String']
  mimeType: Scalars['String']
  size: Scalars['Int']
  URL: Scalars['String']
  createdAt: Scalars['DateTime']
}

export type ProposalImageInput = {
  id: Scalars['UUID']
  name: Scalars['String']
  caption: Scalars['String']
}

export type ProposalLayout = {
  __typename?: 'ProposalLayout'
  name: Scalars['String']
  capacity: Scalars['Int']
}

export type ProposalLayoutInput = {
  name: Scalars['String']
  capacity: Scalars['Int']
}

export type ProposalSpace = {
  __typename?: 'ProposalSpace'
  name: Scalars['String']
  description: DraftJsRawContentArrayForm
  marketplaceId?: Maybe<Scalars['Int']>
  marketplaceURL?: Maybe<Scalars['String']>
  tour3dURL?: Maybe<Scalars['String']>
  location: Scalars['String']
  fullAddress: Scalars['String']
  city: Scalars['String']
  coordinates?: Maybe<Coordinates>
  hostEmail?: Maybe<Scalars['String']>
}

export type ProposalSpaceInput = {
  name: Scalars['String']
  description: DraftJsRawContentArrayFormInput
  marketplaceId?: Maybe<Scalars['Int']>
  location: Scalars['String']
  fullAddress: Scalars['String']
  city: Scalars['String']
  hostEmail: Scalars['String']
}

export enum ProposalState {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export type Query = {
  __typename?: 'Query'
  accountManagers: Array<AccountManager>
  me: Actor
  allEventTypes: Array<Scalars['String']>
  dealById?: Maybe<Deal>
  dealByPublicAccessToken?: Maybe<Deal>
  dealsList: DealsList
  deals: Array<Deal>
  companiesWithDeals: Array<HeadboxCompany>
  headboxSpaceById?: Maybe<HeadboxSpace>
  headboxUserByEmail?: Maybe<HeadboxUser>
  proposalById?: Maybe<Proposal>
  proposalsByHeadboxSpaceId: Array<Proposal>
  runtimeVariables: RuntimeVariables
}

export type QueryDealByIdArgs = {
  id: Scalars['UUID']
}

export type QueryDealByPublicAccessTokenArgs = {
  token: Scalars['UUID']
}

export type QueryDealsListArgs = {
  pagination: PaginationInput
  sorting: DealsListSortingInput
  filtering: DealsListFilteringInput
}

export type QueryDealsArgs = {
  limit?: Maybe<Scalars['Int']>
  filtering: DealsFilteringInput
}

export type QueryHeadboxSpaceByIdArgs = {
  id: Scalars['Int']
}

export type QueryHeadboxUserByEmailArgs = {
  email: Scalars['String']
}

export type QueryProposalByIdArgs = {
  id: Scalars['UUID']
}

export type QueryProposalsByHeadboxSpaceIdArgs = {
  id: Scalars['Int']
}

export type RuntimeVariables = {
  __typename?: 'RuntimeVariables'
  createDealFormURL?: Maybe<Scalars['String']>
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ContainersActorProviderQueryVariables = {}

export type ContainersActorProviderQuery = { __typename?: 'Query' } & {
  actor: { __typename?: 'Actor' } & Pick<
    Actor,
    | 'isAccountManager'
    | 'isBusinessAdministrator'
    | 'isBusinessManager'
    | 'isBusinessMember'
    | 'isHost'
  > & {
      user: Maybe<
        { __typename?: 'HeadboxUser' } & Pick<
          HeadboxUser,
          'id' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'avatarURL'
        > & {
            company: Maybe<
              { __typename?: 'HeadboxCompany' } & Pick<
                HeadboxCompany,
                'id' | 'name' | 'hubspotNewDealFormURL'
              >
            >
            spaces: Array<
              { __typename?: 'HeadboxSpace' } & Pick<
                HeadboxSpace,
                'id' | 'name' | 'venueName'
              >
            >
          }
      >
    }
}

export type CreateProposalWizardCreateFileMutationVariables = {
  name: Scalars['String']
  URL: Scalars['String']
}

export type CreateProposalWizardCreateFileMutation = {
  __typename?: 'Mutation'
} & {
  file: { __typename?: 'File' } & Pick<
    File,
    'id' | 'createdAt' | 'mimeType' | 'name' | 'size' | 'URL'
  >
}

export type CreateProposalWizardFetchFullProposalQueryVariables = {
  id: Scalars['UUID']
}

export type CreateProposalWizardFetchFullProposalQuery = {
  __typename?: 'Query'
} & {
  proposal: Maybe<
    { __typename?: 'Proposal' } & Pick<Proposal, 'id' | 'name' | 'state'> & {
        deal: { __typename?: 'Deal' } & Pick<
          Deal,
          'id' | 'name' | 'stage' | 'decisionDeadline'
        > & {
            amount: Maybe<
              { __typename?: 'Price' } & Pick<
                Price,
                'amount' | 'vatIncluded' | 'currency'
              >
            >
            event: { __typename?: 'DealEvent' } & Pick<
              DealEvent,
              'name' | 'type' | 'date'
            > & {
                budget: Maybe<
                  { __typename?: 'Price' } & Pick<
                    Price,
                    'amount' | 'vatIncluded' | 'currency'
                  >
                >
              }
          }
        space: { __typename?: 'ProposalSpace' } & Pick<
          ProposalSpace,
          | 'name'
          | 'hostEmail'
          | 'marketplaceURL'
          | 'marketplaceId'
          | 'tour3dURL'
          | 'location'
          | 'fullAddress'
          | 'city'
        > & {
            description: {
              __typename?: 'DraftJsRawContentArrayForm'
            } & CreateProposalWizardFetchFullProposalDraftStateFragment
            coordinates: Maybe<
              { __typename?: 'Coordinates' } & Pick<
                Coordinates,
                'latitude' | 'longitude'
              >
            >
          }
        layouts: Array<
          { __typename?: 'ProposalLayout' } & Pick<
            ProposalLayout,
            'capacity' | 'name'
          >
        >
        totalCost: { __typename?: 'Price' } & Pick<
          Price,
          'amount' | 'currency' | 'vatIncluded'
        >
        images: Array<
          { __typename?: 'ProposalImage' } & Pick<
            ProposalImage,
            | 'id'
            | 'name'
            | 'caption'
            | 'URL'
            | 'createdAt'
            | 'mimeType'
            | 'size'
          >
        >
        attachments: Array<
          { __typename?: 'File' } & Pick<
            File,
            'id' | 'name' | 'mimeType' | 'size' | 'URL'
          >
        >
        availabilities: Array<
          { __typename?: 'ProposalAvailability' } & Pick<
            ProposalAvailability,
            'id' | 'date' | 'optionHold' | 'timings'
          >
        >
        costItems: Array<
          { __typename?: 'ProposalCostItem' } & Pick<
            ProposalCostItem,
            'id' | 'type' | 'description'
          >
        >
        additionalNotes: {
          __typename?: 'DraftJsRawContentArrayForm'
        } & CreateProposalWizardFetchFullProposalDraftStateFragment
      }
  >
}

export type CreateProposalWizardFetchFullProposalDraftStateFragment = {
  __typename?: 'DraftJsRawContentArrayForm'
} & {
  blocks: Array<
    { __typename?: 'DraftJsBlock' } & Pick<
      DraftJsBlock,
      'key' | 'text' | 'depth' | 'type'
    > & {
        inlineStyleRanges: Array<
          { __typename?: 'DraftJsInlineStyleRange' } & Pick<
            DraftJsInlineStyleRange,
            'offset' | 'length' | 'style'
          >
        >
        entityRanges: Array<
          { __typename?: 'DraftJsEntityRange' } & Pick<
            DraftJsEntityRange,
            'offset' | 'length' | 'key'
          >
        >
        data: { __typename?: 'DraftJsBlockData' } & Pick<DraftJsBlockData, 'id'>
      }
  >
  entityArray: Array<
    { __typename?: 'DraftJsEntity' } & Pick<
      DraftJsEntity,
      'type' | 'mutability'
    > & {
        data: { __typename?: 'DraftJsEntityLinkData' } & Pick<
          DraftJsEntityLinkData,
          'type' | 'url'
        >
      }
  >
}

export type CreateProposalWizardFetchHeadboxSpaceQueryVariables = {
  spaceId: Scalars['Int']
}

export type CreateProposalWizardFetchHeadboxSpaceQuery = {
  __typename?: 'Query'
} & {
  space: Maybe<
    { __typename?: 'HeadboxSpace' } & Pick<
      HeadboxSpace,
      | 'id'
      | 'name'
      | 'venueName'
      | 'description'
      | 'fullAddress'
      | 'location'
      | 'city'
      | 'maxCapacity'
      | 'marketplaceURL'
      | 'tour3dURL'
    > & {
        host: { __typename?: 'HeadboxUser' } & Pick<HeadboxUser, 'email'>
        photos: Array<
          { __typename?: 'HeadboxSpacePhoto' } & Pick<
            HeadboxSpacePhoto,
            'name' | 'URL'
          >
        >
        layouts: Array<
          { __typename?: 'HeadboxLayout' } & Pick<
            HeadboxLayout,
            'capacity' | 'layout'
          >
        >
      }
  >
}

export type CreateProposalWizardGetProposalsBySpaceIdQueryVariables = {
  id: Scalars['Int']
}

export type CreateProposalWizardGetProposalsBySpaceIdQuery = {
  __typename?: 'Query'
} & {
  proposals: Array<
    { __typename?: 'Proposal' } & Pick<
      Proposal,
      'id' | 'createdAt' | 'deletedAt'
    > & {
        deal: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
            headboxCompany: Maybe<
              { __typename?: 'HeadboxCompany' } & Pick<
                HeadboxCompany,
                'id' | 'name'
              >
            >
            event: { __typename?: 'DealEvent' } & Pick<
              DealEvent,
              'type' | 'date'
            >
          }
        totalCost: { __typename?: 'Price' } & Pick<
          Price,
          'amount' | 'vatIncluded' | 'currency'
        >
      }
  >
}

export type ContainersRuntimeVariablesProviderQueryVariables = {}

export type ContainersRuntimeVariablesProviderQuery = {
  __typename?: 'Query'
} & {
  runtimeVariables: { __typename?: 'RuntimeVariables' } & Pick<
    RuntimeVariables,
    'createDealFormURL'
  >
}

export type UploadAreaCreateFileMutationVariables = {
  name: Scalars['String']
  URL: Scalars['String']
}

export type UploadAreaCreateFileMutation = { __typename?: 'Mutation' } & {
  file: { __typename?: 'File' } & Pick<
    File,
    'id' | 'createdAt' | 'mimeType' | 'name' | 'size' | 'URL'
  >
}

export type UploadAreaGetCoordinatesForFileUploadMutationVariables = {}

export type UploadAreaGetCoordinatesForFileUploadMutation = {
  __typename?: 'Mutation'
} & {
  coordinatesForFileUpload: { __typename?: 'CoordinatesForFileUpload' } & Pick<
    CoordinatesForFileUpload,
    'signedURL'
  >
}

export type ScreensDealAddCommentToProposalMutationVariables = {
  proposalId: Scalars['UUID']
  commentData: ProposalCommentInput
}

export type ScreensDealAddCommentToProposalMutation = {
  __typename?: 'Mutation'
} & {
  comment: { __typename?: 'ProposalComment' } & Pick<ProposalComment, 'id'> & {
      proposal: { __typename?: 'Proposal' } & Pick<Proposal, 'id'> & {
          comments: Array<
            { __typename?: 'ProposalComment' } & Pick<
              ProposalComment,
              'id' | 'text' | 'createdAt' | 'updatedAt'
            > & {
                author: { __typename?: 'ProposalCommentAuthor' } & Pick<
                  ProposalCommentAuthor,
                  'fullName' | 'email' | 'mobileNumber' | 'avatarURL'
                > & {
                    user: Maybe<
                      { __typename?: 'HeadboxUser' } & Pick<
                        HeadboxUser,
                        'id'
                      > & {
                          company: Maybe<
                            { __typename?: 'HeadboxCompany' } & Pick<
                              HeadboxCompany,
                              'id' | 'name'
                            >
                          >
                        }
                    >
                  }
              }
          >
        }
    }
}

export type ScreensDealAssignDealToHeadboxCompanyTeamMutationVariables = {
  dealId: Scalars['UUID']
  teamId?: Maybe<Scalars['Int']>
}

export type ScreensDealAssignDealToHeadboxCompanyTeamMutation = {
  __typename?: 'Mutation'
} & {
  assignDealToHeadboxCompanyTeam: Maybe<
    { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
        headboxCompanyTeam: Maybe<
          { __typename?: 'HeadboxCompanyTeam' } & Pick<
            HeadboxCompanyTeam,
            'id' | 'name'
          > & {
              managers: Array<
                { __typename?: 'HeadboxUser' } & Pick<HeadboxUser, 'id'>
              >
            }
        >
      }
  >
}

export type ScreensDealCloneProposalsMutationVariables = {
  targetDealId: Scalars['UUID']
  proposalsIds: Array<Scalars['UUID']>
}

export type ScreensDealCloneProposalsMutation = { __typename?: 'Mutation' } & {
  clonedProposals: Array<{ __typename?: 'Proposal' } & Pick<Proposal, 'id'>>
}

export type ScreensDealCloneProposalsFetchDealQueryVariables = {
  dealId: Scalars['UUID']
}

export type ScreensDealCloneProposalsFetchDealQuery = {
  __typename?: 'Query'
} & {
  targetDeal: Maybe<
    { __typename?: 'Deal' } & Pick<
      Deal,
      'id' | 'name' | 'decisionDeadline' | 'stage'
    > & {
        amount: Maybe<
          { __typename?: 'Price' } & Pick<
            Price,
            'amount' | 'currency' | 'vatIncluded'
          >
        >
        event: { __typename?: 'DealEvent' } & Pick<
          DealEvent,
          'name' | 'brief' | 'productInterest' | 'date'
        > & {
            budget: Maybe<
              { __typename?: 'Price' } & Pick<
                Price,
                'amount' | 'currency' | 'vatIncluded'
              >
            >
          }
        proposals: Array<
          { __typename?: 'Proposal' } & Pick<Proposal, 'id' | 'deletedAt'> & {
              totalCost: { __typename?: 'Price' } & Pick<
                Price,
                'amount' | 'currency' | 'vatIncluded'
              >
              space: { __typename?: 'ProposalSpace' } & Pick<
                ProposalSpace,
                'name' | 'location'
              >
            }
        >
      }
  >
}

export type ScreensDealCreateHostInvitationMutationVariables = {
  dealId: Scalars['UUID']
  headboxHostIdentity: Scalars['Int']
  customMessage: Scalars['String']
}

export type ScreensDealCreateHostInvitationMutation = {
  __typename?: 'Mutation'
} & {
  createHostInvitationToDeal: { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
      hostInvitations: Array<
        { __typename?: 'HostInvitationToDeal' } & Pick<
          HostInvitationToDeal,
          'id'
        > & {
            createdBy: { __typename?: 'HeadboxUser' } & Pick<HeadboxUser, 'id'>
            invitee: { __typename?: 'HeadboxUser' } & Pick<
              HeadboxUser,
              'id' | 'fullName' | 'avatarURL'
            >
          }
      >
    }
}

export type ScreensDealDeleteProposalMutationVariables = {
  proposalId: Scalars['UUID']
}

export type ScreensDealDeleteProposalMutation = { __typename?: 'Mutation' } & {
  proposalRemoved: Mutation['removeProposal']
}

export type ScreensDealDesignateDealPrimaryContactMutationVariables = {
  dealId: Scalars['UUID']
  headboxUserId?: Maybe<Scalars['Int']>
}

export type ScreensDealDesignateDealPrimaryContactMutation = {
  __typename?: 'Mutation'
} & {
  designateDealPrimaryContact: Maybe<
    { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
        primaryContact:
          | ({ __typename?: 'HeadboxUser' } & Pick<
              HeadboxUser,
              | 'id'
              | 'fullName'
              | 'firstName'
              | 'lastName'
              | 'email'
              | 'phoneNumber'
            >)
          | ({ __typename?: 'DealPrimaryContactProps' } & Pick<
              DealPrimaryContactProps,
              'fullName' | 'firstName' | 'lastName' | 'email' | 'phone'
            >)
      }
  >
}

export type ScreensDealFetchDealQueryVariables = {
  dealId: Scalars['UUID']
}

export type ScreensDealFetchDealQuery = { __typename?: 'Query' } & {
  deal: Maybe<
    { __typename?: 'Deal' } & Pick<
      Deal,
      | 'id'
      | 'publicAccessToken'
      | 'numberOfAttendees'
      | 'name'
      | 'stage'
      | 'decisionDeadline'
      | 'headboxServiceFee'
      | 'bookedSpace'
      | 'hubspotDealURL'
      | 'actorCanAssignToACompamyTeam'
      | 'actorCanShareByEmail'
      | 'actorCanDesignateAPrimaryContact'
      | 'actorCanCreateProposal'
    > & {
        event: { __typename?: 'DealEvent' } & Pick<
          DealEvent,
          'name' | 'brief' | 'type' | 'date'
        > & {
            budget: Maybe<
              { __typename?: 'Price' } & Pick<
                Price,
                'amount' | 'currency' | 'vatIncluded'
              >
            >
          }
        headboxCompany: Maybe<
          { __typename?: 'HeadboxCompany' } & Pick<
            HeadboxCompany,
            'id' | 'name' | 'logoURL'
          > & {
              members: Array<
                { __typename?: 'HeadboxUser' } & Pick<
                  HeadboxUser,
                  'id' | 'fullName' | 'avatarURL'
                >
              >
              teams: Array<
                { __typename?: 'HeadboxCompanyTeam' } & Pick<
                  HeadboxCompanyTeam,
                  'id' | 'name'
                >
              >
            }
        >
        headboxCompanyTeam: Maybe<
          { __typename?: 'HeadboxCompanyTeam' } & Pick<
            HeadboxCompanyTeam,
            'id' | 'name'
          > & {
              managers: Array<
                { __typename?: 'HeadboxUser' } & Pick<HeadboxUser, 'id'>
              >
            }
        >
        primaryContact:
          | ({ __typename?: 'HeadboxUser' } & Pick<
              HeadboxUser,
              | 'id'
              | 'fullName'
              | 'firstName'
              | 'lastName'
              | 'email'
              | 'phoneNumber'
            >)
          | ({ __typename?: 'DealPrimaryContactProps' } & Pick<
              DealPrimaryContactProps,
              'fullName' | 'firstName' | 'lastName' | 'email' | 'phone'
            >)
        ownerAccountManager: Maybe<
          { __typename?: 'AccountManager' } & Pick<
            AccountManager,
            'id' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'avatarURL'
          >
        >
        juniorAccountManager: Maybe<
          { __typename?: 'AccountManager' } & Pick<
            AccountManager,
            'id' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'avatarURL'
          >
        >
        hostInvitations: Array<
          { __typename?: 'HostInvitationToDeal' } & Pick<
            HostInvitationToDeal,
            'id' | 'createdAt'
          > & {
              createdBy: { __typename?: 'HeadboxUser' } & Pick<
                HeadboxUser,
                'id' | 'fullName'
              >
              invitee: { __typename?: 'HeadboxUser' } & Pick<
                HeadboxUser,
                'id' | 'fullName' | 'avatarURL'
              >
            }
        >
        amount: Maybe<
          { __typename?: 'Price' } & Pick<
            Price,
            'amount' | 'currency' | 'vatIncluded'
          >
        >
        proposals: Array<
          { __typename?: 'Proposal' } & Pick<
            Proposal,
            | 'id'
            | 'name'
            | 'state'
            | 'stateReason'
            | 'deletedAt'
            | 'actorCanAccept'
            | 'actorCanReject'
            | 'actorCanSetAsPending'
          > & {
              space: { __typename?: 'ProposalSpace' } & Pick<
                ProposalSpace,
                | 'name'
                | 'location'
                | 'city'
                | 'marketplaceURL'
                | 'marketplaceId'
                | 'hostEmail'
              > & {
                  coordinates: Maybe<
                    { __typename?: 'Coordinates' } & Pick<
                      Coordinates,
                      'latitude' | 'longitude'
                    >
                  >
                }
              layouts: Array<
                { __typename?: 'ProposalLayout' } & Pick<
                  ProposalLayout,
                  'capacity' | 'name'
                >
              >
              totalCost: { __typename?: 'Price' } & Pick<
                Price,
                'amount' | 'currency' | 'vatIncluded'
              >
              images: Array<
                { __typename?: 'ProposalImage' } & Pick<
                  ProposalImage,
                  'id' | 'name' | 'caption' | 'URL'
                >
              >
              availabilities: Array<
                { __typename?: 'ProposalAvailability' } & Pick<
                  ProposalAvailability,
                  'id' | 'date' | 'optionHold' | 'timings'
                >
              >
              costItems: Array<
                { __typename?: 'ProposalCostItem' } & Pick<
                  ProposalCostItem,
                  'id' | 'type' | 'description'
                >
              >
              comments: Array<
                { __typename?: 'ProposalComment' } & Pick<
                  ProposalComment,
                  'id' | 'text' | 'createdAt' | 'updatedAt'
                > & {
                    author: { __typename?: 'ProposalCommentAuthor' } & Pick<
                      ProposalCommentAuthor,
                      'fullName' | 'email' | 'mobileNumber' | 'avatarURL'
                    > & {
                        user: Maybe<
                          { __typename?: 'HeadboxUser' } & Pick<
                            HeadboxUser,
                            'id'
                          > & {
                              company: Maybe<
                                { __typename?: 'HeadboxCompany' } & Pick<
                                  HeadboxCompany,
                                  'id' | 'name'
                                >
                              >
                            }
                        >
                      }
                  }
              >
            }
        >
      } & SummaryFieldsFragment
  >
}

export type ScreensDealDeleteHostInvitationMutationVariables = {
  invitationIdentity: Scalars['UUID']
}

export type ScreensDealDeleteHostInvitationMutation = {
  __typename?: 'Mutation'
} & {
  deleteHostInvitationToDeal: Maybe<
    { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
        hostInvitations: Array<
          { __typename?: 'HostInvitationToDeal' } & Pick<
            HostInvitationToDeal,
            'id'
          > & {
              invitee: { __typename?: 'HeadboxUser' } & Pick<
                HeadboxUser,
                'id' | 'fullName'
              >
            }
        >
      }
  >
}

export type ScreensDealFetchHostByEmailQueryVariables = {
  email: Scalars['String']
}

export type ScreensDealFetchHostByEmailQuery = { __typename?: 'Query' } & {
  headboxUserByEmail: Maybe<
    { __typename?: 'HeadboxUser' } & Pick<
      HeadboxUser,
      'id' | 'avatarURL' | 'email' | 'fullName'
    >
  >
}

export type ScreensDealFetchHostFromSpaceQueryVariables = {
  id: Scalars['Int']
}

export type ScreensDealFetchHostFromSpaceQuery = { __typename?: 'Query' } & {
  headboxSpaceById: Maybe<
    { __typename?: 'HeadboxSpace' } & Pick<HeadboxSpace, 'id'> & {
        host: { __typename?: 'HeadboxUser' } & Pick<
          HeadboxUser,
          'id' | 'fullName' | 'email' | 'avatarURL'
        >
      }
  >
}

export type ScreensDealReorderProposalsMutationVariables = {
  dealId: Scalars['UUID']
  proposalIds: Array<Scalars['UUID']>
}

export type ScreensDealReorderProposalsMutation = {
  __typename?: 'Mutation'
} & {
  reorderProposals: Maybe<
    { __typename?: 'Deal' } & Pick<Deal, 'id'> & {
        proposals: Array<{ __typename?: 'Proposal' } & Pick<Proposal, 'id'>>
      }
  >
}

export type ScreensDealShareDealUrlByEmailMutationVariables = {
  dealId: Scalars['UUID']
  recipients: Array<Scalars['String']>
  customMessage?: Maybe<Scalars['String']>
}

export type ScreensDealShareDealUrlByEmailMutation = {
  __typename?: 'Mutation'
} & { dealURLShared: Mutation['shareDealURLByEmail'] }

export type SummaryFieldsFragment = { __typename?: 'Deal' } & Pick<
  Deal,
  | 'id'
  | 'name'
  | 'stage'
  | 'decisionDeadline'
  | 'numberOfAttendees'
  | 'hubspotDealURL'
> & {
    headboxCompany: Maybe<
      { __typename?: 'HeadboxCompany' } & Pick<HeadboxCompany, 'name'>
    >
    amount: Maybe<
      { __typename?: 'Price' } & Pick<
        Price,
        'amount' | 'currency' | 'vatIncluded'
      >
    >
    event: { __typename?: 'DealEvent' } & Pick<
      DealEvent,
      | 'type'
      | 'date'
      | 'location'
      | 'seatingStyle'
      | 'length'
      | 'time'
      | 'flexibility'
      | 'facilities'
      | 'catering'
      | 'additionalRequirements'
      | 'productInterest'
    > & {
        budget: Maybe<
          { __typename?: 'Price' } & Pick<
            Price,
            'amount' | 'currency' | 'vatIncluded'
          >
        >
      }
  }

export type ScreensDealSyncDealWithHubspotMutationVariables = {
  dealId: Scalars['ID']
}

export type ScreensDealSyncDealWithHubspotMutation = {
  __typename?: 'Mutation'
} & {
  synchronizeDealWithHubspot: Maybe<{ __typename?: 'Deal' } & Pick<Deal, 'id'>>
}

export type ScreensDealUpdateProposalStateMutationVariables = {
  proposalId: Scalars['UUID']
  newState: ProposalState
  reason?: Maybe<Scalars['String']>
}

export type ScreensDealUpdateProposalStateMutation = {
  __typename?: 'Mutation'
} & {
  updateProposalState: { __typename?: 'Proposal' } & Pick<
    Proposal,
    'id' | 'state' | 'stateReason'
  >
}

export type ScreensDealSharedFetchDealQueryVariables = {
  dealPublicId: Scalars['UUID']
  accessToken?: Maybe<Scalars['String']>
}

export type ScreensDealSharedFetchDealQuery = { __typename?: 'Query' } & {
  deal: Maybe<
    { __typename?: 'Deal' } & Pick<
      Deal,
      | 'id'
      | 'name'
      | 'stage'
      | 'decisionDeadline'
      | 'headboxServiceFee'
      | 'bookedSpace'
      | 'hubspotDealURL'
    > & {
        event: { __typename?: 'DealEvent' } & Pick<
          DealEvent,
          'name' | 'brief' | 'productInterest' | 'date'
        > & {
            budget: Maybe<
              { __typename?: 'Price' } & Pick<
                Price,
                'amount' | 'currency' | 'vatIncluded'
              >
            >
          }
        headboxCompany: Maybe<
          { __typename?: 'HeadboxCompany' } & Pick<
            HeadboxCompany,
            'id' | 'name' | 'logoURL'
          > & {
              members: Array<
                { __typename?: 'HeadboxUser' } & Pick<
                  HeadboxUser,
                  'id' | 'fullName' | 'avatarURL'
                >
              >
              teams: Array<
                { __typename?: 'HeadboxCompanyTeam' } & Pick<
                  HeadboxCompanyTeam,
                  'id' | 'name'
                >
              >
            }
        >
        headboxCompanyTeam: Maybe<
          { __typename?: 'HeadboxCompanyTeam' } & Pick<
            HeadboxCompanyTeam,
            'id' | 'name'
          > & {
              managers: Array<
                { __typename?: 'HeadboxUser' } & Pick<HeadboxUser, 'id'>
              >
            }
        >
        primaryContact:
          | ({ __typename?: 'HeadboxUser' } & Pick<
              HeadboxUser,
              | 'id'
              | 'fullName'
              | 'firstName'
              | 'lastName'
              | 'email'
              | 'phoneNumber'
            >)
          | ({ __typename?: 'DealPrimaryContactProps' } & Pick<
              DealPrimaryContactProps,
              'fullName' | 'firstName' | 'lastName' | 'email' | 'phone'
            >)
        ownerAccountManager: Maybe<
          { __typename?: 'AccountManager' } & Pick<
            AccountManager,
            'id' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'avatarURL'
          >
        >
        juniorAccountManager: Maybe<
          { __typename?: 'AccountManager' } & Pick<
            AccountManager,
            'id' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'avatarURL'
          >
        >
        amount: Maybe<
          { __typename?: 'Price' } & Pick<
            Price,
            'amount' | 'currency' | 'vatIncluded'
          >
        >
        proposals: Array<
          { __typename?: 'Proposal' } & Pick<
            Proposal,
            'id' | 'name' | 'state' | 'deletedAt'
          > & {
              layouts: Array<
                { __typename?: 'ProposalLayout' } & Pick<
                  ProposalLayout,
                  'capacity' | 'name'
                >
              >
              space: { __typename?: 'ProposalSpace' } & Pick<
                ProposalSpace,
                | 'name'
                | 'marketplaceURL'
                | 'marketplaceId'
                | 'tour3dURL'
                | 'location'
                | 'fullAddress'
                | 'city'
              > & {
                  description: {
                    __typename?: 'DraftJsRawContentArrayForm'
                  } & ScreensDealSharedFetchDealProposalDraftStateFragment
                  coordinates: Maybe<
                    { __typename?: 'Coordinates' } & Pick<
                      Coordinates,
                      'latitude' | 'longitude'
                    >
                  >
                }
              totalCost: { __typename?: 'Price' } & Pick<
                Price,
                'amount' | 'currency' | 'vatIncluded'
              >
              images: Array<
                { __typename?: 'ProposalImage' } & Pick<
                  ProposalImage,
                  'id' | 'name' | 'caption' | 'URL'
                >
              >
              availabilities: Array<
                { __typename?: 'ProposalAvailability' } & Pick<
                  ProposalAvailability,
                  'id' | 'date' | 'optionHold' | 'timings'
                >
              >
              costItems: Array<
                { __typename?: 'ProposalCostItem' } & Pick<
                  ProposalCostItem,
                  'id' | 'type' | 'description'
                >
              >
              attachments: Array<
                { __typename?: 'File' } & Pick<
                  File,
                  'id' | 'name' | 'mimeType' | 'size' | 'URL'
                >
              >
              comments: Array<
                { __typename?: 'ProposalComment' } & Pick<
                  ProposalComment,
                  'id' | 'text' | 'createdAt' | 'updatedAt'
                > & {
                    author: { __typename?: 'ProposalCommentAuthor' } & Pick<
                      ProposalCommentAuthor,
                      'fullName' | 'email' | 'mobileNumber' | 'avatarURL'
                    > & {
                        user: Maybe<
                          { __typename?: 'HeadboxUser' } & Pick<
                            HeadboxUser,
                            'id'
                          > & {
                              company: Maybe<
                                { __typename?: 'HeadboxCompany' } & Pick<
                                  HeadboxCompany,
                                  'id' | 'name'
                                >
                              >
                            }
                        >
                      }
                  }
              >
              additionalNotes: {
                __typename?: 'DraftJsRawContentArrayForm'
              } & ScreensDealSharedFetchDealProposalDraftStateFragment
            }
        >
      }
  >
}

export type ScreensDealSharedFetchDealProposalDraftStateFragment = {
  __typename?: 'DraftJsRawContentArrayForm'
} & {
  blocks: Array<
    { __typename?: 'DraftJsBlock' } & Pick<
      DraftJsBlock,
      'key' | 'text' | 'depth' | 'type'
    > & {
        inlineStyleRanges: Array<
          { __typename?: 'DraftJsInlineStyleRange' } & Pick<
            DraftJsInlineStyleRange,
            'offset' | 'length' | 'style'
          >
        >
        entityRanges: Array<
          { __typename?: 'DraftJsEntityRange' } & Pick<
            DraftJsEntityRange,
            'offset' | 'length' | 'key'
          >
        >
        data: { __typename?: 'DraftJsBlockData' } & Pick<DraftJsBlockData, 'id'>
      }
  >
  entityArray: Array<
    { __typename?: 'DraftJsEntity' } & Pick<
      DraftJsEntity,
      'type' | 'mutability'
    > & {
        data: { __typename?: 'DraftJsEntityLinkData' } & Pick<
          DraftJsEntityLinkData,
          'type' | 'url'
        >
      }
  >
}

export type ScreensPipelineFetchDataForFiltersQueryVariables = {}

export type ScreensPipelineFetchDataForFiltersQuery = {
  __typename?: 'Query'
} & Pick<Query, 'allEventTypes'> & {
    accountManagers: Array<
      { __typename?: 'AccountManager' } & Pick<
        AccountManager,
        'id' | 'fullName' | 'avatarURL'
      >
    >
    companies: Array<
      { __typename?: 'HeadboxCompany' } & Pick<
        HeadboxCompany,
        'id' | 'name' | 'logoURL'
      >
    >
    userCompany: { __typename?: 'Actor' } & {
      user: Maybe<
        { __typename?: 'HeadboxUser' } & Pick<HeadboxUser, 'id'> & {
            company: Maybe<
              { __typename?: 'HeadboxCompany' } & Pick<HeadboxCompany, 'id'> & {
                  members: Array<
                    { __typename?: 'HeadboxUser' } & Pick<
                      HeadboxUser,
                      'id' | 'fullName'
                    >
                  >
                  teams: Array<
                    { __typename?: 'HeadboxCompanyTeam' } & Pick<
                      HeadboxCompanyTeam,
                      'id' | 'name'
                    >
                  >
                }
            >
          }
      >
    }
  }

export type ScreensPipelineFetchPipelineQueryVariables = {
  filtering: DealsListFilteringInput
  pagination: PaginationInput
  sorting: DealsListSortingInput
}

export type ScreensPipelineFetchPipelineQuery = { __typename?: 'Query' } & {
  runtimeVariables: { __typename?: 'RuntimeVariables' } & Pick<
    RuntimeVariables,
    'createDealFormURL'
  >
  pipeline: { __typename?: 'DealsList' } & Pick<DealsList, 'totalDeals'> & {
      deals: Array<
        { __typename?: 'Deal' } & Pick<
          Deal,
          | 'id'
          | 'createdAt'
          | 'updatedAt'
          | 'name'
          | 'stage'
          | 'decisionDeadline'
        > & {
            amount: Maybe<
              { __typename?: 'Price' } & Pick<
                Price,
                'amount' | 'currency' | 'vatIncluded'
              >
            >
            event: { __typename?: 'DealEvent' } & Pick<
              DealEvent,
              'name' | 'type' | 'date'
            > & {
                budget: Maybe<
                  { __typename?: 'Price' } & Pick<
                    Price,
                    'amount' | 'currency' | 'vatIncluded'
                  >
                >
              }
            primaryContact:
              | ({ __typename?: 'HeadboxUser' } & Pick<
                  HeadboxUser,
                  'id' | 'fullName'
                >)
              | ({ __typename?: 'DealPrimaryContactProps' } & Pick<
                  DealPrimaryContactProps,
                  'fullName'
                >)
            headboxCompany: Maybe<
              { __typename?: 'HeadboxCompany' } & Pick<
                HeadboxCompany,
                'id' | 'name' | 'logoURL'
              >
            >
            headboxCompanyTeam: Maybe<
              { __typename?: 'HeadboxCompanyTeam' } & Pick<
                HeadboxCompanyTeam,
                'id' | 'name'
              >
            >
            ownerAccountManager: Maybe<
              { __typename?: 'AccountManager' } & Pick<
                AccountManager,
                'id' | 'fullName' | 'email' | 'avatarURL'
              >
            >
            juniorAccountManager: Maybe<
              { __typename?: 'AccountManager' } & Pick<
                AccountManager,
                'id' | 'fullName' | 'email' | 'avatarURL'
              >
            >
          }
      >
      page: { __typename?: 'Page' } & Pick<
        Page,
        'number' | 'size' | 'totalPages'
      >
    }
}

export type ScreensPipelineSyncDealWithHubspotMutationVariables = {
  dealId: Scalars['ID']
}

export type ScreensPipelineSyncDealWithHubspotMutation = {
  __typename?: 'Mutation'
} & {
  synchronizeDealWithHubspot: Maybe<{ __typename?: 'Deal' } & Pick<Deal, 'id'>>
}

export type ScreenProposalAddCommentToProposalMutationVariables = {
  proposalId: Scalars['UUID']
  commentData: ProposalCommentInput
}

export type ScreenProposalAddCommentToProposalMutation = {
  __typename?: 'Mutation'
} & {
  comment: { __typename?: 'ProposalComment' } & Pick<ProposalComment, 'id'> & {
      proposal: { __typename?: 'Proposal' } & Pick<Proposal, 'id'> & {
          comments: Array<
            { __typename?: 'ProposalComment' } & Pick<
              ProposalComment,
              'id' | 'text' | 'createdAt' | 'updatedAt'
            > & {
                author: { __typename?: 'ProposalCommentAuthor' } & Pick<
                  ProposalCommentAuthor,
                  'fullName' | 'email' | 'mobileNumber' | 'avatarURL'
                > & {
                    user: Maybe<
                      { __typename?: 'HeadboxUser' } & Pick<
                        HeadboxUser,
                        'id'
                      > & {
                          company: Maybe<
                            { __typename?: 'HeadboxCompany' } & Pick<
                              HeadboxCompany,
                              'id' | 'name'
                            >
                          >
                        }
                    >
                  }
              }
          >
        }
    }
}

export type ScreenProposalFetchProposalQueryVariables = {
  proposalId: Scalars['UUID']
}

export type ScreenProposalFetchProposalQuery = { __typename?: 'Query' } & {
  proposal: Maybe<
    { __typename?: 'Proposal' } & Pick<
      Proposal,
      'id' | 'name' | 'state' | 'stateReason' | 'actorCanAccept'
    > & {
        layouts: Array<
          { __typename?: 'ProposalLayout' } & Pick<
            ProposalLayout,
            'capacity' | 'name'
          >
        >
        totalCost: { __typename?: 'Price' } & Pick<
          Price,
          'amount' | 'currency' | 'vatIncluded'
        >
        costItems: Array<
          { __typename?: 'ProposalCostItem' } & Pick<
            ProposalCostItem,
            'id' | 'type' | 'description'
          >
        >
        availabilities: Array<
          { __typename?: 'ProposalAvailability' } & Pick<
            ProposalAvailability,
            'id' | 'date' | 'timings' | 'optionHold'
          >
        >
        images: Array<
          { __typename?: 'ProposalImage' } & Pick<
            ProposalImage,
            'id' | 'name' | 'caption' | 'URL'
          >
        >
        attachments: Array<
          { __typename?: 'File' } & Pick<
            File,
            'id' | 'name' | 'mimeType' | 'size' | 'URL'
          >
        >
        comments: Array<
          { __typename?: 'ProposalComment' } & Pick<
            ProposalComment,
            'id' | 'text' | 'createdAt' | 'updatedAt'
          > & {
              author: { __typename?: 'ProposalCommentAuthor' } & Pick<
                ProposalCommentAuthor,
                'fullName' | 'email' | 'mobileNumber' | 'avatarURL'
              > & {
                  user: Maybe<
                    { __typename?: 'HeadboxUser' } & Pick<HeadboxUser, 'id'> & {
                        company: Maybe<
                          { __typename?: 'HeadboxCompany' } & Pick<
                            HeadboxCompany,
                            'id' | 'name'
                          >
                        >
                      }
                  >
                }
            }
        >
        additionalNotes: {
          __typename?: 'DraftJsRawContentArrayForm'
        } & ScreenProposalFetchProposalDraftStateFragment
        deal: { __typename?: 'Deal' } & Pick<
          Deal,
          'id' | 'name' | 'stage' | 'decisionDeadline' | 'numberOfAttendees'
        > & {
            event: { __typename?: 'DealEvent' } & Pick<
              DealEvent,
              'name' | 'date' | 'productInterest' | 'type'
            > & {
                budget: Maybe<
                  { __typename?: 'Price' } & Pick<
                    Price,
                    'amount' | 'currency' | 'vatIncluded'
                  >
                >
              }
            proposals: Array<
              { __typename?: 'Proposal' } & Pick<Proposal, 'id' | 'state'>
            >
            headboxCompanyTeam: Maybe<
              { __typename?: 'HeadboxCompanyTeam' } & Pick<
                HeadboxCompanyTeam,
                'id'
              > & {
                  managers: Array<
                    { __typename?: 'HeadboxUser' } & Pick<HeadboxUser, 'id'>
                  >
                }
            >
            amount: Maybe<
              { __typename?: 'Price' } & Pick<
                Price,
                'amount' | 'currency' | 'vatIncluded'
              >
            >
          }
        space: { __typename?: 'ProposalSpace' } & Pick<
          ProposalSpace,
          | 'name'
          | 'marketplaceURL'
          | 'marketplaceId'
          | 'tour3dURL'
          | 'location'
          | 'fullAddress'
          | 'city'
          | 'hostEmail'
        > & {
            description: {
              __typename?: 'DraftJsRawContentArrayForm'
            } & ScreenProposalFetchProposalDraftStateFragment
            coordinates: Maybe<
              { __typename?: 'Coordinates' } & Pick<
                Coordinates,
                'latitude' | 'longitude'
              >
            >
          }
      }
  >
}

export type ScreenProposalFetchProposalDraftStateFragment = {
  __typename?: 'DraftJsRawContentArrayForm'
} & {
  blocks: Array<
    { __typename?: 'DraftJsBlock' } & Pick<
      DraftJsBlock,
      'key' | 'text' | 'depth' | 'type'
    > & {
        inlineStyleRanges: Array<
          { __typename?: 'DraftJsInlineStyleRange' } & Pick<
            DraftJsInlineStyleRange,
            'offset' | 'length' | 'style'
          >
        >
        entityRanges: Array<
          { __typename?: 'DraftJsEntityRange' } & Pick<
            DraftJsEntityRange,
            'offset' | 'length' | 'key'
          >
        >
        data: { __typename?: 'DraftJsBlockData' } & Pick<DraftJsBlockData, 'id'>
      }
  >
  entityArray: Array<
    { __typename?: 'DraftJsEntity' } & Pick<
      DraftJsEntity,
      'type' | 'mutability'
    > & {
        data: { __typename?: 'DraftJsEntityLinkData' } & Pick<
          DraftJsEntityLinkData,
          'type' | 'url'
        >
      }
  >
}

export type ScreensProposalUpdateProposalStateMutationVariables = {
  proposalId: Scalars['UUID']
  newState: ProposalState
  reason?: Maybe<Scalars['String']>
}

export type ScreensProposalUpdateProposalStateMutation = {
  __typename?: 'Mutation'
} & {
  updateProposalState: { __typename?: 'Proposal' } & Pick<
    Proposal,
    'id' | 'state' | 'stateReason'
  >
}

export type ScreenProposalCreationCreateProposalMutationVariables = {
  dealId: Scalars['UUID']
  proposalData: ProposalDataInput
}

export type ScreenProposalCreationCreateProposalMutation = {
  __typename?: 'Mutation'
} & { proposal: { __typename?: 'Proposal' } & Pick<Proposal, 'id'> }

export type ScreenProposalCreationFetchDealQueryVariables = {
  dealId: Scalars['UUID']
}

export type ScreenProposalCreationFetchDealQuery = { __typename?: 'Query' } & {
  deal: Maybe<
    { __typename?: 'Deal' } & Pick<
      Deal,
      'id' | 'name' | 'stage' | 'decisionDeadline' | 'numberOfAttendees'
    > & {
        event: { __typename?: 'DealEvent' } & Pick<
          DealEvent,
          'name' | 'brief' | 'type' | 'productInterest' | 'date'
        > & {
            budget: Maybe<
              { __typename?: 'Price' } & Pick<
                Price,
                'amount' | 'currency' | 'vatIncluded'
              >
            >
          }
        headboxCompany: Maybe<
          { __typename?: 'HeadboxCompany' } & Pick<
            HeadboxCompany,
            'id' | 'name' | 'logoURL'
          >
        >
        primaryContact:
          | ({ __typename?: 'HeadboxUser' } & Pick<
              HeadboxUser,
              | 'id'
              | 'fullName'
              | 'firstName'
              | 'lastName'
              | 'email'
              | 'mobileNumber'
            >)
          | ({ __typename?: 'DealPrimaryContactProps' } & Pick<
              DealPrimaryContactProps,
              'fullName' | 'firstName' | 'lastName' | 'email' | 'phone'
            >)
        ownerAccountManager: Maybe<
          { __typename?: 'AccountManager' } & Pick<
            AccountManager,
            'id' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'avatarURL'
          >
        >
        juniorAccountManager: Maybe<
          { __typename?: 'AccountManager' } & Pick<
            AccountManager,
            'id' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'avatarURL'
          >
        >
        amount: Maybe<
          { __typename?: 'Price' } & Pick<
            Price,
            'amount' | 'currency' | 'vatIncluded'
          >
        >
      }
  >
}

export type ScreenProposalCreationFetchHeaboxSpaceQueryVariables = {
  spaceId: Scalars['Int']
}

export type ScreenProposalCreationFetchHeaboxSpaceQuery = {
  __typename?: 'Query'
} & {
  headboxSpaceById: Maybe<
    { __typename?: 'HeadboxSpace' } & Pick<
      HeadboxSpace,
      | 'id'
      | 'name'
      | 'venueName'
      | 'description'
      | 'maxCapacity'
      | 'marketplaceURL'
      | 'tour3dURL'
      | 'location'
      | 'fullAddress'
      | 'city'
    > & {
        photos: Array<
          { __typename?: 'HeadboxSpacePhoto' } & Pick<
            HeadboxSpacePhoto,
            'name' | 'URL'
          >
        >
        attachments: Array<
          { __typename?: 'HeadboxSpaceAttachment' } & Pick<
            HeadboxSpaceAttachment,
            'name' | 'URL'
          >
        >
        coordinates: Maybe<
          { __typename?: 'Coordinates' } & Pick<
            Coordinates,
            'latitude' | 'longitude'
          >
        >
      }
  >
}

export type ScreenProposalModificationFetchHeaboxSpaceQueryVariables = {
  spaceId: Scalars['Int']
}

export type ScreenProposalModificationFetchHeaboxSpaceQuery = {
  __typename?: 'Query'
} & {
  headboxSpaceById: Maybe<
    { __typename?: 'HeadboxSpace' } & Pick<
      HeadboxSpace,
      | 'id'
      | 'name'
      | 'venueName'
      | 'description'
      | 'maxCapacity'
      | 'marketplaceURL'
      | 'tour3dURL'
      | 'location'
      | 'fullAddress'
      | 'city'
    > & {
        photos: Array<
          { __typename?: 'HeadboxSpacePhoto' } & Pick<
            HeadboxSpacePhoto,
            'name' | 'URL'
          >
        >
        attachments: Array<
          { __typename?: 'HeadboxSpaceAttachment' } & Pick<
            HeadboxSpaceAttachment,
            'name' | 'URL'
          >
        >
        coordinates: Maybe<
          { __typename?: 'Coordinates' } & Pick<
            Coordinates,
            'latitude' | 'longitude'
          >
        >
      }
  >
}

export type ScreenProposalModificationFetchProposalQueryVariables = {
  proposalId: Scalars['UUID']
}

export type ScreenProposalModificationFetchProposalQuery = {
  __typename?: 'Query'
} & {
  proposal: Maybe<
    { __typename?: 'Proposal' } & Pick<Proposal, 'id' | 'name' | 'state'> & {
        deal: { __typename?: 'Deal' } & Pick<
          Deal,
          'id' | 'name' | 'stage' | 'decisionDeadline' | 'numberOfAttendees'
        > & {
            amount: Maybe<
              { __typename?: 'Price' } & Pick<
                Price,
                'amount' | 'vatIncluded' | 'currency'
              >
            >
            event: { __typename?: 'DealEvent' } & Pick<
              DealEvent,
              'name' | 'type' | 'date' | 'productInterest'
            > & {
                budget: Maybe<
                  { __typename?: 'Price' } & Pick<
                    Price,
                    'amount' | 'vatIncluded' | 'currency'
                  >
                >
              }
          }
        space: { __typename?: 'ProposalSpace' } & Pick<
          ProposalSpace,
          | 'name'
          | 'hostEmail'
          | 'marketplaceId'
          | 'marketplaceURL'
          | 'tour3dURL'
          | 'location'
          | 'fullAddress'
          | 'city'
        > & {
            description: {
              __typename?: 'DraftJsRawContentArrayForm'
            } & ScreenProposalModificationFetchProposalDraftStateFragment
            coordinates: Maybe<
              { __typename?: 'Coordinates' } & Pick<
                Coordinates,
                'latitude' | 'longitude'
              >
            >
          }
        layouts: Array<
          { __typename?: 'ProposalLayout' } & Pick<
            ProposalLayout,
            'capacity' | 'name'
          >
        >
        totalCost: { __typename?: 'Price' } & Pick<
          Price,
          'amount' | 'currency' | 'vatIncluded'
        >
        images: Array<
          { __typename?: 'ProposalImage' } & Pick<
            ProposalImage,
            'id' | 'name' | 'caption' | 'URL'
          >
        >
        attachments: Array<
          { __typename?: 'File' } & Pick<
            File,
            'id' | 'name' | 'mimeType' | 'size' | 'URL'
          >
        >
        availabilities: Array<
          { __typename?: 'ProposalAvailability' } & Pick<
            ProposalAvailability,
            'id' | 'date' | 'optionHold' | 'timings'
          >
        >
        costItems: Array<
          { __typename?: 'ProposalCostItem' } & Pick<
            ProposalCostItem,
            'id' | 'type' | 'description'
          >
        >
        additionalNotes: {
          __typename?: 'DraftJsRawContentArrayForm'
        } & ScreenProposalModificationFetchProposalDraftStateFragment
      }
  >
}

export type ScreenProposalModificationFetchProposalDraftStateFragment = {
  __typename?: 'DraftJsRawContentArrayForm'
} & {
  blocks: Array<
    { __typename?: 'DraftJsBlock' } & Pick<
      DraftJsBlock,
      'key' | 'text' | 'depth' | 'type'
    > & {
        inlineStyleRanges: Array<
          { __typename?: 'DraftJsInlineStyleRange' } & Pick<
            DraftJsInlineStyleRange,
            'offset' | 'length' | 'style'
          >
        >
        entityRanges: Array<
          { __typename?: 'DraftJsEntityRange' } & Pick<
            DraftJsEntityRange,
            'offset' | 'length' | 'key'
          >
        >
        data: { __typename?: 'DraftJsBlockData' } & Pick<DraftJsBlockData, 'id'>
      }
  >
  entityArray: Array<
    { __typename?: 'DraftJsEntity' } & Pick<
      DraftJsEntity,
      'type' | 'mutability'
    > & {
        data: { __typename?: 'DraftJsEntityLinkData' } & Pick<
          DraftJsEntityLinkData,
          'type' | 'url'
        >
      }
  >
}

export type ScreensProposalModificationUpdateProposalMutationVariables = {
  proposalId: Scalars['UUID']
  proposalData: ProposalDataInput
}

export type ScreensProposalModificationUpdateProposalMutation = {
  __typename?: 'Mutation'
} & { proposal: { __typename?: 'Proposal' } & Pick<Proposal, 'id'> }

export type ScreenViewProposalFetchProposalQueryVariables = {
  proposalId: Scalars['UUID']
}

export type ScreenViewProposalFetchProposalQuery = { __typename?: 'Query' } & {
  proposal: Maybe<
    { __typename?: 'Proposal' } & Pick<
      Proposal,
      'id' | 'name' | 'state' | 'stateReason' | 'actorCanAccept'
    > & {
        layouts: Array<
          { __typename?: 'ProposalLayout' } & Pick<
            ProposalLayout,
            'capacity' | 'name'
          >
        >
        totalCost: { __typename?: 'Price' } & Pick<
          Price,
          'amount' | 'currency' | 'vatIncluded'
        >
        costItems: Array<
          { __typename?: 'ProposalCostItem' } & Pick<
            ProposalCostItem,
            'id' | 'type' | 'description'
          >
        >
        availabilities: Array<
          { __typename?: 'ProposalAvailability' } & Pick<
            ProposalAvailability,
            'id' | 'date' | 'timings' | 'optionHold'
          >
        >
        images: Array<
          { __typename?: 'ProposalImage' } & Pick<
            ProposalImage,
            'id' | 'name' | 'caption' | 'URL'
          >
        >
        attachments: Array<
          { __typename?: 'File' } & Pick<
            File,
            'id' | 'name' | 'mimeType' | 'size' | 'URL'
          >
        >
        comments: Array<
          { __typename?: 'ProposalComment' } & Pick<
            ProposalComment,
            'id' | 'text' | 'createdAt' | 'updatedAt'
          > & {
              author: { __typename?: 'ProposalCommentAuthor' } & Pick<
                ProposalCommentAuthor,
                'fullName' | 'email' | 'mobileNumber' | 'avatarURL'
              > & {
                  user: Maybe<
                    { __typename?: 'HeadboxUser' } & Pick<HeadboxUser, 'id'> & {
                        company: Maybe<
                          { __typename?: 'HeadboxCompany' } & Pick<
                            HeadboxCompany,
                            'id' | 'name'
                          >
                        >
                      }
                  >
                }
            }
        >
        additionalNotes: {
          __typename?: 'DraftJsRawContentArrayForm'
        } & ScreenProposalFetchProposalDraftStateFragment
        deal: { __typename?: 'Deal' } & Pick<
          Deal,
          'id' | 'name' | 'stage' | 'decisionDeadline' | 'numberOfAttendees'
        > & {
            event: { __typename?: 'DealEvent' } & Pick<
              DealEvent,
              'name' | 'date' | 'productInterest' | 'type'
            > & {
                budget: Maybe<
                  { __typename?: 'Price' } & Pick<
                    Price,
                    'amount' | 'currency' | 'vatIncluded'
                  >
                >
              }
            proposals: Array<
              { __typename?: 'Proposal' } & Pick<Proposal, 'id' | 'state'>
            >
            headboxCompanyTeam: Maybe<
              { __typename?: 'HeadboxCompanyTeam' } & Pick<
                HeadboxCompanyTeam,
                'id'
              > & {
                  managers: Array<
                    { __typename?: 'HeadboxUser' } & Pick<HeadboxUser, 'id'>
                  >
                }
            >
            amount: Maybe<
              { __typename?: 'Price' } & Pick<
                Price,
                'amount' | 'currency' | 'vatIncluded'
              >
            >
          }
        space: { __typename?: 'ProposalSpace' } & Pick<
          ProposalSpace,
          | 'name'
          | 'marketplaceURL'
          | 'marketplaceId'
          | 'tour3dURL'
          | 'location'
          | 'fullAddress'
          | 'city'
          | 'hostEmail'
        > & {
            description: {
              __typename?: 'DraftJsRawContentArrayForm'
            } & ScreenProposalFetchProposalDraftStateFragment
            coordinates: Maybe<
              { __typename?: 'Coordinates' } & Pick<
                Coordinates,
                'latitude' | 'longitude'
              >
            >
          }
      }
  >
}

export type ScreenViewProposalFetchProposalDraftStateFragment = {
  __typename?: 'DraftJsRawContentArrayForm'
} & {
  blocks: Array<
    { __typename?: 'DraftJsBlock' } & Pick<
      DraftJsBlock,
      'key' | 'text' | 'depth' | 'type'
    > & {
        inlineStyleRanges: Array<
          { __typename?: 'DraftJsInlineStyleRange' } & Pick<
            DraftJsInlineStyleRange,
            'offset' | 'length' | 'style'
          >
        >
        entityRanges: Array<
          { __typename?: 'DraftJsEntityRange' } & Pick<
            DraftJsEntityRange,
            'offset' | 'length' | 'key'
          >
        >
        data: { __typename?: 'DraftJsBlockData' } & Pick<DraftJsBlockData, 'id'>
      }
  >
  entityArray: Array<
    { __typename?: 'DraftJsEntity' } & Pick<
      DraftJsEntity,
      'type' | 'mutability'
    > & {
        data: { __typename?: 'DraftJsEntityLinkData' } & Pick<
          DraftJsEntityLinkData,
          'type' | 'url'
        >
      }
  >
}
export const CreateProposalWizardFetchFullProposalDraftStateFragmentDoc = gql`
  fragment CreateProposalWizardFetchFullProposalDraftState on DraftJsRawContentArrayForm {
    blocks {
      key
      text
      depth
      type
      inlineStyleRanges {
        offset
        length
        style
      }
      entityRanges {
        offset
        length
        key
      }
      data {
        id
      }
    }
    entityArray {
      type
      mutability
      data {
        ... on DraftJsEntityLinkData {
          type
          url
        }
      }
    }
  }
`
export const summaryFieldsFragmentDoc = gql`
  fragment summaryFields on Deal {
    id
    name
    stage
    decisionDeadline
    numberOfAttendees
    headboxCompany {
      name
    }
    amount {
      amount
      currency
      vatIncluded
    }
    event {
      type
      date
      budget {
        amount
        currency
        vatIncluded
      }
      location
      seatingStyle
      length
      time
      flexibility
      facilities
      catering
      additionalRequirements
      productInterest
    }
    hubspotDealURL
  }
`
export const ScreensDealSharedFetchDealProposalDraftStateFragmentDoc = gql`
  fragment ScreensDealSharedFetchDealProposalDraftState on DraftJsRawContentArrayForm {
    blocks {
      key
      text
      depth
      type
      inlineStyleRanges {
        offset
        length
        style
      }
      entityRanges {
        offset
        length
        key
      }
      data {
        id
      }
    }
    entityArray {
      type
      mutability
      data {
        ... on DraftJsEntityLinkData {
          type
          url
        }
      }
    }
  }
`
export const ScreenProposalFetchProposalDraftStateFragmentDoc = gql`
  fragment ScreenProposalFetchProposalDraftState on DraftJsRawContentArrayForm {
    blocks {
      key
      text
      depth
      type
      inlineStyleRanges {
        offset
        length
        style
      }
      entityRanges {
        offset
        length
        key
      }
      data {
        id
      }
    }
    entityArray {
      type
      mutability
      data {
        ... on DraftJsEntityLinkData {
          type
          url
        }
      }
    }
  }
`
export const ScreenProposalModificationFetchProposalDraftStateFragmentDoc = gql`
  fragment ScreenProposalModificationFetchProposalDraftState on DraftJsRawContentArrayForm {
    blocks {
      key
      text
      depth
      type
      inlineStyleRanges {
        offset
        length
        style
      }
      entityRanges {
        offset
        length
        key
      }
      data {
        id
      }
    }
    entityArray {
      type
      mutability
      data {
        ... on DraftJsEntityLinkData {
          type
          url
        }
      }
    }
  }
`
export const ScreenViewProposalFetchProposalDraftStateFragmentDoc = gql`
  fragment ScreenViewProposalFetchProposalDraftState on DraftJsRawContentArrayForm {
    blocks {
      key
      text
      depth
      type
      inlineStyleRanges {
        offset
        length
        style
      }
      entityRanges {
        offset
        length
        key
      }
      data {
        id
      }
    }
    entityArray {
      type
      mutability
      data {
        ... on DraftJsEntityLinkData {
          type
          url
        }
      }
    }
  }
`
export const ContainersActorProviderDocument = gql`
  query ContainersActorProvider {
    actor: me {
      user {
        id
        fullName
        firstName
        lastName
        email
        avatarURL
        company {
          id
          name
          hubspotNewDealFormURL
        }
        spaces {
          id
          name
          venueName
        }
      }
      isAccountManager
      isBusinessAdministrator
      isBusinessManager
      isBusinessMember
      isHost
    }
  }
`
export type ContainersActorProviderComponentProps = Omit<
  ReactApollo.QueryProps<
    ContainersActorProviderQuery,
    ContainersActorProviderQueryVariables
  >,
  'query'
>

export const ContainersActorProviderComponent = (
  props: ContainersActorProviderComponentProps
) => (
  <ReactApollo.Query<
    ContainersActorProviderQuery,
    ContainersActorProviderQueryVariables
  >
    query={ContainersActorProviderDocument}
    {...props}
  />
)

export type ContainersActorProviderProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ContainersActorProviderQuery,
    ContainersActorProviderQueryVariables
  >
> &
  TChildProps
export function withContainersActorProvider<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ContainersActorProviderQuery,
    ContainersActorProviderQueryVariables,
    ContainersActorProviderProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ContainersActorProviderQuery,
    ContainersActorProviderQueryVariables,
    ContainersActorProviderProps<TChildProps>
  >(ContainersActorProviderDocument, {
    alias: 'withContainersActorProvider',
    ...operationOptions,
  })
}
export const CreateProposalWizardCreateFileDocument = gql`
  mutation CreateProposalWizardCreateFile($name: String!, $URL: String!) {
    file: createFileFromURL(name: $name, url: $URL) {
      id
      createdAt
      mimeType
      name
      size
      URL
    }
  }
`
export type CreateProposalWizardCreateFileMutationFn = ReactApollo.MutationFn<
  CreateProposalWizardCreateFileMutation,
  CreateProposalWizardCreateFileMutationVariables
>
export type CreateProposalWizardCreateFileComponentProps = Omit<
  ReactApollo.MutationProps<
    CreateProposalWizardCreateFileMutation,
    CreateProposalWizardCreateFileMutationVariables
  >,
  'mutation'
>

export const CreateProposalWizardCreateFileComponent = (
  props: CreateProposalWizardCreateFileComponentProps
) => (
  <ReactApollo.Mutation<
    CreateProposalWizardCreateFileMutation,
    CreateProposalWizardCreateFileMutationVariables
  >
    mutation={CreateProposalWizardCreateFileDocument}
    {...props}
  />
)

export type CreateProposalWizardCreateFileProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    CreateProposalWizardCreateFileMutation,
    CreateProposalWizardCreateFileMutationVariables
  >
> &
  TChildProps
export function withCreateProposalWizardCreateFile<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateProposalWizardCreateFileMutation,
    CreateProposalWizardCreateFileMutationVariables,
    CreateProposalWizardCreateFileProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateProposalWizardCreateFileMutation,
    CreateProposalWizardCreateFileMutationVariables,
    CreateProposalWizardCreateFileProps<TChildProps>
  >(CreateProposalWizardCreateFileDocument, {
    alias: 'withCreateProposalWizardCreateFile',
    ...operationOptions,
  })
}
export const CreateProposalWizardFetchFullProposalDocument = gql`
  query CreateProposalWizardFetchFullProposal($id: UUID!) {
    proposal: proposalById(id: $id) {
      id
      name
      state
      deal {
        id
        name
        stage
        decisionDeadline
        amount {
          amount
          vatIncluded
          currency
        }
        event {
          name
          type
          date
          budget {
            amount
            vatIncluded
            currency
          }
        }
      }
      space {
        name
        hostEmail
        description {
          ...CreateProposalWizardFetchFullProposalDraftState
        }
        marketplaceURL
        marketplaceId
        tour3dURL
        location
        fullAddress
        city
        coordinates {
          latitude
          longitude
        }
      }
      layouts {
        capacity
        name
      }
      totalCost {
        amount
        currency
        vatIncluded
      }
      images {
        id
        name
        caption
        URL
        createdAt
        mimeType
        size
      }
      attachments {
        id
        name
        mimeType
        size
        URL
      }
      availabilities {
        id
        date
        optionHold
        timings
      }
      costItems {
        id
        type
        description
      }
      additionalNotes {
        ...CreateProposalWizardFetchFullProposalDraftState
      }
    }
  }
  ${CreateProposalWizardFetchFullProposalDraftStateFragmentDoc}
`
export type CreateProposalWizardFetchFullProposalComponentProps = Omit<
  ReactApollo.QueryProps<
    CreateProposalWizardFetchFullProposalQuery,
    CreateProposalWizardFetchFullProposalQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: CreateProposalWizardFetchFullProposalQueryVariables
        skip?: false
      }
    | { skip: true })

export const CreateProposalWizardFetchFullProposalComponent = (
  props: CreateProposalWizardFetchFullProposalComponentProps
) => (
  <ReactApollo.Query<
    CreateProposalWizardFetchFullProposalQuery,
    CreateProposalWizardFetchFullProposalQueryVariables
  >
    query={CreateProposalWizardFetchFullProposalDocument}
    {...props}
  />
)

export type CreateProposalWizardFetchFullProposalProps<
  TChildProps = {}
> = Partial<
  ReactApollo.DataProps<
    CreateProposalWizardFetchFullProposalQuery,
    CreateProposalWizardFetchFullProposalQueryVariables
  >
> &
  TChildProps
export function withCreateProposalWizardFetchFullProposal<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateProposalWizardFetchFullProposalQuery,
    CreateProposalWizardFetchFullProposalQueryVariables,
    CreateProposalWizardFetchFullProposalProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    CreateProposalWizardFetchFullProposalQuery,
    CreateProposalWizardFetchFullProposalQueryVariables,
    CreateProposalWizardFetchFullProposalProps<TChildProps>
  >(CreateProposalWizardFetchFullProposalDocument, {
    alias: 'withCreateProposalWizardFetchFullProposal',
    ...operationOptions,
  })
}
export const CreateProposalWizardFetchHeadboxSpaceDocument = gql`
  query CreateProposalWizardFetchHeadboxSpace($spaceId: Int!) {
    space: headboxSpaceById(id: $spaceId) {
      id
      name
      venueName
      description
      host {
        email
      }
      fullAddress
      location
      city
      maxCapacity
      marketplaceURL
      tour3dURL
      photos {
        name
        URL
      }
      layouts {
        capacity
        layout
      }
    }
  }
`
export type CreateProposalWizardFetchHeadboxSpaceComponentProps = Omit<
  ReactApollo.QueryProps<
    CreateProposalWizardFetchHeadboxSpaceQuery,
    CreateProposalWizardFetchHeadboxSpaceQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: CreateProposalWizardFetchHeadboxSpaceQueryVariables
        skip?: false
      }
    | { skip: true })

export const CreateProposalWizardFetchHeadboxSpaceComponent = (
  props: CreateProposalWizardFetchHeadboxSpaceComponentProps
) => (
  <ReactApollo.Query<
    CreateProposalWizardFetchHeadboxSpaceQuery,
    CreateProposalWizardFetchHeadboxSpaceQueryVariables
  >
    query={CreateProposalWizardFetchHeadboxSpaceDocument}
    {...props}
  />
)

export type CreateProposalWizardFetchHeadboxSpaceProps<
  TChildProps = {}
> = Partial<
  ReactApollo.DataProps<
    CreateProposalWizardFetchHeadboxSpaceQuery,
    CreateProposalWizardFetchHeadboxSpaceQueryVariables
  >
> &
  TChildProps
export function withCreateProposalWizardFetchHeadboxSpace<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateProposalWizardFetchHeadboxSpaceQuery,
    CreateProposalWizardFetchHeadboxSpaceQueryVariables,
    CreateProposalWizardFetchHeadboxSpaceProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    CreateProposalWizardFetchHeadboxSpaceQuery,
    CreateProposalWizardFetchHeadboxSpaceQueryVariables,
    CreateProposalWizardFetchHeadboxSpaceProps<TChildProps>
  >(CreateProposalWizardFetchHeadboxSpaceDocument, {
    alias: 'withCreateProposalWizardFetchHeadboxSpace',
    ...operationOptions,
  })
}
export const CreateProposalWizardGetProposalsBySpaceIdDocument = gql`
  query CreateProposalWizardGetProposalsBySpaceId($id: Int!) {
    proposals: proposalsByHeadboxSpaceId(id: $id) {
      id
      createdAt
      deletedAt
      deal {
        id
        headboxCompany {
          id
          name
        }
        event {
          type
          date
        }
      }
      totalCost {
        amount
        vatIncluded
        currency
      }
    }
  }
`
export type CreateProposalWizardGetProposalsBySpaceIdComponentProps = Omit<
  ReactApollo.QueryProps<
    CreateProposalWizardGetProposalsBySpaceIdQuery,
    CreateProposalWizardGetProposalsBySpaceIdQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: CreateProposalWizardGetProposalsBySpaceIdQueryVariables
        skip?: false
      }
    | { skip: true })

export const CreateProposalWizardGetProposalsBySpaceIdComponent = (
  props: CreateProposalWizardGetProposalsBySpaceIdComponentProps
) => (
  <ReactApollo.Query<
    CreateProposalWizardGetProposalsBySpaceIdQuery,
    CreateProposalWizardGetProposalsBySpaceIdQueryVariables
  >
    query={CreateProposalWizardGetProposalsBySpaceIdDocument}
    {...props}
  />
)

export type CreateProposalWizardGetProposalsBySpaceIdProps<
  TChildProps = {}
> = Partial<
  ReactApollo.DataProps<
    CreateProposalWizardGetProposalsBySpaceIdQuery,
    CreateProposalWizardGetProposalsBySpaceIdQueryVariables
  >
> &
  TChildProps
export function withCreateProposalWizardGetProposalsBySpaceId<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateProposalWizardGetProposalsBySpaceIdQuery,
    CreateProposalWizardGetProposalsBySpaceIdQueryVariables,
    CreateProposalWizardGetProposalsBySpaceIdProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    CreateProposalWizardGetProposalsBySpaceIdQuery,
    CreateProposalWizardGetProposalsBySpaceIdQueryVariables,
    CreateProposalWizardGetProposalsBySpaceIdProps<TChildProps>
  >(CreateProposalWizardGetProposalsBySpaceIdDocument, {
    alias: 'withCreateProposalWizardGetProposalsBySpaceId',
    ...operationOptions,
  })
}
export const ContainersRuntimeVariablesProviderDocument = gql`
  query ContainersRuntimeVariablesProvider {
    runtimeVariables {
      createDealFormURL
    }
  }
`
export type ContainersRuntimeVariablesProviderComponentProps = Omit<
  ReactApollo.QueryProps<
    ContainersRuntimeVariablesProviderQuery,
    ContainersRuntimeVariablesProviderQueryVariables
  >,
  'query'
>

export const ContainersRuntimeVariablesProviderComponent = (
  props: ContainersRuntimeVariablesProviderComponentProps
) => (
  <ReactApollo.Query<
    ContainersRuntimeVariablesProviderQuery,
    ContainersRuntimeVariablesProviderQueryVariables
  >
    query={ContainersRuntimeVariablesProviderDocument}
    {...props}
  />
)

export type ContainersRuntimeVariablesProviderProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ContainersRuntimeVariablesProviderQuery,
    ContainersRuntimeVariablesProviderQueryVariables
  >
> &
  TChildProps
export function withContainersRuntimeVariablesProvider<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ContainersRuntimeVariablesProviderQuery,
    ContainersRuntimeVariablesProviderQueryVariables,
    ContainersRuntimeVariablesProviderProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ContainersRuntimeVariablesProviderQuery,
    ContainersRuntimeVariablesProviderQueryVariables,
    ContainersRuntimeVariablesProviderProps<TChildProps>
  >(ContainersRuntimeVariablesProviderDocument, {
    alias: 'withContainersRuntimeVariablesProvider',
    ...operationOptions,
  })
}
export const UploadAreaCreateFileDocument = gql`
  mutation UploadAreaCreateFile($name: String!, $URL: String!) {
    file: createFileFromURL(name: $name, url: $URL) {
      id
      createdAt
      mimeType
      name
      size
      URL
    }
  }
`
export type UploadAreaCreateFileMutationFn = ReactApollo.MutationFn<
  UploadAreaCreateFileMutation,
  UploadAreaCreateFileMutationVariables
>
export type UploadAreaCreateFileComponentProps = Omit<
  ReactApollo.MutationProps<
    UploadAreaCreateFileMutation,
    UploadAreaCreateFileMutationVariables
  >,
  'mutation'
>

export const UploadAreaCreateFileComponent = (
  props: UploadAreaCreateFileComponentProps
) => (
  <ReactApollo.Mutation<
    UploadAreaCreateFileMutation,
    UploadAreaCreateFileMutationVariables
  >
    mutation={UploadAreaCreateFileDocument}
    {...props}
  />
)

export type UploadAreaCreateFileProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    UploadAreaCreateFileMutation,
    UploadAreaCreateFileMutationVariables
  >
> &
  TChildProps
export function withUploadAreaCreateFile<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UploadAreaCreateFileMutation,
    UploadAreaCreateFileMutationVariables,
    UploadAreaCreateFileProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UploadAreaCreateFileMutation,
    UploadAreaCreateFileMutationVariables,
    UploadAreaCreateFileProps<TChildProps>
  >(UploadAreaCreateFileDocument, {
    alias: 'withUploadAreaCreateFile',
    ...operationOptions,
  })
}
export const UploadAreaGetCoordinatesForFileUploadDocument = gql`
  mutation UploadAreaGetCoordinatesForFileUpload {
    coordinatesForFileUpload: getCoordinatesForFileUpload {
      signedURL
    }
  }
`
export type UploadAreaGetCoordinatesForFileUploadMutationFn = ReactApollo.MutationFn<
  UploadAreaGetCoordinatesForFileUploadMutation,
  UploadAreaGetCoordinatesForFileUploadMutationVariables
>
export type UploadAreaGetCoordinatesForFileUploadComponentProps = Omit<
  ReactApollo.MutationProps<
    UploadAreaGetCoordinatesForFileUploadMutation,
    UploadAreaGetCoordinatesForFileUploadMutationVariables
  >,
  'mutation'
>

export const UploadAreaGetCoordinatesForFileUploadComponent = (
  props: UploadAreaGetCoordinatesForFileUploadComponentProps
) => (
  <ReactApollo.Mutation<
    UploadAreaGetCoordinatesForFileUploadMutation,
    UploadAreaGetCoordinatesForFileUploadMutationVariables
  >
    mutation={UploadAreaGetCoordinatesForFileUploadDocument}
    {...props}
  />
)

export type UploadAreaGetCoordinatesForFileUploadProps<
  TChildProps = {}
> = Partial<
  ReactApollo.MutateProps<
    UploadAreaGetCoordinatesForFileUploadMutation,
    UploadAreaGetCoordinatesForFileUploadMutationVariables
  >
> &
  TChildProps
export function withUploadAreaGetCoordinatesForFileUpload<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UploadAreaGetCoordinatesForFileUploadMutation,
    UploadAreaGetCoordinatesForFileUploadMutationVariables,
    UploadAreaGetCoordinatesForFileUploadProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UploadAreaGetCoordinatesForFileUploadMutation,
    UploadAreaGetCoordinatesForFileUploadMutationVariables,
    UploadAreaGetCoordinatesForFileUploadProps<TChildProps>
  >(UploadAreaGetCoordinatesForFileUploadDocument, {
    alias: 'withUploadAreaGetCoordinatesForFileUpload',
    ...operationOptions,
  })
}
export const ScreensDealAddCommentToProposalDocument = gql`
  mutation ScreensDealAddCommentToProposal(
    $proposalId: UUID!
    $commentData: ProposalCommentInput!
  ) {
    comment: addCommentToProposal(
      proposalId: $proposalId
      commentData: $commentData
    ) {
      id
      proposal {
        id
        comments {
          id
          text
          createdAt
          updatedAt
          author {
            fullName
            email
            mobileNumber
            avatarURL
            user {
              id
              company {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
export type ScreensDealAddCommentToProposalMutationFn = ReactApollo.MutationFn<
  ScreensDealAddCommentToProposalMutation,
  ScreensDealAddCommentToProposalMutationVariables
>
export type ScreensDealAddCommentToProposalComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensDealAddCommentToProposalMutation,
    ScreensDealAddCommentToProposalMutationVariables
  >,
  'mutation'
>

export const ScreensDealAddCommentToProposalComponent = (
  props: ScreensDealAddCommentToProposalComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensDealAddCommentToProposalMutation,
    ScreensDealAddCommentToProposalMutationVariables
  >
    mutation={ScreensDealAddCommentToProposalDocument}
    {...props}
  />
)

export type ScreensDealAddCommentToProposalProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ScreensDealAddCommentToProposalMutation,
    ScreensDealAddCommentToProposalMutationVariables
  >
> &
  TChildProps
export function withScreensDealAddCommentToProposal<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealAddCommentToProposalMutation,
    ScreensDealAddCommentToProposalMutationVariables,
    ScreensDealAddCommentToProposalProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensDealAddCommentToProposalMutation,
    ScreensDealAddCommentToProposalMutationVariables,
    ScreensDealAddCommentToProposalProps<TChildProps>
  >(ScreensDealAddCommentToProposalDocument, {
    alias: 'withScreensDealAddCommentToProposal',
    ...operationOptions,
  })
}
export const ScreensDealAssignDealToHeadboxCompanyTeamDocument = gql`
  mutation ScreensDealAssignDealToHeadboxCompanyTeam(
    $dealId: UUID!
    $teamId: Int
  ) {
    assignDealToHeadboxCompanyTeam(
      dealId: $dealId
      headboxCompanyTeamId: $teamId
    ) {
      id
      headboxCompanyTeam {
        id
        name
        managers {
          id
        }
      }
    }
  }
`
export type ScreensDealAssignDealToHeadboxCompanyTeamMutationFn = ReactApollo.MutationFn<
  ScreensDealAssignDealToHeadboxCompanyTeamMutation,
  ScreensDealAssignDealToHeadboxCompanyTeamMutationVariables
>
export type ScreensDealAssignDealToHeadboxCompanyTeamComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensDealAssignDealToHeadboxCompanyTeamMutation,
    ScreensDealAssignDealToHeadboxCompanyTeamMutationVariables
  >,
  'mutation'
>

export const ScreensDealAssignDealToHeadboxCompanyTeamComponent = (
  props: ScreensDealAssignDealToHeadboxCompanyTeamComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensDealAssignDealToHeadboxCompanyTeamMutation,
    ScreensDealAssignDealToHeadboxCompanyTeamMutationVariables
  >
    mutation={ScreensDealAssignDealToHeadboxCompanyTeamDocument}
    {...props}
  />
)

export type ScreensDealAssignDealToHeadboxCompanyTeamProps<
  TChildProps = {}
> = Partial<
  ReactApollo.MutateProps<
    ScreensDealAssignDealToHeadboxCompanyTeamMutation,
    ScreensDealAssignDealToHeadboxCompanyTeamMutationVariables
  >
> &
  TChildProps
export function withScreensDealAssignDealToHeadboxCompanyTeam<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealAssignDealToHeadboxCompanyTeamMutation,
    ScreensDealAssignDealToHeadboxCompanyTeamMutationVariables,
    ScreensDealAssignDealToHeadboxCompanyTeamProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensDealAssignDealToHeadboxCompanyTeamMutation,
    ScreensDealAssignDealToHeadboxCompanyTeamMutationVariables,
    ScreensDealAssignDealToHeadboxCompanyTeamProps<TChildProps>
  >(ScreensDealAssignDealToHeadboxCompanyTeamDocument, {
    alias: 'withScreensDealAssignDealToHeadboxCompanyTeam',
    ...operationOptions,
  })
}
export const ScreensDealCloneProposalsDocument = gql`
  mutation ScreensDealCloneProposals(
    $targetDealId: UUID!
    $proposalsIds: [UUID!]!
  ) {
    clonedProposals: cloneProposals(
      targetDealId: $targetDealId
      proposalsIds: $proposalsIds
    ) {
      id
    }
  }
`
export type ScreensDealCloneProposalsMutationFn = ReactApollo.MutationFn<
  ScreensDealCloneProposalsMutation,
  ScreensDealCloneProposalsMutationVariables
>
export type ScreensDealCloneProposalsComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensDealCloneProposalsMutation,
    ScreensDealCloneProposalsMutationVariables
  >,
  'mutation'
>

export const ScreensDealCloneProposalsComponent = (
  props: ScreensDealCloneProposalsComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensDealCloneProposalsMutation,
    ScreensDealCloneProposalsMutationVariables
  >
    mutation={ScreensDealCloneProposalsDocument}
    {...props}
  />
)

export type ScreensDealCloneProposalsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ScreensDealCloneProposalsMutation,
    ScreensDealCloneProposalsMutationVariables
  >
> &
  TChildProps
export function withScreensDealCloneProposals<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealCloneProposalsMutation,
    ScreensDealCloneProposalsMutationVariables,
    ScreensDealCloneProposalsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensDealCloneProposalsMutation,
    ScreensDealCloneProposalsMutationVariables,
    ScreensDealCloneProposalsProps<TChildProps>
  >(ScreensDealCloneProposalsDocument, {
    alias: 'withScreensDealCloneProposals',
    ...operationOptions,
  })
}
export const ScreensDealCloneProposalsFetchDealDocument = gql`
  query ScreensDealCloneProposalsFetchDeal($dealId: UUID!) {
    targetDeal: dealById(id: $dealId) {
      id
      name
      amount {
        amount
        currency
        vatIncluded
      }
      decisionDeadline
      event {
        name
        brief
        productInterest
        date
        budget {
          amount
          currency
          vatIncluded
        }
      }
      stage
      proposals {
        id
        deletedAt
        totalCost {
          amount
          currency
          vatIncluded
        }
        space {
          name
          location
        }
      }
    }
  }
`
export type ScreensDealCloneProposalsFetchDealComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreensDealCloneProposalsFetchDealQuery,
    ScreensDealCloneProposalsFetchDealQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: ScreensDealCloneProposalsFetchDealQueryVariables
        skip?: false
      }
    | { skip: true })

export const ScreensDealCloneProposalsFetchDealComponent = (
  props: ScreensDealCloneProposalsFetchDealComponentProps
) => (
  <ReactApollo.Query<
    ScreensDealCloneProposalsFetchDealQuery,
    ScreensDealCloneProposalsFetchDealQueryVariables
  >
    query={ScreensDealCloneProposalsFetchDealDocument}
    {...props}
  />
)

export type ScreensDealCloneProposalsFetchDealProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ScreensDealCloneProposalsFetchDealQuery,
    ScreensDealCloneProposalsFetchDealQueryVariables
  >
> &
  TChildProps
export function withScreensDealCloneProposalsFetchDeal<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealCloneProposalsFetchDealQuery,
    ScreensDealCloneProposalsFetchDealQueryVariables,
    ScreensDealCloneProposalsFetchDealProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreensDealCloneProposalsFetchDealQuery,
    ScreensDealCloneProposalsFetchDealQueryVariables,
    ScreensDealCloneProposalsFetchDealProps<TChildProps>
  >(ScreensDealCloneProposalsFetchDealDocument, {
    alias: 'withScreensDealCloneProposalsFetchDeal',
    ...operationOptions,
  })
}
export const ScreensDealCreateHostInvitationDocument = gql`
  mutation ScreensDealCreateHostInvitation(
    $dealId: UUID!
    $headboxHostIdentity: Int!
    $customMessage: String!
  ) {
    createHostInvitationToDeal(
      dealId: $dealId
      headboxHostIdentity: $headboxHostIdentity
      customMessage: $customMessage
    ) {
      id
      hostInvitations {
        id
        createdBy {
          id
        }
        invitee {
          id
          fullName
          avatarURL
        }
      }
    }
  }
`
export type ScreensDealCreateHostInvitationMutationFn = ReactApollo.MutationFn<
  ScreensDealCreateHostInvitationMutation,
  ScreensDealCreateHostInvitationMutationVariables
>
export type ScreensDealCreateHostInvitationComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensDealCreateHostInvitationMutation,
    ScreensDealCreateHostInvitationMutationVariables
  >,
  'mutation'
>

export const ScreensDealCreateHostInvitationComponent = (
  props: ScreensDealCreateHostInvitationComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensDealCreateHostInvitationMutation,
    ScreensDealCreateHostInvitationMutationVariables
  >
    mutation={ScreensDealCreateHostInvitationDocument}
    {...props}
  />
)

export type ScreensDealCreateHostInvitationProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ScreensDealCreateHostInvitationMutation,
    ScreensDealCreateHostInvitationMutationVariables
  >
> &
  TChildProps
export function withScreensDealCreateHostInvitation<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealCreateHostInvitationMutation,
    ScreensDealCreateHostInvitationMutationVariables,
    ScreensDealCreateHostInvitationProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensDealCreateHostInvitationMutation,
    ScreensDealCreateHostInvitationMutationVariables,
    ScreensDealCreateHostInvitationProps<TChildProps>
  >(ScreensDealCreateHostInvitationDocument, {
    alias: 'withScreensDealCreateHostInvitation',
    ...operationOptions,
  })
}
export const ScreensDealDeleteProposalDocument = gql`
  mutation ScreensDealDeleteProposal($proposalId: UUID!) {
    proposalRemoved: removeProposal(proposalId: $proposalId)
  }
`
export type ScreensDealDeleteProposalMutationFn = ReactApollo.MutationFn<
  ScreensDealDeleteProposalMutation,
  ScreensDealDeleteProposalMutationVariables
>
export type ScreensDealDeleteProposalComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensDealDeleteProposalMutation,
    ScreensDealDeleteProposalMutationVariables
  >,
  'mutation'
>

export const ScreensDealDeleteProposalComponent = (
  props: ScreensDealDeleteProposalComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensDealDeleteProposalMutation,
    ScreensDealDeleteProposalMutationVariables
  >
    mutation={ScreensDealDeleteProposalDocument}
    {...props}
  />
)

export type ScreensDealDeleteProposalProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ScreensDealDeleteProposalMutation,
    ScreensDealDeleteProposalMutationVariables
  >
> &
  TChildProps
export function withScreensDealDeleteProposal<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealDeleteProposalMutation,
    ScreensDealDeleteProposalMutationVariables,
    ScreensDealDeleteProposalProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensDealDeleteProposalMutation,
    ScreensDealDeleteProposalMutationVariables,
    ScreensDealDeleteProposalProps<TChildProps>
  >(ScreensDealDeleteProposalDocument, {
    alias: 'withScreensDealDeleteProposal',
    ...operationOptions,
  })
}
export const ScreensDealDesignateDealPrimaryContactDocument = gql`
  mutation ScreensDealDesignateDealPrimaryContact(
    $dealId: UUID!
    $headboxUserId: Int
  ) {
    designateDealPrimaryContact(
      dealId: $dealId
      headboxUserIdentity: $headboxUserId
    ) {
      id
      primaryContact {
        ... on HeadboxUser {
          id
          fullName
          firstName
          lastName
          email
          phoneNumber
        }
        ... on DealPrimaryContactProps {
          fullName
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`
export type ScreensDealDesignateDealPrimaryContactMutationFn = ReactApollo.MutationFn<
  ScreensDealDesignateDealPrimaryContactMutation,
  ScreensDealDesignateDealPrimaryContactMutationVariables
>
export type ScreensDealDesignateDealPrimaryContactComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensDealDesignateDealPrimaryContactMutation,
    ScreensDealDesignateDealPrimaryContactMutationVariables
  >,
  'mutation'
>

export const ScreensDealDesignateDealPrimaryContactComponent = (
  props: ScreensDealDesignateDealPrimaryContactComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensDealDesignateDealPrimaryContactMutation,
    ScreensDealDesignateDealPrimaryContactMutationVariables
  >
    mutation={ScreensDealDesignateDealPrimaryContactDocument}
    {...props}
  />
)

export type ScreensDealDesignateDealPrimaryContactProps<
  TChildProps = {}
> = Partial<
  ReactApollo.MutateProps<
    ScreensDealDesignateDealPrimaryContactMutation,
    ScreensDealDesignateDealPrimaryContactMutationVariables
  >
> &
  TChildProps
export function withScreensDealDesignateDealPrimaryContact<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealDesignateDealPrimaryContactMutation,
    ScreensDealDesignateDealPrimaryContactMutationVariables,
    ScreensDealDesignateDealPrimaryContactProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensDealDesignateDealPrimaryContactMutation,
    ScreensDealDesignateDealPrimaryContactMutationVariables,
    ScreensDealDesignateDealPrimaryContactProps<TChildProps>
  >(ScreensDealDesignateDealPrimaryContactDocument, {
    alias: 'withScreensDealDesignateDealPrimaryContact',
    ...operationOptions,
  })
}
export const ScreensDealFetchDealDocument = gql`
  query ScreensDealFetchDeal($dealId: UUID!) {
    deal: dealById(id: $dealId) {
      id
      publicAccessToken
      numberOfAttendees
      name
      event {
        name
        brief
        type
        date
        budget {
          amount
          currency
          vatIncluded
        }
      }
      stage
      decisionDeadline
      headboxCompany {
        id
        name
        logoURL
        members {
          id
          fullName
          avatarURL
        }
        teams {
          id
          name
        }
      }
      headboxCompanyTeam {
        id
        name
        managers {
          id
        }
      }
      headboxServiceFee
      bookedSpace
      hubspotDealURL
      primaryContact {
        ... on HeadboxUser {
          id
          fullName
          firstName
          lastName
          email
          phoneNumber
        }
        ... on DealPrimaryContactProps {
          fullName
          firstName
          lastName
          email
          phone
        }
      }
      ownerAccountManager {
        id
        fullName
        firstName
        lastName
        email
        avatarURL
      }
      juniorAccountManager {
        id
        fullName
        firstName
        lastName
        email
        avatarURL
      }
      hostInvitations {
        id
        createdAt
        createdBy {
          id
          fullName
        }
        invitee {
          id
          fullName
          avatarURL
        }
      }
      actorCanAssignToACompamyTeam
      actorCanShareByEmail
      actorCanDesignateAPrimaryContact
      actorCanCreateProposal
      amount {
        amount
        currency
        vatIncluded
      }
      proposals {
        id
        name
        state
        stateReason
        deletedAt
        space {
          name
          location
          city
          marketplaceURL
          marketplaceId
          hostEmail
          coordinates {
            latitude
            longitude
          }
        }
        layouts {
          capacity
          name
        }
        totalCost {
          amount
          currency
          vatIncluded
        }
        images {
          id
          name
          caption
          URL
        }
        availabilities {
          id
          date
          optionHold
          timings
        }
        costItems {
          id
          type
          description
        }
        comments {
          id
          text
          createdAt
          updatedAt
          author {
            fullName
            email
            mobileNumber
            avatarURL
            user {
              id
              company {
                id
                name
              }
            }
          }
        }
        actorCanAccept
        actorCanReject
        actorCanSetAsPending
      }
      ...summaryFields
    }
  }
  ${summaryFieldsFragmentDoc}
`
export type ScreensDealFetchDealComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreensDealFetchDealQuery,
    ScreensDealFetchDealQueryVariables
  >,
  'query'
> &
  (
    | { variables: ScreensDealFetchDealQueryVariables; skip?: false }
    | { skip: true })

export const ScreensDealFetchDealComponent = (
  props: ScreensDealFetchDealComponentProps
) => (
  <ReactApollo.Query<
    ScreensDealFetchDealQuery,
    ScreensDealFetchDealQueryVariables
  >
    query={ScreensDealFetchDealDocument}
    {...props}
  />
)

export type ScreensDealFetchDealProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ScreensDealFetchDealQuery,
    ScreensDealFetchDealQueryVariables
  >
> &
  TChildProps
export function withScreensDealFetchDeal<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealFetchDealQuery,
    ScreensDealFetchDealQueryVariables,
    ScreensDealFetchDealProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreensDealFetchDealQuery,
    ScreensDealFetchDealQueryVariables,
    ScreensDealFetchDealProps<TChildProps>
  >(ScreensDealFetchDealDocument, {
    alias: 'withScreensDealFetchDeal',
    ...operationOptions,
  })
}
export const ScreensDealDeleteHostInvitationDocument = gql`
  mutation ScreensDealDeleteHostInvitation($invitationIdentity: UUID!) {
    deleteHostInvitationToDeal(invitationIdentity: $invitationIdentity) {
      id
      hostInvitations {
        id
        invitee {
          id
          fullName
        }
      }
    }
  }
`
export type ScreensDealDeleteHostInvitationMutationFn = ReactApollo.MutationFn<
  ScreensDealDeleteHostInvitationMutation,
  ScreensDealDeleteHostInvitationMutationVariables
>
export type ScreensDealDeleteHostInvitationComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensDealDeleteHostInvitationMutation,
    ScreensDealDeleteHostInvitationMutationVariables
  >,
  'mutation'
>

export const ScreensDealDeleteHostInvitationComponent = (
  props: ScreensDealDeleteHostInvitationComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensDealDeleteHostInvitationMutation,
    ScreensDealDeleteHostInvitationMutationVariables
  >
    mutation={ScreensDealDeleteHostInvitationDocument}
    {...props}
  />
)

export type ScreensDealDeleteHostInvitationProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ScreensDealDeleteHostInvitationMutation,
    ScreensDealDeleteHostInvitationMutationVariables
  >
> &
  TChildProps
export function withScreensDealDeleteHostInvitation<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealDeleteHostInvitationMutation,
    ScreensDealDeleteHostInvitationMutationVariables,
    ScreensDealDeleteHostInvitationProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensDealDeleteHostInvitationMutation,
    ScreensDealDeleteHostInvitationMutationVariables,
    ScreensDealDeleteHostInvitationProps<TChildProps>
  >(ScreensDealDeleteHostInvitationDocument, {
    alias: 'withScreensDealDeleteHostInvitation',
    ...operationOptions,
  })
}
export const ScreensDealFetchHostByEmailDocument = gql`
  query ScreensDealFetchHostByEmail($email: String!) {
    headboxUserByEmail(email: $email) {
      id
      avatarURL
      email
      fullName
    }
  }
`
export type ScreensDealFetchHostByEmailComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreensDealFetchHostByEmailQuery,
    ScreensDealFetchHostByEmailQueryVariables
  >,
  'query'
> &
  (
    | { variables: ScreensDealFetchHostByEmailQueryVariables; skip?: false }
    | { skip: true })

export const ScreensDealFetchHostByEmailComponent = (
  props: ScreensDealFetchHostByEmailComponentProps
) => (
  <ReactApollo.Query<
    ScreensDealFetchHostByEmailQuery,
    ScreensDealFetchHostByEmailQueryVariables
  >
    query={ScreensDealFetchHostByEmailDocument}
    {...props}
  />
)

export type ScreensDealFetchHostByEmailProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ScreensDealFetchHostByEmailQuery,
    ScreensDealFetchHostByEmailQueryVariables
  >
> &
  TChildProps
export function withScreensDealFetchHostByEmail<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealFetchHostByEmailQuery,
    ScreensDealFetchHostByEmailQueryVariables,
    ScreensDealFetchHostByEmailProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreensDealFetchHostByEmailQuery,
    ScreensDealFetchHostByEmailQueryVariables,
    ScreensDealFetchHostByEmailProps<TChildProps>
  >(ScreensDealFetchHostByEmailDocument, {
    alias: 'withScreensDealFetchHostByEmail',
    ...operationOptions,
  })
}
export const ScreensDealFetchHostFromSpaceDocument = gql`
  query ScreensDealFetchHostFromSpace($id: Int!) {
    headboxSpaceById(id: $id) {
      id
      host {
        id
        fullName
        email
        avatarURL
      }
    }
  }
`
export type ScreensDealFetchHostFromSpaceComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreensDealFetchHostFromSpaceQuery,
    ScreensDealFetchHostFromSpaceQueryVariables
  >,
  'query'
> &
  (
    | { variables: ScreensDealFetchHostFromSpaceQueryVariables; skip?: false }
    | { skip: true })

export const ScreensDealFetchHostFromSpaceComponent = (
  props: ScreensDealFetchHostFromSpaceComponentProps
) => (
  <ReactApollo.Query<
    ScreensDealFetchHostFromSpaceQuery,
    ScreensDealFetchHostFromSpaceQueryVariables
  >
    query={ScreensDealFetchHostFromSpaceDocument}
    {...props}
  />
)

export type ScreensDealFetchHostFromSpaceProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ScreensDealFetchHostFromSpaceQuery,
    ScreensDealFetchHostFromSpaceQueryVariables
  >
> &
  TChildProps
export function withScreensDealFetchHostFromSpace<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealFetchHostFromSpaceQuery,
    ScreensDealFetchHostFromSpaceQueryVariables,
    ScreensDealFetchHostFromSpaceProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreensDealFetchHostFromSpaceQuery,
    ScreensDealFetchHostFromSpaceQueryVariables,
    ScreensDealFetchHostFromSpaceProps<TChildProps>
  >(ScreensDealFetchHostFromSpaceDocument, {
    alias: 'withScreensDealFetchHostFromSpace',
    ...operationOptions,
  })
}
export const ScreensDealReorderProposalsDocument = gql`
  mutation ScreensDealReorderProposals($dealId: UUID!, $proposalIds: [UUID!]!) {
    reorderProposals(dealId: $dealId, proposalIds: $proposalIds) {
      id
      proposals {
        id
      }
    }
  }
`
export type ScreensDealReorderProposalsMutationFn = ReactApollo.MutationFn<
  ScreensDealReorderProposalsMutation,
  ScreensDealReorderProposalsMutationVariables
>
export type ScreensDealReorderProposalsComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensDealReorderProposalsMutation,
    ScreensDealReorderProposalsMutationVariables
  >,
  'mutation'
>

export const ScreensDealReorderProposalsComponent = (
  props: ScreensDealReorderProposalsComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensDealReorderProposalsMutation,
    ScreensDealReorderProposalsMutationVariables
  >
    mutation={ScreensDealReorderProposalsDocument}
    {...props}
  />
)

export type ScreensDealReorderProposalsProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ScreensDealReorderProposalsMutation,
    ScreensDealReorderProposalsMutationVariables
  >
> &
  TChildProps
export function withScreensDealReorderProposals<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealReorderProposalsMutation,
    ScreensDealReorderProposalsMutationVariables,
    ScreensDealReorderProposalsProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensDealReorderProposalsMutation,
    ScreensDealReorderProposalsMutationVariables,
    ScreensDealReorderProposalsProps<TChildProps>
  >(ScreensDealReorderProposalsDocument, {
    alias: 'withScreensDealReorderProposals',
    ...operationOptions,
  })
}
export const ScreensDealShareDealUrlByEmailDocument = gql`
  mutation ScreensDealShareDealURLByEmail(
    $dealId: UUID!
    $recipients: [String!]!
    $customMessage: String
  ) {
    dealURLShared: shareDealURLByEmail(
      dealId: $dealId
      recipients: $recipients
      customMessage: $customMessage
    )
  }
`
export type ScreensDealShareDealUrlByEmailMutationFn = ReactApollo.MutationFn<
  ScreensDealShareDealUrlByEmailMutation,
  ScreensDealShareDealUrlByEmailMutationVariables
>
export type ScreensDealShareDealUrlByEmailComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensDealShareDealUrlByEmailMutation,
    ScreensDealShareDealUrlByEmailMutationVariables
  >,
  'mutation'
>

export const ScreensDealShareDealUrlByEmailComponent = (
  props: ScreensDealShareDealUrlByEmailComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensDealShareDealUrlByEmailMutation,
    ScreensDealShareDealUrlByEmailMutationVariables
  >
    mutation={ScreensDealShareDealUrlByEmailDocument}
    {...props}
  />
)

export type ScreensDealShareDealUrlByEmailProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ScreensDealShareDealUrlByEmailMutation,
    ScreensDealShareDealUrlByEmailMutationVariables
  >
> &
  TChildProps
export function withScreensDealShareDealUrlByEmail<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealShareDealUrlByEmailMutation,
    ScreensDealShareDealUrlByEmailMutationVariables,
    ScreensDealShareDealUrlByEmailProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensDealShareDealUrlByEmailMutation,
    ScreensDealShareDealUrlByEmailMutationVariables,
    ScreensDealShareDealUrlByEmailProps<TChildProps>
  >(ScreensDealShareDealUrlByEmailDocument, {
    alias: 'withScreensDealShareDealUrlByEmail',
    ...operationOptions,
  })
}
export const ScreensDealSyncDealWithHubspotDocument = gql`
  mutation ScreensDealSyncDealWithHubspot($dealId: ID!) {
    synchronizeDealWithHubspot(id: $dealId) {
      id
    }
  }
`
export type ScreensDealSyncDealWithHubspotMutationFn = ReactApollo.MutationFn<
  ScreensDealSyncDealWithHubspotMutation,
  ScreensDealSyncDealWithHubspotMutationVariables
>
export type ScreensDealSyncDealWithHubspotComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensDealSyncDealWithHubspotMutation,
    ScreensDealSyncDealWithHubspotMutationVariables
  >,
  'mutation'
>

export const ScreensDealSyncDealWithHubspotComponent = (
  props: ScreensDealSyncDealWithHubspotComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensDealSyncDealWithHubspotMutation,
    ScreensDealSyncDealWithHubspotMutationVariables
  >
    mutation={ScreensDealSyncDealWithHubspotDocument}
    {...props}
  />
)

export type ScreensDealSyncDealWithHubspotProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ScreensDealSyncDealWithHubspotMutation,
    ScreensDealSyncDealWithHubspotMutationVariables
  >
> &
  TChildProps
export function withScreensDealSyncDealWithHubspot<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealSyncDealWithHubspotMutation,
    ScreensDealSyncDealWithHubspotMutationVariables,
    ScreensDealSyncDealWithHubspotProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensDealSyncDealWithHubspotMutation,
    ScreensDealSyncDealWithHubspotMutationVariables,
    ScreensDealSyncDealWithHubspotProps<TChildProps>
  >(ScreensDealSyncDealWithHubspotDocument, {
    alias: 'withScreensDealSyncDealWithHubspot',
    ...operationOptions,
  })
}
export const ScreensDealUpdateProposalStateDocument = gql`
  mutation ScreensDealUpdateProposalState(
    $proposalId: UUID!
    $newState: ProposalState!
    $reason: String
  ) {
    updateProposalState(
      proposalId: $proposalId
      proposalState: $newState
      reason: $reason
    ) {
      id
      state
      stateReason
    }
  }
`
export type ScreensDealUpdateProposalStateMutationFn = ReactApollo.MutationFn<
  ScreensDealUpdateProposalStateMutation,
  ScreensDealUpdateProposalStateMutationVariables
>
export type ScreensDealUpdateProposalStateComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensDealUpdateProposalStateMutation,
    ScreensDealUpdateProposalStateMutationVariables
  >,
  'mutation'
>

export const ScreensDealUpdateProposalStateComponent = (
  props: ScreensDealUpdateProposalStateComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensDealUpdateProposalStateMutation,
    ScreensDealUpdateProposalStateMutationVariables
  >
    mutation={ScreensDealUpdateProposalStateDocument}
    {...props}
  />
)

export type ScreensDealUpdateProposalStateProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ScreensDealUpdateProposalStateMutation,
    ScreensDealUpdateProposalStateMutationVariables
  >
> &
  TChildProps
export function withScreensDealUpdateProposalState<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealUpdateProposalStateMutation,
    ScreensDealUpdateProposalStateMutationVariables,
    ScreensDealUpdateProposalStateProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensDealUpdateProposalStateMutation,
    ScreensDealUpdateProposalStateMutationVariables,
    ScreensDealUpdateProposalStateProps<TChildProps>
  >(ScreensDealUpdateProposalStateDocument, {
    alias: 'withScreensDealUpdateProposalState',
    ...operationOptions,
  })
}
export const ScreensDealSharedFetchDealDocument = gql`
  query ScreensDealSharedFetchDeal($dealPublicId: UUID!, $accessToken: String) {
    deal: dealByPublicAccessToken(token: $dealPublicId) {
      id
      name
      event {
        name
        brief
        productInterest
        date
        budget {
          amount
          currency
          vatIncluded
        }
      }
      stage
      decisionDeadline
      headboxCompany {
        id
        name
        logoURL
        members {
          id
          fullName
          avatarURL
        }
        teams {
          id
          name
        }
      }
      headboxCompanyTeam {
        id
        name
        managers {
          id
        }
      }
      headboxServiceFee
      bookedSpace
      hubspotDealURL
      primaryContact {
        ... on HeadboxUser {
          id
          fullName
          firstName
          lastName
          email
          phoneNumber
        }
        ... on DealPrimaryContactProps {
          fullName
          firstName
          lastName
          email
          phone
        }
      }
      ownerAccountManager {
        id
        fullName
        firstName
        lastName
        email
        avatarURL
      }
      juniorAccountManager {
        id
        fullName
        firstName
        lastName
        email
        avatarURL
      }
      amount {
        amount
        currency
        vatIncluded
      }
      proposals(accessToken: $accessToken) {
        id
        name
        state
        deletedAt
        layouts {
          capacity
          name
        }
        space {
          name
          description {
            ...ScreensDealSharedFetchDealProposalDraftState
          }
          marketplaceURL
          marketplaceId
          tour3dURL
          location
          fullAddress
          city
          coordinates {
            latitude
            longitude
          }
        }
        totalCost {
          amount
          currency
          vatIncluded
        }
        images {
          id
          name
          caption
          URL
        }
        availabilities {
          id
          date
          optionHold
          timings
        }
        costItems {
          id
          type
          description
        }
        attachments {
          id
          name
          mimeType
          size
          URL
        }
        comments {
          id
          text
          createdAt
          updatedAt
          author {
            fullName
            email
            mobileNumber
            avatarURL
            user {
              id
              company {
                id
                name
              }
            }
          }
        }
        additionalNotes {
          ...ScreensDealSharedFetchDealProposalDraftState
        }
      }
    }
  }
  ${ScreensDealSharedFetchDealProposalDraftStateFragmentDoc}
`
export type ScreensDealSharedFetchDealComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreensDealSharedFetchDealQuery,
    ScreensDealSharedFetchDealQueryVariables
  >,
  'query'
> &
  (
    | { variables: ScreensDealSharedFetchDealQueryVariables; skip?: false }
    | { skip: true })

export const ScreensDealSharedFetchDealComponent = (
  props: ScreensDealSharedFetchDealComponentProps
) => (
  <ReactApollo.Query<
    ScreensDealSharedFetchDealQuery,
    ScreensDealSharedFetchDealQueryVariables
  >
    query={ScreensDealSharedFetchDealDocument}
    {...props}
  />
)

export type ScreensDealSharedFetchDealProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ScreensDealSharedFetchDealQuery,
    ScreensDealSharedFetchDealQueryVariables
  >
> &
  TChildProps
export function withScreensDealSharedFetchDeal<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensDealSharedFetchDealQuery,
    ScreensDealSharedFetchDealQueryVariables,
    ScreensDealSharedFetchDealProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreensDealSharedFetchDealQuery,
    ScreensDealSharedFetchDealQueryVariables,
    ScreensDealSharedFetchDealProps<TChildProps>
  >(ScreensDealSharedFetchDealDocument, {
    alias: 'withScreensDealSharedFetchDeal',
    ...operationOptions,
  })
}
export const ScreensPipelineFetchDataForFiltersDocument = gql`
  query ScreensPipelineFetchDataForFilters {
    accountManagers {
      id
      fullName
      avatarURL
    }
    companies: companiesWithDeals {
      id
      name
      logoURL
    }
    userCompany: me {
      user {
        id
        company {
          id
          members {
            id
            fullName
          }
          teams {
            id
            name
          }
        }
      }
    }
    allEventTypes
  }
`
export type ScreensPipelineFetchDataForFiltersComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreensPipelineFetchDataForFiltersQuery,
    ScreensPipelineFetchDataForFiltersQueryVariables
  >,
  'query'
>

export const ScreensPipelineFetchDataForFiltersComponent = (
  props: ScreensPipelineFetchDataForFiltersComponentProps
) => (
  <ReactApollo.Query<
    ScreensPipelineFetchDataForFiltersQuery,
    ScreensPipelineFetchDataForFiltersQueryVariables
  >
    query={ScreensPipelineFetchDataForFiltersDocument}
    {...props}
  />
)

export type ScreensPipelineFetchDataForFiltersProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ScreensPipelineFetchDataForFiltersQuery,
    ScreensPipelineFetchDataForFiltersQueryVariables
  >
> &
  TChildProps
export function withScreensPipelineFetchDataForFilters<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensPipelineFetchDataForFiltersQuery,
    ScreensPipelineFetchDataForFiltersQueryVariables,
    ScreensPipelineFetchDataForFiltersProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreensPipelineFetchDataForFiltersQuery,
    ScreensPipelineFetchDataForFiltersQueryVariables,
    ScreensPipelineFetchDataForFiltersProps<TChildProps>
  >(ScreensPipelineFetchDataForFiltersDocument, {
    alias: 'withScreensPipelineFetchDataForFilters',
    ...operationOptions,
  })
}
export const ScreensPipelineFetchPipelineDocument = gql`
  query ScreensPipelineFetchPipeline(
    $filtering: DealsListFilteringInput!
    $pagination: PaginationInput!
    $sorting: DealsListSortingInput!
  ) {
    runtimeVariables {
      createDealFormURL
    }
    pipeline: dealsList(
      filtering: $filtering
      pagination: $pagination
      sorting: $sorting
    ) {
      deals {
        id
        createdAt
        updatedAt
        name
        stage
        decisionDeadline
        amount {
          amount
          currency
          vatIncluded
        }
        event {
          name
          type
          date
          budget {
            amount
            currency
            vatIncluded
          }
        }
        primaryContact {
          ... on HeadboxUser {
            id
            fullName
          }
          ... on DealPrimaryContactProps {
            fullName
          }
        }
        headboxCompany {
          id
          name
          logoURL
        }
        headboxCompanyTeam {
          id
          name
        }
        ownerAccountManager {
          id
          fullName
          email
          avatarURL
        }
        juniorAccountManager {
          id
          fullName
          email
          avatarURL
        }
      }
      page {
        number
        size
        totalPages
      }
      totalDeals
    }
  }
`
export type ScreensPipelineFetchPipelineComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreensPipelineFetchPipelineQuery,
    ScreensPipelineFetchPipelineQueryVariables
  >,
  'query'
> &
  (
    | { variables: ScreensPipelineFetchPipelineQueryVariables; skip?: false }
    | { skip: true })

export const ScreensPipelineFetchPipelineComponent = (
  props: ScreensPipelineFetchPipelineComponentProps
) => (
  <ReactApollo.Query<
    ScreensPipelineFetchPipelineQuery,
    ScreensPipelineFetchPipelineQueryVariables
  >
    query={ScreensPipelineFetchPipelineDocument}
    {...props}
  />
)

export type ScreensPipelineFetchPipelineProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ScreensPipelineFetchPipelineQuery,
    ScreensPipelineFetchPipelineQueryVariables
  >
> &
  TChildProps
export function withScreensPipelineFetchPipeline<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensPipelineFetchPipelineQuery,
    ScreensPipelineFetchPipelineQueryVariables,
    ScreensPipelineFetchPipelineProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreensPipelineFetchPipelineQuery,
    ScreensPipelineFetchPipelineQueryVariables,
    ScreensPipelineFetchPipelineProps<TChildProps>
  >(ScreensPipelineFetchPipelineDocument, {
    alias: 'withScreensPipelineFetchPipeline',
    ...operationOptions,
  })
}
export const ScreensPipelineSyncDealWithHubspotDocument = gql`
  mutation ScreensPipelineSyncDealWithHubspot($dealId: ID!) {
    synchronizeDealWithHubspot(id: $dealId) {
      id
    }
  }
`
export type ScreensPipelineSyncDealWithHubspotMutationFn = ReactApollo.MutationFn<
  ScreensPipelineSyncDealWithHubspotMutation,
  ScreensPipelineSyncDealWithHubspotMutationVariables
>
export type ScreensPipelineSyncDealWithHubspotComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensPipelineSyncDealWithHubspotMutation,
    ScreensPipelineSyncDealWithHubspotMutationVariables
  >,
  'mutation'
>

export const ScreensPipelineSyncDealWithHubspotComponent = (
  props: ScreensPipelineSyncDealWithHubspotComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensPipelineSyncDealWithHubspotMutation,
    ScreensPipelineSyncDealWithHubspotMutationVariables
  >
    mutation={ScreensPipelineSyncDealWithHubspotDocument}
    {...props}
  />
)

export type ScreensPipelineSyncDealWithHubspotProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ScreensPipelineSyncDealWithHubspotMutation,
    ScreensPipelineSyncDealWithHubspotMutationVariables
  >
> &
  TChildProps
export function withScreensPipelineSyncDealWithHubspot<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensPipelineSyncDealWithHubspotMutation,
    ScreensPipelineSyncDealWithHubspotMutationVariables,
    ScreensPipelineSyncDealWithHubspotProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensPipelineSyncDealWithHubspotMutation,
    ScreensPipelineSyncDealWithHubspotMutationVariables,
    ScreensPipelineSyncDealWithHubspotProps<TChildProps>
  >(ScreensPipelineSyncDealWithHubspotDocument, {
    alias: 'withScreensPipelineSyncDealWithHubspot',
    ...operationOptions,
  })
}
export const ScreenProposalAddCommentToProposalDocument = gql`
  mutation ScreenProposalAddCommentToProposal(
    $proposalId: UUID!
    $commentData: ProposalCommentInput!
  ) {
    comment: addCommentToProposal(
      proposalId: $proposalId
      commentData: $commentData
    ) {
      id
      proposal {
        id
        comments {
          id
          text
          createdAt
          updatedAt
          author {
            fullName
            email
            mobileNumber
            avatarURL
            user {
              id
              company {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
export type ScreenProposalAddCommentToProposalMutationFn = ReactApollo.MutationFn<
  ScreenProposalAddCommentToProposalMutation,
  ScreenProposalAddCommentToProposalMutationVariables
>
export type ScreenProposalAddCommentToProposalComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreenProposalAddCommentToProposalMutation,
    ScreenProposalAddCommentToProposalMutationVariables
  >,
  'mutation'
>

export const ScreenProposalAddCommentToProposalComponent = (
  props: ScreenProposalAddCommentToProposalComponentProps
) => (
  <ReactApollo.Mutation<
    ScreenProposalAddCommentToProposalMutation,
    ScreenProposalAddCommentToProposalMutationVariables
  >
    mutation={ScreenProposalAddCommentToProposalDocument}
    {...props}
  />
)

export type ScreenProposalAddCommentToProposalProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ScreenProposalAddCommentToProposalMutation,
    ScreenProposalAddCommentToProposalMutationVariables
  >
> &
  TChildProps
export function withScreenProposalAddCommentToProposal<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreenProposalAddCommentToProposalMutation,
    ScreenProposalAddCommentToProposalMutationVariables,
    ScreenProposalAddCommentToProposalProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreenProposalAddCommentToProposalMutation,
    ScreenProposalAddCommentToProposalMutationVariables,
    ScreenProposalAddCommentToProposalProps<TChildProps>
  >(ScreenProposalAddCommentToProposalDocument, {
    alias: 'withScreenProposalAddCommentToProposal',
    ...operationOptions,
  })
}
export const ScreenProposalFetchProposalDocument = gql`
  query ScreenProposalFetchProposal($proposalId: UUID!) {
    proposal: proposalById(id: $proposalId) {
      id
      name
      state
      stateReason
      layouts {
        capacity
        name
      }
      totalCost {
        amount
        currency
        vatIncluded
      }
      costItems {
        id
        type
        description
      }
      availabilities {
        id
        date
        timings
        optionHold
      }
      images {
        id
        name
        caption
        URL
      }
      attachments {
        id
        name
        mimeType
        size
        URL
      }
      comments {
        id
        text
        createdAt
        updatedAt
        author {
          fullName
          email
          mobileNumber
          avatarURL
          user {
            id
            company {
              id
              name
            }
          }
        }
      }
      additionalNotes {
        ...ScreenProposalFetchProposalDraftState
      }
      deal {
        id
        name
        stage
        decisionDeadline
        numberOfAttendees
        event {
          name
          date
          productInterest
          type
          budget {
            amount
            currency
            vatIncluded
          }
        }
        proposals {
          id
          state
        }
        headboxCompanyTeam {
          id
          managers {
            id
          }
        }
        amount {
          amount
          currency
          vatIncluded
        }
      }
      space {
        name
        description {
          ...ScreenProposalFetchProposalDraftState
        }
        marketplaceURL
        marketplaceId
        tour3dURL
        location
        fullAddress
        city
        coordinates {
          latitude
          longitude
        }
        hostEmail
      }
      actorCanAccept
    }
  }
  ${ScreenProposalFetchProposalDraftStateFragmentDoc}
`
export type ScreenProposalFetchProposalComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreenProposalFetchProposalQuery,
    ScreenProposalFetchProposalQueryVariables
  >,
  'query'
> &
  (
    | { variables: ScreenProposalFetchProposalQueryVariables; skip?: false }
    | { skip: true })

export const ScreenProposalFetchProposalComponent = (
  props: ScreenProposalFetchProposalComponentProps
) => (
  <ReactApollo.Query<
    ScreenProposalFetchProposalQuery,
    ScreenProposalFetchProposalQueryVariables
  >
    query={ScreenProposalFetchProposalDocument}
    {...props}
  />
)

export type ScreenProposalFetchProposalProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ScreenProposalFetchProposalQuery,
    ScreenProposalFetchProposalQueryVariables
  >
> &
  TChildProps
export function withScreenProposalFetchProposal<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreenProposalFetchProposalQuery,
    ScreenProposalFetchProposalQueryVariables,
    ScreenProposalFetchProposalProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreenProposalFetchProposalQuery,
    ScreenProposalFetchProposalQueryVariables,
    ScreenProposalFetchProposalProps<TChildProps>
  >(ScreenProposalFetchProposalDocument, {
    alias: 'withScreenProposalFetchProposal',
    ...operationOptions,
  })
}
export const ScreensProposalUpdateProposalStateDocument = gql`
  mutation ScreensProposalUpdateProposalState(
    $proposalId: UUID!
    $newState: ProposalState!
    $reason: String
  ) {
    updateProposalState(
      proposalId: $proposalId
      proposalState: $newState
      reason: $reason
    ) {
      id
      state
      stateReason
    }
  }
`
export type ScreensProposalUpdateProposalStateMutationFn = ReactApollo.MutationFn<
  ScreensProposalUpdateProposalStateMutation,
  ScreensProposalUpdateProposalStateMutationVariables
>
export type ScreensProposalUpdateProposalStateComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensProposalUpdateProposalStateMutation,
    ScreensProposalUpdateProposalStateMutationVariables
  >,
  'mutation'
>

export const ScreensProposalUpdateProposalStateComponent = (
  props: ScreensProposalUpdateProposalStateComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensProposalUpdateProposalStateMutation,
    ScreensProposalUpdateProposalStateMutationVariables
  >
    mutation={ScreensProposalUpdateProposalStateDocument}
    {...props}
  />
)

export type ScreensProposalUpdateProposalStateProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ScreensProposalUpdateProposalStateMutation,
    ScreensProposalUpdateProposalStateMutationVariables
  >
> &
  TChildProps
export function withScreensProposalUpdateProposalState<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensProposalUpdateProposalStateMutation,
    ScreensProposalUpdateProposalStateMutationVariables,
    ScreensProposalUpdateProposalStateProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensProposalUpdateProposalStateMutation,
    ScreensProposalUpdateProposalStateMutationVariables,
    ScreensProposalUpdateProposalStateProps<TChildProps>
  >(ScreensProposalUpdateProposalStateDocument, {
    alias: 'withScreensProposalUpdateProposalState',
    ...operationOptions,
  })
}
export const ScreenProposalCreationCreateProposalDocument = gql`
  mutation ScreenProposalCreationCreateProposal(
    $dealId: UUID!
    $proposalData: ProposalDataInput!
  ) {
    proposal: addProposalToDeal(dealId: $dealId, proposalData: $proposalData) {
      id
    }
  }
`
export type ScreenProposalCreationCreateProposalMutationFn = ReactApollo.MutationFn<
  ScreenProposalCreationCreateProposalMutation,
  ScreenProposalCreationCreateProposalMutationVariables
>
export type ScreenProposalCreationCreateProposalComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreenProposalCreationCreateProposalMutation,
    ScreenProposalCreationCreateProposalMutationVariables
  >,
  'mutation'
>

export const ScreenProposalCreationCreateProposalComponent = (
  props: ScreenProposalCreationCreateProposalComponentProps
) => (
  <ReactApollo.Mutation<
    ScreenProposalCreationCreateProposalMutation,
    ScreenProposalCreationCreateProposalMutationVariables
  >
    mutation={ScreenProposalCreationCreateProposalDocument}
    {...props}
  />
)

export type ScreenProposalCreationCreateProposalProps<
  TChildProps = {}
> = Partial<
  ReactApollo.MutateProps<
    ScreenProposalCreationCreateProposalMutation,
    ScreenProposalCreationCreateProposalMutationVariables
  >
> &
  TChildProps
export function withScreenProposalCreationCreateProposal<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreenProposalCreationCreateProposalMutation,
    ScreenProposalCreationCreateProposalMutationVariables,
    ScreenProposalCreationCreateProposalProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreenProposalCreationCreateProposalMutation,
    ScreenProposalCreationCreateProposalMutationVariables,
    ScreenProposalCreationCreateProposalProps<TChildProps>
  >(ScreenProposalCreationCreateProposalDocument, {
    alias: 'withScreenProposalCreationCreateProposal',
    ...operationOptions,
  })
}
export const ScreenProposalCreationFetchDealDocument = gql`
  query ScreenProposalCreationFetchDeal($dealId: UUID!) {
    deal: dealById(id: $dealId) {
      id
      name
      stage
      decisionDeadline
      numberOfAttendees
      event {
        name
        brief
        type
        productInterest
        date
        budget {
          amount
          currency
          vatIncluded
        }
      }
      headboxCompany {
        id
        name
        logoURL
      }
      primaryContact {
        ... on HeadboxUser {
          id
          fullName
          firstName
          lastName
          email
          mobileNumber
        }
        ... on DealPrimaryContactProps {
          fullName
          firstName
          lastName
          email
          phone
        }
      }
      ownerAccountManager {
        id
        fullName
        firstName
        lastName
        email
        avatarURL
      }
      juniorAccountManager {
        id
        fullName
        firstName
        lastName
        email
        avatarURL
      }
      amount {
        amount
        currency
        vatIncluded
      }
    }
  }
`
export type ScreenProposalCreationFetchDealComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreenProposalCreationFetchDealQuery,
    ScreenProposalCreationFetchDealQueryVariables
  >,
  'query'
> &
  (
    | { variables: ScreenProposalCreationFetchDealQueryVariables; skip?: false }
    | { skip: true })

export const ScreenProposalCreationFetchDealComponent = (
  props: ScreenProposalCreationFetchDealComponentProps
) => (
  <ReactApollo.Query<
    ScreenProposalCreationFetchDealQuery,
    ScreenProposalCreationFetchDealQueryVariables
  >
    query={ScreenProposalCreationFetchDealDocument}
    {...props}
  />
)

export type ScreenProposalCreationFetchDealProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ScreenProposalCreationFetchDealQuery,
    ScreenProposalCreationFetchDealQueryVariables
  >
> &
  TChildProps
export function withScreenProposalCreationFetchDeal<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreenProposalCreationFetchDealQuery,
    ScreenProposalCreationFetchDealQueryVariables,
    ScreenProposalCreationFetchDealProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreenProposalCreationFetchDealQuery,
    ScreenProposalCreationFetchDealQueryVariables,
    ScreenProposalCreationFetchDealProps<TChildProps>
  >(ScreenProposalCreationFetchDealDocument, {
    alias: 'withScreenProposalCreationFetchDeal',
    ...operationOptions,
  })
}
export const ScreenProposalCreationFetchHeaboxSpaceDocument = gql`
  query ScreenProposalCreationFetchHeaboxSpace($spaceId: Int!) {
    headboxSpaceById(id: $spaceId) {
      id
      name
      venueName
      description
      maxCapacity
      marketplaceURL
      tour3dURL
      location
      fullAddress
      city
      photos {
        name
        URL
      }
      attachments {
        name
        URL
      }
      coordinates {
        latitude
        longitude
      }
    }
  }
`
export type ScreenProposalCreationFetchHeaboxSpaceComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreenProposalCreationFetchHeaboxSpaceQuery,
    ScreenProposalCreationFetchHeaboxSpaceQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: ScreenProposalCreationFetchHeaboxSpaceQueryVariables
        skip?: false
      }
    | { skip: true })

export const ScreenProposalCreationFetchHeaboxSpaceComponent = (
  props: ScreenProposalCreationFetchHeaboxSpaceComponentProps
) => (
  <ReactApollo.Query<
    ScreenProposalCreationFetchHeaboxSpaceQuery,
    ScreenProposalCreationFetchHeaboxSpaceQueryVariables
  >
    query={ScreenProposalCreationFetchHeaboxSpaceDocument}
    {...props}
  />
)

export type ScreenProposalCreationFetchHeaboxSpaceProps<
  TChildProps = {}
> = Partial<
  ReactApollo.DataProps<
    ScreenProposalCreationFetchHeaboxSpaceQuery,
    ScreenProposalCreationFetchHeaboxSpaceQueryVariables
  >
> &
  TChildProps
export function withScreenProposalCreationFetchHeaboxSpace<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreenProposalCreationFetchHeaboxSpaceQuery,
    ScreenProposalCreationFetchHeaboxSpaceQueryVariables,
    ScreenProposalCreationFetchHeaboxSpaceProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreenProposalCreationFetchHeaboxSpaceQuery,
    ScreenProposalCreationFetchHeaboxSpaceQueryVariables,
    ScreenProposalCreationFetchHeaboxSpaceProps<TChildProps>
  >(ScreenProposalCreationFetchHeaboxSpaceDocument, {
    alias: 'withScreenProposalCreationFetchHeaboxSpace',
    ...operationOptions,
  })
}
export const ScreenProposalModificationFetchHeaboxSpaceDocument = gql`
  query ScreenProposalModificationFetchHeaboxSpace($spaceId: Int!) {
    headboxSpaceById(id: $spaceId) {
      id
      name
      venueName
      description
      maxCapacity
      marketplaceURL
      tour3dURL
      location
      fullAddress
      city
      photos {
        name
        URL
      }
      attachments {
        name
        URL
      }
      coordinates {
        latitude
        longitude
      }
    }
  }
`
export type ScreenProposalModificationFetchHeaboxSpaceComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreenProposalModificationFetchHeaboxSpaceQuery,
    ScreenProposalModificationFetchHeaboxSpaceQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: ScreenProposalModificationFetchHeaboxSpaceQueryVariables
        skip?: false
      }
    | { skip: true })

export const ScreenProposalModificationFetchHeaboxSpaceComponent = (
  props: ScreenProposalModificationFetchHeaboxSpaceComponentProps
) => (
  <ReactApollo.Query<
    ScreenProposalModificationFetchHeaboxSpaceQuery,
    ScreenProposalModificationFetchHeaboxSpaceQueryVariables
  >
    query={ScreenProposalModificationFetchHeaboxSpaceDocument}
    {...props}
  />
)

export type ScreenProposalModificationFetchHeaboxSpaceProps<
  TChildProps = {}
> = Partial<
  ReactApollo.DataProps<
    ScreenProposalModificationFetchHeaboxSpaceQuery,
    ScreenProposalModificationFetchHeaboxSpaceQueryVariables
  >
> &
  TChildProps
export function withScreenProposalModificationFetchHeaboxSpace<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreenProposalModificationFetchHeaboxSpaceQuery,
    ScreenProposalModificationFetchHeaboxSpaceQueryVariables,
    ScreenProposalModificationFetchHeaboxSpaceProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreenProposalModificationFetchHeaboxSpaceQuery,
    ScreenProposalModificationFetchHeaboxSpaceQueryVariables,
    ScreenProposalModificationFetchHeaboxSpaceProps<TChildProps>
  >(ScreenProposalModificationFetchHeaboxSpaceDocument, {
    alias: 'withScreenProposalModificationFetchHeaboxSpace',
    ...operationOptions,
  })
}
export const ScreenProposalModificationFetchProposalDocument = gql`
  query ScreenProposalModificationFetchProposal($proposalId: UUID!) {
    proposal: proposalById(id: $proposalId) {
      id
      name
      state
      deal {
        id
        name
        stage
        decisionDeadline
        numberOfAttendees
        amount {
          amount
          vatIncluded
          currency
        }
        event {
          name
          type
          date
          productInterest
          budget {
            amount
            vatIncluded
            currency
          }
        }
      }
      space {
        name
        hostEmail
        description {
          ...ScreenProposalModificationFetchProposalDraftState
        }
        marketplaceId
        marketplaceURL
        tour3dURL
        location
        fullAddress
        city
        coordinates {
          latitude
          longitude
        }
      }
      layouts {
        capacity
        name
      }
      totalCost {
        amount
        currency
        vatIncluded
      }
      images {
        id
        name
        caption
        URL
      }
      attachments {
        id
        name
        mimeType
        size
        URL
      }
      availabilities {
        id
        date
        optionHold
        timings
      }
      costItems {
        id
        type
        description
      }
      additionalNotes {
        ...ScreenProposalModificationFetchProposalDraftState
      }
    }
  }
  ${ScreenProposalModificationFetchProposalDraftStateFragmentDoc}
`
export type ScreenProposalModificationFetchProposalComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreenProposalModificationFetchProposalQuery,
    ScreenProposalModificationFetchProposalQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: ScreenProposalModificationFetchProposalQueryVariables
        skip?: false
      }
    | { skip: true })

export const ScreenProposalModificationFetchProposalComponent = (
  props: ScreenProposalModificationFetchProposalComponentProps
) => (
  <ReactApollo.Query<
    ScreenProposalModificationFetchProposalQuery,
    ScreenProposalModificationFetchProposalQueryVariables
  >
    query={ScreenProposalModificationFetchProposalDocument}
    {...props}
  />
)

export type ScreenProposalModificationFetchProposalProps<
  TChildProps = {}
> = Partial<
  ReactApollo.DataProps<
    ScreenProposalModificationFetchProposalQuery,
    ScreenProposalModificationFetchProposalQueryVariables
  >
> &
  TChildProps
export function withScreenProposalModificationFetchProposal<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreenProposalModificationFetchProposalQuery,
    ScreenProposalModificationFetchProposalQueryVariables,
    ScreenProposalModificationFetchProposalProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreenProposalModificationFetchProposalQuery,
    ScreenProposalModificationFetchProposalQueryVariables,
    ScreenProposalModificationFetchProposalProps<TChildProps>
  >(ScreenProposalModificationFetchProposalDocument, {
    alias: 'withScreenProposalModificationFetchProposal',
    ...operationOptions,
  })
}
export const ScreensProposalModificationUpdateProposalDocument = gql`
  mutation ScreensProposalModificationUpdateProposal(
    $proposalId: UUID!
    $proposalData: ProposalDataInput!
  ) {
    proposal: updateProposal(
      proposalId: $proposalId
      proposalData: $proposalData
    ) {
      id
    }
  }
`
export type ScreensProposalModificationUpdateProposalMutationFn = ReactApollo.MutationFn<
  ScreensProposalModificationUpdateProposalMutation,
  ScreensProposalModificationUpdateProposalMutationVariables
>
export type ScreensProposalModificationUpdateProposalComponentProps = Omit<
  ReactApollo.MutationProps<
    ScreensProposalModificationUpdateProposalMutation,
    ScreensProposalModificationUpdateProposalMutationVariables
  >,
  'mutation'
>

export const ScreensProposalModificationUpdateProposalComponent = (
  props: ScreensProposalModificationUpdateProposalComponentProps
) => (
  <ReactApollo.Mutation<
    ScreensProposalModificationUpdateProposalMutation,
    ScreensProposalModificationUpdateProposalMutationVariables
  >
    mutation={ScreensProposalModificationUpdateProposalDocument}
    {...props}
  />
)

export type ScreensProposalModificationUpdateProposalProps<
  TChildProps = {}
> = Partial<
  ReactApollo.MutateProps<
    ScreensProposalModificationUpdateProposalMutation,
    ScreensProposalModificationUpdateProposalMutationVariables
  >
> &
  TChildProps
export function withScreensProposalModificationUpdateProposal<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreensProposalModificationUpdateProposalMutation,
    ScreensProposalModificationUpdateProposalMutationVariables,
    ScreensProposalModificationUpdateProposalProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ScreensProposalModificationUpdateProposalMutation,
    ScreensProposalModificationUpdateProposalMutationVariables,
    ScreensProposalModificationUpdateProposalProps<TChildProps>
  >(ScreensProposalModificationUpdateProposalDocument, {
    alias: 'withScreensProposalModificationUpdateProposal',
    ...operationOptions,
  })
}
export const ScreenViewProposalFetchProposalDocument = gql`
  query ScreenViewProposalFetchProposal($proposalId: UUID!) {
    proposal: proposalById(id: $proposalId) {
      id
      name
      state
      stateReason
      layouts {
        capacity
        name
      }
      totalCost {
        amount
        currency
        vatIncluded
      }
      costItems {
        id
        type
        description
      }
      availabilities {
        id
        date
        timings
        optionHold
      }
      images {
        id
        name
        caption
        URL
      }
      attachments {
        id
        name
        mimeType
        size
        URL
      }
      comments {
        id
        text
        createdAt
        updatedAt
        author {
          fullName
          email
          mobileNumber
          avatarURL
          user {
            id
            company {
              id
              name
            }
          }
        }
      }
      additionalNotes {
        ...ScreenProposalFetchProposalDraftState
      }
      deal {
        id
        name
        stage
        decisionDeadline
        numberOfAttendees
        event {
          name
          date
          productInterest
          type
          budget {
            amount
            currency
            vatIncluded
          }
        }
        proposals {
          id
          state
        }
        headboxCompanyTeam {
          id
          managers {
            id
          }
        }
        amount {
          amount
          currency
          vatIncluded
        }
      }
      space {
        name
        description {
          ...ScreenProposalFetchProposalDraftState
        }
        marketplaceURL
        marketplaceId
        tour3dURL
        location
        fullAddress
        city
        coordinates {
          latitude
          longitude
        }
        hostEmail
      }
      actorCanAccept
    }
  }
  ${ScreenProposalFetchProposalDraftStateFragmentDoc}
`
export type ScreenViewProposalFetchProposalComponentProps = Omit<
  ReactApollo.QueryProps<
    ScreenViewProposalFetchProposalQuery,
    ScreenViewProposalFetchProposalQueryVariables
  >,
  'query'
> &
  (
    | { variables: ScreenViewProposalFetchProposalQueryVariables; skip?: false }
    | { skip: true })

export const ScreenViewProposalFetchProposalComponent = (
  props: ScreenViewProposalFetchProposalComponentProps
) => (
  <ReactApollo.Query<
    ScreenViewProposalFetchProposalQuery,
    ScreenViewProposalFetchProposalQueryVariables
  >
    query={ScreenViewProposalFetchProposalDocument}
    {...props}
  />
)

export type ScreenViewProposalFetchProposalProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ScreenViewProposalFetchProposalQuery,
    ScreenViewProposalFetchProposalQueryVariables
  >
> &
  TChildProps
export function withScreenViewProposalFetchProposal<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ScreenViewProposalFetchProposalQuery,
    ScreenViewProposalFetchProposalQueryVariables,
    ScreenViewProposalFetchProposalProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ScreenViewProposalFetchProposalQuery,
    ScreenViewProposalFetchProposalQueryVariables,
    ScreenViewProposalFetchProposalProps<TChildProps>
  >(ScreenViewProposalFetchProposalDocument, {
    alias: 'withScreenViewProposalFetchProposal',
    ...operationOptions,
  })
}
