import styled from '@emotion/styled'
import React from 'react'

import BoxEmpty from 'app/static/images/box-empty.png'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0;
  user-select: none;
  text-align: center;
  pointer-events: none;
  > img {
    width: 100px;
    margin-bottom: 0.5rem;
  }
`

const Message = styled.h4`
  max-width: 550px;
  margin: 0 auto;
  padding: 0 2rem;
`

interface Props {
  message?: string
}

export const NoDataPlaceholder = ({ message }: Props) => (
  <Container>
    <img src={BoxEmpty} alt={message || 'No data found'} />
    <Message>{message || 'No data found'}</Message>
  </Container>
)
