import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'

import { Grid } from 'app/components/layout/Grid'
import { Button } from 'app/components/ui/Button'
import { theme } from 'app/theme/themeDefault'

export interface ModalFooterProps {
  cancelButtonText?: string
  confirmButtonIsDisabled?: boolean
  confirmButtonText?: string
  onCloseRequest?: () => void
  onConfirmRequest?: () => void
  confirmButtonDataAttributeProps?: {
    [key: string]: string
  }
  fullwidthButtons?: boolean
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  cancelButtonText,
  confirmButtonIsDisabled,
  confirmButtonText,
  onCloseRequest,
  onConfirmRequest,
  confirmButtonDataAttributeProps,
  fullwidthButtons,
}) => {
  const showFooter = Boolean(cancelButtonText || onConfirmRequest)

  return !showFooter ? null : (
    <Footer>
      <Grid
        columnsTemplate='1fr 1fr'
        columnsTemplateBelowBreakpoint='1fr 1fr'
        breakAt='sm'
        gap='lg'
      >
        {cancelButtonText ? (
          <Button
            variant='secondary'
            onClick={onCloseRequest}
            fullwidth={fullwidthButtons}
            type='button'
          >
            {cancelButtonText}
          </Button>
        ) : (
          <div />
        )}
        {onConfirmRequest ? (
          <Button
            type='button'
            variant='primary'
            onClick={onConfirmRequest}
            fullwidth={fullwidthButtons}
            disabled={confirmButtonIsDisabled}
            {...confirmButtonDataAttributeProps}
            css={fullwidthButtons ? undefined : rightButtonAlignemnt}
          >
            {confirmButtonText || 'Confirm'}
          </Button>
        ) : null}
      </Grid>
    </Footer>
  )
}

export const Footer = styled.footer`
  flex: 0 0 auto;
  padding-top: ${theme.spacings.lg};
`

const rightButtonAlignemnt = css`
  margin-left: auto;
`
