import { css, SerializedStyles } from '@emotion/core'

export const breakpoints = {
  sm: '576px',
  smMax: '767px',
  md: '768px',
  mdMax: '991px',
  lg: '992px',
  lgMax: '1201px',
  xl: '1200px',
}

export type Breakpoint = keyof typeof breakpoints

export const makeSmartphoneMediaQuery = (style: SerializedStyles) => css`
  @media screen and (max-width: ${breakpoints.smMax}) {
    ${style}
  }
`

export const makeTabletMediaQuery = (style: SerializedStyles) => css`
  @media screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.mdMax}) {
    ${style}
  }
`

export const makeMobileMediaQuery = (style: SerializedStyles) => css`
  @media screen and (max-width: ${breakpoints.mdMax}) {
    ${style}
  }
`
