import ApolloClient, { ApolloError, FetchPolicy } from 'apollo-client'
import React from 'react'
import { Subtract } from 'utility-types'

import {
  Actor,
  ContainersActorProviderComponent,
} from 'app/containers/ApolloGeneratedComponents'
import { getDisplayName } from 'app/utils/getDisplayName'

interface ActorProviderRenderProps {
  loading: boolean
  actor?: Actor
  error?: ApolloError
  client?: ApolloClient<any>
  refetch: () => Promise<void>
}

const DEFAULT_FETCH_POLICY: FetchPolicy = 'cache-first'

interface Props {
  fetchPolicy?: FetchPolicy
  children: (renderProps: ActorProviderRenderProps) => React.ReactNode
}

export const ActorProvider = ({ fetchPolicy, children }: Props) => (
  <ContainersActorProviderComponent
    fetchPolicy={fetchPolicy || DEFAULT_FETCH_POLICY}
  >
    {({ loading, error, data, refetch, client }) => {
      const actor = data && data.actor
      return children({
        loading,
        actor,
        error,
        client,
        refetch: () => refetch().then(() => undefined),
      })
    }}
  </ContainersActorProviderComponent>
)

export interface WithActorProps {
  actorProvider: ActorProviderRenderProps
}

// HOC COMPONENT
export const withActor = <P extends WithActorProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  const WithActor: React.FC<Subtract<P, WithActorProps>> = (props) => (
    <ActorProvider>
      {({ loading, error, actor, refetch, client }) => (
        <WrappedComponent
          {...(props as P)}
          actorProvider={{ loading, error, actor, refetch, client }}
        />
      )}
    </ActorProvider>
  )

  WithActor.displayName = `WithActor(${getDisplayName(WrappedComponent)})`

  return WithActor
}
