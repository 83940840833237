import React from 'react'

import { ClampedLinesWithLabel } from 'app/components/ClampedLinesWithLabel'
import { Grid } from 'app/components/layout/Grid'
import { Spacer } from 'app/components/layout/Spacer'
import {
  COST_ITEMS_DICTIONARY,
  CostItemsTypes,
  CostItemType,
} from 'app/constants/proposalCostItems'
import {
  Price,
  ProposalCostItem,
  ProposalLayout,
} from 'app/containers/ApolloGeneratedComponents'
import { theme } from 'app/theme/themeDefault'
import { dateStringToNiceString } from 'app/utils/date'
import { generatePendoDataAttribute } from 'app/utils/pendo/generatePendoId'

import { PriceTag } from './PriceTag'
import { Container, Title } from './styled'

export interface Props {
  id: string
  title: string
  location: string
  layouts: readonly ProposalLayout[]
  availabilityDates: readonly string[]
  costItems: readonly ProposalCostItem[]
  totalCost: Price
  onProposalViewRequest: () => void
}

const isEditableType = (type: CostItemType) => !CostItemsTypes.includes(type)

export const Details = ({
  title,
  location,
  layouts,
  id,
  availabilityDates,
  costItems,
  totalCost,
  onProposalViewRequest,
}: Props) => (
  <>
    <Container
      onClick={onProposalViewRequest}
      {...generatePendoDataAttribute('Card', `Open proposal ${id}`)}
    >
      <Spacer size='md'>
        <Grid
          gap='sm'
          breakAt='sm'
          columnsTemplate='1fr auto'
          columnsTemplateBelowBreakpoint='1fr'
        >
          <Title>{title}</Title>
          <PriceTag {...totalCost} />
        </Grid>
      </Spacer>
      <Grid
        gap='sm'
        breakAt='sm'
        columnsTemplate='1fr 1fr'
        columnsTemplateBelowBreakpoint='1fr'
      >
        <ClampedLinesWithLabel
          label='Location'
          lines={[location]}
          spacing='none'
        />
        <ClampedLinesWithLabel
          label={
            layouts.length > 1 ? 'Layouts / capacities' : 'Layout / capacity'
          }
          lines={layouts.map(({ name, capacity }) => `${name} / ${capacity}`)}
          spacing='none'
          clampToMax={2}
        />
        <ClampedLinesWithLabel
          label={`Date${availabilityDates.length > 1 ? 's' : ''}`}
          lines={availabilityDates.map(dateStringToNiceString)}
          clampToMax={2}
          spacing='none'
        />
        {costItems.length
          ? costItems
              .slice(0, 3)
              .map(({ id: costId, type, description }) => (
                <ClampedLinesWithLabel
                  key={costId}
                  label={
                    isEditableType(type as CostItemType)
                      ? type
                      : COST_ITEMS_DICTIONARY[type as CostItemType].label
                  }
                  lines={[description]}
                  spacing='none'
                  clampToMax={2}
                  fontSize={theme.fontSize.small}
                />
              ))
          : null}
      </Grid>
    </Container>
  </>
)
