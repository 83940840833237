import styled from '@emotion/styled'
import React from 'react'

interface Props {
  allowOverflow: boolean
}

export const ModalBody: React.FC<Props> = ({ allowOverflow, children }) => (
  <Wrapper allowOverflow={allowOverflow}>{children}</Wrapper>
)

const Wrapper = styled.div<Props>`
  flex: 1 1 auto;
  overflow: ${({ allowOverflow }) => (allowOverflow ? 'visible' : 'auto')};
`
