import React from 'react'

import { FlexBox } from 'app/components/layout/FlexBox'
import { Spacer } from 'app/components/layout/Spacer'
import { Avatar } from 'app/components/ui/Avatar'
import { AvatarWrapper, Image, TitleWrapper } from './styled'

export interface CardHeadingProps {
  title: string
  media?: string
  showMediaAsAvatar?: boolean
}

export const CardHeading: React.FunctionComponent<CardHeadingProps> = ({
  title,
  media,
  showMediaAsAvatar,
}) => {
  return (
    <Spacer size='normal'>
      <FlexBox row={showMediaAsAvatar} dial={1}>
        <TitleWrapper>
          <h4>{title}</h4>
        </TitleWrapper>
        {media && showMediaAsAvatar ? (
          <AvatarWrapper>
            <Avatar imageUrl={media} altText={title || ''} />
          </AvatarWrapper>
        ) : media ? (
          <Image src={media} alt='title' />
        ) : null}
      </FlexBox>
    </Spacer>
  )
}
