import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { theme } from 'app/theme/themeDefault'
interface ContainerProps {
  hasError?: boolean
}

const getErrorStyle = ({ hasError = false }: ContainerProps) =>
  hasError
    ? css`
        border: 1px solid ${theme.colors.danger};
      `
    : css`
        border: 1px solid ${theme.colors.components.inputBorders};
      `

export const Container = styled.div<ContainerProps>`
  width: 100%;
  textarea {
    display: block;
    width: 100%;
    padding: ${theme.inputs.padding};
    line-height: ${theme.lineHeight.normal};
    resize: none;

    background-color: ${theme.colors.components.inputBackground};
    border-radius: ${theme.borderRadius};
    font-size: ${theme.fontSize.normal};
    min-height: ${theme.inputs.height};
    ${getErrorStyle};
    &:focus {
      outline: none;
      box-shadow: ${theme.boxShadowInputFocus};
    }
  }
`
