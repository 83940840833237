import { css } from '@emotion/core'
import { Props } from './index'

export const justifyContent = ({
  dial,
  row,
  reverse,
  spaceAround,
  spaceBetween,
  spaceEvenly,
}: Pick<
  Props,
  'dial' | 'row' | 'reverse' | 'spaceAround' | 'spaceBetween' | 'spaceEvenly'
>) => {
  const value = spaceAround
    ? 'space-around'
    : spaceBetween
    ? 'space-between'
    : spaceEvenly
    ? 'space-evenly'
    : row
    ? dial % 3 === 0
      ? reverse
        ? 'flex-start'
        : 'flex-end'
      : dial % 3 === 2
      ? 'center'
      : reverse
      ? 'flex-end'
      : 'flex-start'
    : dial > 6
    ? reverse
      ? 'flex-start'
      : 'flex-end'
    : dial > 3
    ? 'center'
    : reverse
    ? 'flex-end'
    : 'flex-start'

  return css`
    justify-content: ${value};
  `
}
