import React from 'react'

import { StyledCard, StyledCardProps } from './styled'

interface Props extends StyledCardProps {
  onClick?: (event: React.MouseEvent) => void
}

export const Card: React.FC<Props> = ({ children, onClick, ...rest }) => {
  return (
    <StyledCard onClick={onClick} {...rest}>
      {children}
    </StyledCard>
  )
}
