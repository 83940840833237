import { FieldProps } from 'formik'
import React from 'react'

import { InputWrapper, Props as StyledInputProps, StyledInput } from './styled'

interface Props {
  id?: string
  type?: 'text' | 'password' | 'number' | 'email'
  disabled?: boolean
  autoFocus?: boolean
  readOnly?: boolean
  placeholder?: string
  variant?: StyledInputProps['variant']
  hasError?: boolean
  onClick?: () => void
}

export const Input: React.FC<Props & FieldProps['field']> = ({
  hasError,
  variant,
  ...rest
}) => {
  return (
    <InputWrapper>
      <StyledInput
        {...rest}
        hasError={!!hasError}
        autoCorrect='false'
        autoCapitalize='false'
        spellCheck={false}
        autoComplete='off'
        variant={variant || 'normal'}
      />
    </InputWrapper>
  )
}
