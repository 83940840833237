import styled from '@emotion/styled'

import { FlexBox } from 'app/components/layout/FlexBox'
import { breakpoints } from 'app/theme/breakpoints'
import { theme } from 'app/theme/themeDefault'

export const FlexContainer = styled(FlexBox)`
  width: 100%;
  padding: 0.5rem 0;

  @media (max-width: ${breakpoints.sm}) {
    padding: ${theme.spacingsLegacy.component};
    padding: ${theme.spacings.component};
  }
`

export const FlexAction = styled(FlexBox)`
  flex: 1;
`

export const FlexDropdownContainer = styled(FlexBox)`
  i {
    padding: 0 1rem;
  }
`
