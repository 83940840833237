import styled from '@emotion/styled'
import React from 'react'

import { CommentList } from 'app/components/CommentList'
import { InputErrorMessage } from 'app/components/form/InputErrorMessage'
import { FlexBox } from 'app/components/layout/FlexBox'
import { Spacer } from 'app/components/layout/Spacer'
import { NewCommentForm } from 'app/components/NewCommentForm'
import { NoDataPlaceholder } from 'app/components/NoDataPlaceholder'
import {
  ProposalComment,
  ScreensDealAddCommentToProposalComponent,
} from 'app/containers/ApolloGeneratedComponents'
import { breakpoints } from 'app/theme/breakpoints'
import { theme } from 'app/theme/themeDefault'

interface Props {
  proposalId: string
  spaceName: string
  comments: ReadonlyArray<Omit<ProposalComment, 'attachments' | 'proposal'>>
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  > section + section {
    margin-top: ${theme.spacingsLegacy.lg};
    margin-top: ${theme.spacings.lg};
  }
  @media (min-width: ${breakpoints.smMax}) {
    flex-direction: row;
    flex-wrap: nowrap;
    > section:nth-of-type(1) {
      width: 60%;
    }

    > section:nth-of-type(2) {
      width: 40%;
      margin-top: 0;
      padding-left: ${theme.spacingsLegacy.lg};
      padding-left: ${theme.spacings.lg};
    }
  }
`

export const CommentsBox: React.FC<Props> = (props) => {
  const { spaceName, proposalId, comments } = props

  return (
    <div>
      <Spacer size='lg'>
        <FlexBox dial={5} row>
          <h4>{spaceName} Comments</h4>
        </FlexBox>
      </Spacer>
      <Wrapper>
        {/* COMMENT LIST */}
        <section>
          {comments.length ? (
            <CommentList
              comments={comments.map(({ id, createdAt, author, text }) => ({
                id,
                author: {
                  fullName: author.fullName,
                  companyName:
                    author.user && author.user.company
                      ? author.user.company.name
                      : null,
                },
                date: createdAt,
                text,
              }))}
            />
          ) : (
            <NoDataPlaceholder message='There are no comments yet.' />
          )}
        </section>

        {/* NEW COMMENT FORM */}
        <section>
          <ScreensDealAddCommentToProposalComponent>
            {(addComment, { loading: mutating, error }) => {
              return (
                <div>
                  {error ? (
                    <InputErrorMessage
                      message='Your comment could not be posted. Please refresh the page
                    and retry'
                    />
                  ) : null}
                  <NewCommentForm
                    enabled={!mutating}
                    onSubmit={({ authorName, text }) =>
                      addComment({
                        variables: {
                          proposalId,
                          commentData: {
                            authorName,
                            text,
                            attachments: [],
                          },
                        },
                      })
                    }
                  />
                </div>
              )
            }}
          </ScreensDealAddCommentToProposalComponent>
        </section>
      </Wrapper>
    </div>
  )
}
