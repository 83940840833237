import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { darken, rgba } from 'polished'

import { makeMobileMediaQuery } from 'app/theme/breakpoints'
import { theme } from 'app/theme/themeDefault'

interface StyledModalProps {
  isTransitioning: boolean
  transitionDurationMs: number
}

export interface ModalContainerProps {
  allowOverflow: boolean
  verticalCentered: boolean
  size: 'normal' | 'small' | 'large'
  modalPaddingInRem: number
}

export const StyledModal = styled.div<StyledModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  /* TRANSITION EFFECT */
  transition-duration: ${({ transitionDurationMs }) =>
    transitionDurationMs / 1000}s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  opacity: ${({ isTransitioning }) => (isTransitioning ? 1 : 0)};
`

export const Backdrop = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: ${rgba(darken(0.1, theme.colors.primary), 0.8)};
`

const getModalPosition = ({ verticalCentered }: ModalContainerProps) =>
  verticalCentered
    ? css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        ${makeMobileMediaQuery(css`
          top: 5%;
          transform: translate(-50%, 0%);
        `)}
      `
    : css`
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, 0);
        ${makeMobileMediaQuery(css`
          top: 5%;
        `)}
      `

const getModalSize = ({ size }: ModalContainerProps) => {
  switch (size) {
    case 'large':
      return css`
        width: 900px;
        max-width: 90%;
      `

    case 'small':
      return css`
        width: 500px;
        max-width: 90%;
      `

    // normal
    default:
      return css`
        width: 650px;
        max-width: 90%;
      `
  }
}

export const ModalContainer = styled.div<ModalContainerProps>`
  padding: ${({ modalPaddingInRem }) => `${modalPaddingInRem}rem`};
  background-color: #fff;
  border-radius: ${theme.borderRadius};
  overflow: ${({ allowOverflow }) => (allowOverflow ? 'visible' : 'auto')};

  ${getModalPosition}
  ${getModalSize}

  ${makeMobileMediaQuery(css`
    max-height: 70vh;
    overflow-y: auto;
  `)}
`

export const ModalContainerInner = styled.section<{ allowOverflow: boolean }>`
  max-height: 80vh;
  overflow: ${({ allowOverflow }) => (allowOverflow ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;

  ${makeMobileMediaQuery(css`
    max-height: 70vh;
    overflow-y: auto;
  `)}

  /* IE 10 FIX */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    overflow-y: auto;
  }
`

export const ButtonClose = styled.button<{ buttonSize: string }>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ buttonSize }) => buttonSize};
  height: ${({ buttonSize }) => buttonSize};
  line-height: ${({ buttonSize }) => buttonSize};
  font-size: ${({ buttonSize }) => buttonSize};
  cursor: pointer;
  border: 0;
  background-color: #fff;
  z-index: 1;

  &:focus {
    outline: none;
    background-color: ${rgba(theme.colors.primary, 0.1)};
  }

  &:before {
    content: '×';
    font-family: Arial, sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    line-height: inherit;
    font-size: inherit;
  }
  &:hover {
    color: ${theme.colors.primary};
  }
`
