import React from 'react'
import { StyledItem, StyledMenu } from './styled'

interface Props {
  menuWidth: string
  hasHoverState?: boolean
  children: React.ReactNode
}

export const ListMenu: React.FC<Props> = ({
  children,
  menuWidth,
  hasHoverState,
}) => {
  return (
    <StyledMenu menuWidth={menuWidth}>
      {React.Children.map(children, (child, idx) => {
        return (
          <StyledItem key={idx} hasHoverState={hasHoverState || false}>
            {child}
          </StyledItem>
        )
      })}
    </StyledMenu>
  )
}
