import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'

import { Price } from 'app/containers/ApolloGeneratedComponents'
import { makeMobileMediaQuery } from 'app/theme/breakpoints'
import { theme } from 'app/theme/themeDefault'
import { formatPrice } from 'app/utils/currency'

export interface Props {
  stage: string
  dealAmount?: Price | null
  eventBudget?: Price | null
  dateOfEvent?: string
  decisionDeadline?: string
  typeOfEvent: string
  numberOfAttendees?: number | null
}

export const DealSummaryBar: React.FC<Props> = ({
  stage,
  dealAmount,
  eventBudget,
  dateOfEvent,
  decisionDeadline,
  typeOfEvent,
  numberOfAttendees,
}) => {
  const amountToDisplay = dealAmount
    ? formatPrice(dealAmount.amount, dealAmount.currency)
    : 'TBC'
  const budgetToDisplay = eventBudget
    ? formatPrice(eventBudget.amount, eventBudget.currency)
    : 'TBC'

  return (
    <Container>
      <Box>
        <Stage>{stage}</Stage>
        {dealAmount !== undefined ? (
          <Detail>
            <Label>Event Amount</Label>
            <Value>{amountToDisplay}</Value>
          </Detail>
        ) : null}
        <Detail>
          <Label>Budget</Label>
          <Value>{budgetToDisplay}</Value>
        </Detail>
        {dateOfEvent ? (
          <Detail>
            <Label>Event Date</Label>
            <Value>{dateOfEvent}</Value>
          </Detail>
        ) : null}
        {decisionDeadline ? (
          <Detail>
            <Label>Date of Decision</Label>
            <Value>{decisionDeadline}</Value>
          </Detail>
        ) : null}
        {numberOfAttendees ? (
          <Detail>
            <Label>Number of Attendees</Label>
            <Value>{numberOfAttendees}</Value>
          </Detail>
        ) : null}
        <Detail>
          <Label>Event Type</Label>
          <Value>{typeOfEvent}</Value>
        </Detail>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  background-color: #fff;
  z-index: ${theme.zIndex.sticky - 1};
  box-shadow: ${theme.boxShadow};

  ${makeMobileMediaQuery(css`
    display: none;
  `)}
`

const Box = styled.div`
  font-size: ${theme.fontSize.small};
  line-height: ${theme.lineHeight.normal};
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
`

const Stage = styled.div`
  display: flex;
  flex: 1;
  max-width: 250px;
  align-items: center;
  text-align: center;
  padding-left: ${theme.spacings.component};
  background-color: ${theme.colors.primary};
  color: #fff;
`

const Detail = styled.div`
  flex: 1;
  padding: ${theme.spacingsLegacy.xs};
  padding: ${theme.spacings.xs};
  text-align: center;
  position: relative;

  & + & {
    &:before {
      content: '';
      border-left: 1px solid #d2d2d2;
      position: absolute;
      top: 7px;
      bottom: 7px;
      left: 0;
    }
  }
`

const Label = styled.div`
  font-weight: bold;
`

const Value = styled.span``
