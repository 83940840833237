import React from 'react'

import { Props as StyledTagProps, StyledTag } from './styled'

interface Props extends StyledTagProps {
  text: string
}
export const Tag: React.FC<Props> = ({ text, ...rest }) => (
  <StyledTag {...rest}>{text}</StyledTag>
)
