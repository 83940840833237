import styled from '@emotion/styled'

import { theme } from 'app/theme/themeDefault'

export const Container = styled.div`
  width: 100%;
  padding: ${theme.spacingsLegacy.component};
  padding: ${theme.spacings.component};
  &:hover {
    background-color: #eaeaea;
  }
`

export const Title = styled.h4`
  width: 100%;
  color: ${theme.colors.primary};
`

export const Price = {
  Container: styled.div`
    padding-left: 0.5rem;
    display: flex;
  `,
  AmountLine: styled.div`
    font-size: ${theme.headings.h4};
    font-weight: bold;
  `,
  VatLine: styled.div`
    font-size: ${theme.fontSize.normal};
    padding-left: 0.3rem;
    display: flex;
    align-items: flex-end;
    white-space: nowrap;
  `,
}
