import React from 'react'

import {
  MoreActionsDropdown,
  Props as MoreActionsDropdownProps,
} from './MoreActionsDropdown'
import { FlexAction, FlexContainer, FlexDropdownContainer } from './styled'

export interface Props extends MoreActionsDropdownProps {
  leftActionComponent?: React.ReactChild
  rightActionComponent?: React.ReactChild
}

export const Actions: React.FC<Props> = ({
  leftActionComponent,
  rightActionComponent,
  ...dropdownProps
}) => {
  return (
    <FlexContainer dial={5} row spaceAround>
      <FlexAction dial={5}>
        {leftActionComponent ? leftActionComponent : null}
      </FlexAction>
      <FlexAction dial={5}>
        {rightActionComponent ? rightActionComponent : null}
      </FlexAction>
      <FlexDropdownContainer dial={5}>
        <MoreActionsDropdown {...dropdownProps} />
      </FlexDropdownContainer>
    </FlexContainer>
  )
}
