import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'

import introspectionQueryResultData from 'app/containers/ApolloGeneratedComponents/fragmentTypes.json'

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('authToken')

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-HeadBox-AccessToken': token ? token : '',
    },
  }
})

const httpBatchLink = new BatchHttpLink({
  uri: window.env.APP_BACKEND_URL + '/api/graphql',
  credentials: 'include',
})

const httpLink = new HttpLink({
  uri: window.env.APP_BACKEND_URL + '/api/graphql',
  credentials: 'include',
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: (n) => {
      switch (n.__typename) {
        case 'Actor':
          return 'me'
        default:
          return n.id ? `${n.__typename}:${n.id}` : null
      }
    },
    // https://www.apollographql.com/docs/react/advanced/fragments/#fragments-on-unions-and-interfaces
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    }),
  }),
  // link: authLink.concat(httpBatchLink),
  // the link to use is a either the default or batch link
  // example: <Query context={{ important: true }} />
  link: authLink.split(
    (operation) => operation.getContext().important === true,
    httpLink, // if the test is true -- debatch
    httpBatchLink // otherwise, batching is fine
  ),
})
