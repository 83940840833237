import styled from '@emotion/styled'
import React from 'react'

import { breakpoints } from 'app/theme/breakpoints'
import { theme } from 'app/theme/themeDefault'

interface Props {
  /** if fluis it will expand and will use 100% of available width. Otherwise will use the container sizes set in theme default */
  fluid?: boolean
  /** white background */
  solid?: boolean
  /** Some pages require a smaller container, this is to add an inner wrapper and inner side padding */
  wrappedInside?: boolean
}

const StyledContainer = styled.section<Props>`
  width: ${(props) => (props.fluid ? '100%' : theme.containerSize.sm)};
  max-width: 100%;
  margin: 0 auto;
  background-color: ${(props) =>
    props.solid && !props.wrappedInside ? theme.colors.white : 'transparent'};

  @media (min-width: ${breakpoints.md}) {
    width: ${({ fluid }) => (fluid ? '100%' : theme.containerSize.md)};
    padding: ${({ wrappedInside }) => (wrappedInside ? '0 50px' : '0')};
  }

  @media (min-width: ${breakpoints.lg}) {
    width: ${({ fluid }) => (fluid ? '100%' : theme.containerSize.lg)};
    padding: ${(props) => (props.wrappedInside ? '0 100px' : '0')};
  }
  @media (min-width: ${breakpoints.xl}) {
    width: ${({ fluid }) => (fluid ? '100%' : theme.containerSize.xl)};
  }
`

const InsideWrapper = styled.div<{ isTransparent: boolean }>`
  width: 100%;
  background-color: ${({ isTransparent }) =>
    isTransparent ? 'transparent' : 'theme.colors.white'};
`

export const PageContainer: React.FunctionComponent<Props> = ({
  children,
  fluid,
  solid,
  wrappedInside,
}) => (
  <StyledContainer fluid={fluid} solid={solid} wrappedInside={wrappedInside}>
    {wrappedInside ? (
      <InsideWrapper isTransparent={!solid}>{children}</InsideWrapper>
    ) : (
      children
    )}
  </StyledContainer>
)
