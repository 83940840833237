import styled from '@emotion/styled'
import React from 'react'

import { generatePendoDataAttribute } from 'app/utils/pendo/generatePendoId'

import { Card } from 'app/components/layout/Card'
import { Spacer } from 'app/components/layout/Spacer'
import { theme } from 'app/theme/themeDefault'

export interface Props {
  amountPercent: number
}

export const ServiceFeeNotice: React.FC<Props> = ({ amountPercent }) => (
  <Spacer size='lg'>
    <Card horizontalPadding='none' verticalPadding='none'>
      <Box>
        {`Booking(s) will be subject to a ${amountPercent}% HeadBox Processing Fee. For our full T&Cs click `}
        <a
          href='https://www.headbox.com/en/policies/fee-structure-and-invoicing'
          target='_blank'
          {...generatePendoDataAttribute('Anchor', 'Headbox fee T&C')}
        >
          here
        </a>
        .
      </Box>
    </Card>
  </Spacer>
)

const Box = styled.div`
  padding: ${theme.spacingsLegacy.md} ${theme.spacingsLegacy.component};
  padding: ${theme.spacings.md} ${theme.spacings.component};
  font-size: ${theme.fontSize.normal};
  line-height: ${theme.lineHeight.normal};
`
