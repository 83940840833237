import 'react-hot-loader'

import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

const MOUNT_NODE = document.getElementById('root')

if (MOUNT_NODE !== null) {
  ReactDOM.render(<App />, MOUNT_NODE)
}
