import React, { useEffect, useState } from 'react'
// import { Subtract } from 'utility-types'

import { MessageTypes } from 'app/components/ui/Alert'
// import { getDisplayName } from 'app/utils/getDisplayName'

type NotificationObject = {
  message: string
} & MessageTypes

interface NotificationsContext {
  notifications: ReadonlyArray<NotificationObject>
  dispatchNotification: null | ((obj: NotificationObject) => void)
}

interface Props {
  /** time in milliseconds to wait before seeing notification disappering */
  waitToKill: number
}

// exporting for useContext hook
export const NotificationsContext = React.createContext<NotificationsContext | null>(
  null
)

export const NotificationsProvider: React.FC<Props> = ({
  children,
  waitToKill,
}) => {
  const [notifications, setNotification] = useState<
    ReadonlyArray<NotificationObject>
  >([])

  // notification killer
  useEffect(() => {
    window.setTimeout(() => {
      if (notifications.length) {
        setNotification((prevState) => prevState.filter((_, key) => key > 0))
      }
    }, waitToKill)
  })

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        dispatchNotification: (newItem) => {
          setNotification((prevState) => [...prevState, newItem])
        },
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}
