import styled from '@emotion/styled'
import React from 'react'

import { Card } from 'app/components/layout/Card'
import { Separator } from 'app/components/layout/Separator'
import { Spacer } from 'app/components/layout/Spacer'
import { ButtonLink } from 'app/components/ui/ButtonLink'
import { Price } from 'app/containers/ApolloGeneratedComponents'
import { theme } from 'app/theme/themeDefault'
import { formatPrice } from 'app/utils/currency'
import { Stage } from './Stage'

interface Props {
  stage: string
  title: string
  brief: string
  dealAmount?: Price | null
  eventBudget?: Price | null
  dateOfEvent?: string
  decisionDeadline?: string
  displayDecisionDeadline: boolean
  typeOfEvent: string
  companyName?: string
  bookedSpaceName?: string
  hubspotDealUrl?: string
}

export const Summary: React.FC<Props> = ({
  stage,
  title,
  brief,
  dealAmount,
  eventBudget,
  dateOfEvent,
  decisionDeadline,
  displayDecisionDeadline,
  typeOfEvent,
  companyName,
  bookedSpaceName,
  hubspotDealUrl,
}) => {
  const briefLines =
    brief && brief.split('\n').map((line, idx) => <p key={idx}>{line}</p>)

  const amountToDisplay = dealAmount
    ? formatPrice(dealAmount.amount, dealAmount.currency)
    : 'TBC'
  const budgetToDisplay = eventBudget
    ? formatPrice(eventBudget.amount, eventBudget.currency)
    : 'TBC'

  return (
    <Card horizontalPadding='none' verticalPadding='none'>
      <Stage stage={stage} />
      <Box>
        <Title>{title}</Title>
        {dealAmount !== undefined ? (
          <Detail>Event Amount: {amountToDisplay}</Detail>
        ) : null}
        <Detail>Budget: {budgetToDisplay}</Detail>
        <Detail>Event Date: {dateOfEvent || 'TBC'}</Detail>
        {displayDecisionDeadline ? (
          <Detail>Date of Decision: {decisionDeadline || 'TBC'}</Detail>
        ) : null}
        <Detail>Event Type: {typeOfEvent}</Detail>
        <Detail>Company: {companyName}</Detail>
        {bookedSpaceName ? (
          <Detail>Venue booked: {bookedSpaceName}</Detail>
        ) : null}
        {hubspotDealUrl ? (
          <>
            <Spacer />
            <ButtonLink
              fullwidth
              variant='secondary'
              to={hubspotDealUrl}
              isExternalUrl
              target='_blank'
              size='small'
            >
              View Event on Hubspot
            </ButtonLink>
          </>
        ) : null}
      </Box>
      {briefLines && (
        <Description>
          <Separator />
          <div>
            <strong>Event brief:</strong>
            {briefLines}
          </div>
        </Description>
      )}
    </Card>
  )
}

const Box = styled.div`
  padding: ${theme.spacingsLegacy.md} ${theme.spacingsLegacy.component};
  padding: ${theme.spacings.md} ${theme.spacings.component};
  font-size: ${theme.fontSize.normal};
  line-height: ${theme.lineHeight.normal};
`
const Title = styled.h4``
const Detail = styled.div``
const Description = styled.div`
  padding: 0 ${theme.spacingsLegacy.component} ${theme.spacingsLegacy.md};
  padding: 0 ${theme.spacings.component} ${theme.spacings.md};
  line-height: ${theme.lineHeight.normal};
  color: ${theme.colors.grayLight};
`
