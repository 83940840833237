import React from 'react'

import { FileItem } from 'app/components/FileItem'
import { File } from 'app/containers/ApolloGeneratedComponents'

export type Props = Pick<File, 'id' | 'mimeType' | 'name' | 'size' | 'URL'>

export const Attachment: React.FC<Props> = ({ name, URL, mimeType, size }) => (
  <FileItem name={name} URL={URL} mimeType={mimeType} size={size} />
)
