import styled from '@emotion/styled'
import React from 'react'

import { SpacingValue, theme } from 'app/theme/themeDefault'

interface Props {
  size?: SpacingValue
}

export const Separator: React.FC<Props> = ({ size }) => (
  <Hr gap={size || 'normal'} />
)

const Hr = styled.hr<{ gap: SpacingValue }>`
  border-right-style: initial;
  border-bottom-style: initial;
  border-left-style: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-width: 1px 0px 0px;
  border-image: initial;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: ${({ gap }) => theme.spacings[gap]} 0px;
`
