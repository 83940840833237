import React, { Fragment } from 'react'

import { GridProp, StyledGrid } from './styled'

interface Props extends Partial<GridProp> {
  skipInnerDivCreation?: boolean
}
export const Grid: React.FC<Props> = ({
  gap,
  rowGap,
  breakAt,
  children,
  rowsTemplate,
  columnsTemplate,
  columnsTemplateBelowBreakpoint,
  alignItems,
  skipInnerDivCreation,
  legacyCss,
}) => {
  return (
    <StyledGrid
      rowsTemplate={rowsTemplate || 'auto'}
      columnsTemplate={columnsTemplate || '1fr 1fr'}
      columnsTemplateBelowBreakpoint={columnsTemplateBelowBreakpoint || '1fr'}
      gap={gap || 'none'}
      rowGap={rowGap ? rowGap : gap ? gap : 'none'}
      breakAt={breakAt || 'md'}
      alignItems={alignItems || 'initial'}
      legacyCss={legacyCss}
    >
      {React.Children.map(children, (child, idx) => {
        if (!child) {
          return null
        }
        return skipInnerDivCreation ? (
          <Fragment key={idx}>{child}</Fragment>
        ) : (
          <div key={idx}>{child}</div>
        )
      })}
    </StyledGrid>
  )
}
