import {
  DraftInlineStyleType,
  EditorState,
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntity,
  RawDraftEntityRange,
  RawDraftInlineStyleRange,
} from 'draft-js'

import {
  DraftJsBlock,
  DraftJsBlockInput,
  DraftJsBlockType,
  DraftJsEntity,
  DraftJsEntityInput,
  DraftJsEntityMutability,
  DraftJsEntityRangeInput,
  DraftJsEntityType,
  DraftJsInlineStyle,
  DraftJsInlineStyleRangeInput,
  DraftJsRawContentArrayForm,
  DraftJsRawContentArrayFormInput,
} from 'app/containers/ApolloGeneratedComponents'

export const rawStateToGraphQLArrayFormInput = (
  raw: RawDraftContentState
): DraftJsRawContentArrayFormInput => ({
  blocks: raw.blocks.map(rawBlockToGraphQLBlockInput),
  entityArray: Object.keys(raw.entityMap)
    .map((key) => rawEntityToGraphQLEntityInput(raw.entityMap[key]))
    .filter<DraftJsEntityInput>((e): e is DraftJsEntityInput => e !== null),
})

const rawBlockToGraphQLBlockInput = (
  block: RawDraftContentBlock
): DraftJsBlockInput => ({
  key: block.key,
  text: block.text,
  depth: block.depth,
  type: rawBlockTypeToGraphQLBlockType(block.type),
  inlineStyleRanges: block.inlineStyleRanges.map(
    rawInlineStyleRangeToGraphQLInlineStyleRangeInput
  ),
  entityRanges: block.entityRanges.map(rawEntityRangeToGraphQLEntityRangeInput),
  data: {
    id: null,
  },
})

const rawBlockTypeToGraphQLBlockType = (type: string): DraftJsBlockType => {
  switch (type) {
    case 'blockquote':
      return DraftJsBlockType.BLOCKQUOTE
    case 'code-block':
      return DraftJsBlockType.CODE_BLOCK
    case 'unordered-list-item':
      return DraftJsBlockType.UNORDERED_LIST_ITEM
    case 'ordered-list-item':
      return DraftJsBlockType.ORDERED_LIST_ITEM
    case 'header-one':
      return DraftJsBlockType.HEADER_ONE
    case 'header-two':
      return DraftJsBlockType.HEADER_TWO
    case 'header-three':
      return DraftJsBlockType.HEADER_THREE
    case 'header-four':
      return DraftJsBlockType.HEADER_FOUR
    case 'header-five':
      return DraftJsBlockType.HEADER_FIVE
    case 'header-six':
      return DraftJsBlockType.HEADER_SIX
    case 'unstyled':
    default:
      return DraftJsBlockType.UNSTYLED
  }
}

const rawInlineStyleToGraphQLInlineStyle = (
  style: DraftInlineStyleType
): DraftJsInlineStyle => {
  switch (style) {
    case 'BOLD':
      return DraftJsInlineStyle.BOLD
    case 'ITALIC':
      return DraftJsInlineStyle.ITALIC
    case 'UNDERLINE':
      return DraftJsInlineStyle.UNDERLINE
    case 'CODE':
      return DraftJsInlineStyle.CODE
    case 'STRIKETHROUGH':
      return DraftJsInlineStyle.BOLD
  }
}

const rawInlineStyleRangeToGraphQLInlineStyleRangeInput = (
  range: RawDraftInlineStyleRange
): DraftJsInlineStyleRangeInput => ({
  offset: range.offset,
  length: range.length,
  style: rawInlineStyleToGraphQLInlineStyle(range.style),
})

const rawEntityRangeToGraphQLEntityRangeInput = (
  range: RawDraftEntityRange
): DraftJsEntityRangeInput => ({
  key: range.key,
  offset: range.offset,
  length: range.length,
})

const rawEntityToGraphQLEntityInput = (
  entity: RawDraftEntity
): DraftJsEntityInput | null =>
  entity.type === 'LINK'
    ? {
        type: DraftJsEntityType.LINK,
        mutability: DraftJsEntityMutability.MUTABLE,
        data: {
          type: DraftJsEntityType.LINK,
          url: entity.data.url,
        },
      }
    : null

export const graphQLArrayFormToRawState = (
  content: DraftJsRawContentArrayForm
): RawDraftContentState => {
  const rawState = {
    blocks: content.blocks.map(graphQLBlockToRawBlock),
    entityMap: content.entityArray.reduce<RawDraftContentState['entityMap']>(
      (entityMap, entity, index) => ({
        ...entityMap,
        [String(index)]: graphQLEntityToRawEntity(entity),
      }),
      Object.create(null)
    ),
  }
  return rawState
}

const graphQLBlockToRawBlock = ({
  __typename,
  type,
  inlineStyleRanges,
  ...rest
}: DraftJsBlock): RawDraftContentBlock => ({
  ...rest,
  type: graphQLBlockTypeToRawBlockType(type),
  inlineStyleRanges: inlineStyleRanges.map(({ offset, length, style }) => ({
    offset,
    length,
    style: graphQLInlineStyleToRawInlineStyle(style),
  })),
})

const graphQLBlockTypeToRawBlockType = (type: DraftJsBlockType): string => {
  switch (type) {
    case DraftJsBlockType.BLOCKQUOTE:
      return 'blockquote'
    case DraftJsBlockType.CODE_BLOCK:
      return 'code-block'
    case DraftJsBlockType.UNORDERED_LIST_ITEM:
      return 'unordered-list-item'
    case DraftJsBlockType.ORDERED_LIST_ITEM:
      return 'ordered-list-item'
    case DraftJsBlockType.HEADER_ONE:
      return 'header-one'
    case DraftJsBlockType.HEADER_TWO:
      return 'header-two'
    case DraftJsBlockType.HEADER_THREE:
      return 'header-three'
    case DraftJsBlockType.HEADER_FOUR:
      return 'header-four'
    case DraftJsBlockType.HEADER_FIVE:
      return 'header-five'
    case DraftJsBlockType.HEADER_SIX:
      return 'header-six'
    case DraftJsBlockType.UNSTYLED:
    default:
      return 'unstyled'
  }
}

const graphQLInlineStyleToRawInlineStyle = (
  style: DraftJsInlineStyle
): DraftInlineStyleType => {
  switch (style) {
    case DraftJsInlineStyle.BOLD:
      return 'BOLD'
    case DraftJsInlineStyle.ITALIC:
      return 'ITALIC'
    case DraftJsInlineStyle.UNDERLINE:
      return 'UNDERLINE'
    case DraftJsInlineStyle.CODE:
      return 'CODE'
    case DraftJsInlineStyle.STRIKETHROUGH:
      return 'STRIKETHROUGH'
  }
}

const graphQLEntityToRawEntity = ({ data }: DraftJsEntity): RawDraftEntity => ({
  type: 'LINK',
  mutability: 'MUTABLE',
  data: {
    url: data.url,
  },
})

export const checkIfEditorStateContainsText = (
  editorState: EditorState
): boolean => editorState.getCurrentContent().hasText()
