import numeral from 'numeral'

import { Currency } from 'app/containers/ApolloGeneratedComponents'

enum CURRENCY_SYMBOL {
  EUR = '€',
  GBP = '£',
  AUD = '$',
}

export const formatPrice = (amount: number, currency: Currency): string => {
  // const formatted =
  //   currency === 'EUR'
  //     ? numeral(amount)
  //         .format('0,0.00')
  //         .replace(',', '_')
  //         .replace('.', ',')
  //         .replace('_', '.')
  //     : numeral(amount).format('0,0')
  const formatted = numeral(amount).format('0,0')

  return `${CURRENCY_SYMBOL[currency]}${formatted}`
}
