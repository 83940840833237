import styled from '@emotion/styled'
import React from 'react'

import { css } from '@emotion/core'
import { Colors, theme } from 'app/theme/themeDefault'

interface Props {
  size?: 'large' | 'normal' | 'small'
  weight?: 'normal' | 'bold'
  wordBreak?: 'normal' | 'break-word'
  textAlignment?: 'left' | 'center' | 'right'
  children: React.ReactNode
  textColor?: Colors
}

export const Typography: React.FC<Props> = ({
  children,
  size,
  wordBreak,
  weight,
  textAlignment,
  textColor,
}) => {
  return (
    <Block
      size={size || 'normal'}
      weight={weight || 'normal'}
      wordBreak={wordBreak || 'normal'}
      textAlignment={textAlignment || 'left'}
      textColor={textColor}
    >
      {children}
    </Block>
  )
}

export const Block = styled.div<Partial<Props>>`
  font-size: ${({ size }) => theme.fontSize[size || 'normal']};
  font-weight: ${({ weight }) => weight};
  line-height: ${theme.lineHeight.normal};
  word-break: ${({ wordBreak }) => wordBreak};
  text-align: ${({ textAlignment }) => textAlignment};

  ${({ textColor }) =>
    textColor
      ? css`
          color: ${theme.colors[textColor]};
        `
      : null}
`
